import { Close } from 'icons/svg';
import { Button, OutlinedButton } from 'components/atoms/button';
import { BspotModal } from 'components/atoms/modal/modal';
import React from 'react';

type PlayLaterModalProps = {
  onClickPlayLater?(product: any): void;
  onClose?(): void;
  product: any;
  show: boolean;
};
export const PlayLaterModal = ({
  product,
  onClose,
  show,
  onClickPlayLater,
}: PlayLaterModalProps) => {
  const onCloseModal = () => {
    onClose();
  };

  const onPlayLater = () => {
    onCloseModal();
    onClickPlayLater(product);
  };

  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Play Later?'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={onCloseModal} />
      </div>
      <div className={'d-flex flex-column gap-1'}>
        <div>These credits may take 15-30 minutes to be available to play.</div>
        <div>
          {' '}
          If you'd like to avoid the wait, select a different package to get
          your credits immediately.
        </div>
      </div>
      <div className={'d-flex flex-row gap-2'}>
        <OutlinedButton text='Play Later' onClick={onPlayLater} />
        <Button text='Play now' onClick={onCloseModal} />
      </div>
    </BspotModal>
  );
};
export default PlayLaterModal;
