import { FC } from 'react';
import './light-dot.scss';

export const LightDot: FC<{
  top?: string;
  left?: string;
  bottom?: string;
  className: string;
}> = ({ top, left, bottom, className }) => {
  return (
    <img
      className={'dot ' + className}
      src='/js_assets/images/sweepstakes/dot.svg'
      alt=''
      style={{
        top,
        bottom,
        left,
      }}
    />
  );
};
