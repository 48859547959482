import { BspotAlert } from 'components/molecules/alerts/alert';
import React from 'react';
import { Routes } from 'common/routes';

type AlertSuccessProps = {
  inGame?: boolean;
  onBuyCreditsInGame?: () => void;
};
export const AlertDepositSuccess = ({
  inGame = false,
  onBuyCreditsInGame,
}: AlertSuccessProps) => {
  const message = (
    <div>
      Deposit Confirmed. Let's get spinning!
      <br />
      {inGame ? (
        <>
          <span
            style={{
              color: 'white',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={onBuyCreditsInGame}
          >
            Buy Credits now
          </span>
        </>
      ) : (
        <a style={{ color: 'white' }} href={Routes.WagersPage.path}>
          Buy Credits now
        </a>
      )}
    </div>
  );

  return <BspotAlert type={'success'} message={message} />;
};
