export type BorderShadowProps = {
  borderRadius?: string | number;
  disableShadow?: boolean;
  shadowSize?: ShadowSizes;
};

export enum ShadowSizes {
  None = 'none',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const Shadows = {
  [ShadowSizes.None]: '0 0 0 transparent',
  [ShadowSizes.Small]:
    'calc(var(--spacing-1)/2) calc(var(--spacing-1)/2) 0 var(--bg-dark-color)',
  [ShadowSizes.Medium]:
    'var(--spacing-1) var(--spacing-1) 0 var(--bg-dark-color)',
  [ShadowSizes.Large]:
    'calc(var(--spacing-1)*1.5) calc(var(--spacing-1)*1.5) 0 var(--bg-dark-color)',
};
export const BorderShadow = ({
  disableShadow,
  borderRadius,
  shadowSize = ShadowSizes.Medium,
}: BorderShadowProps) => `
border: var(--border-width-1) solid var(--bg-dark-color);
${
  !disableShadow
    ? `
  box-shadow: ${Shadows[shadowSize]};
`
    : ''
}
border-radius: ${borderRadius || 'var(--spacing-4)'};
`;

//@TODO: these colours need adding to variables
//Update when we review colours with Sergio
export const Disabled = () => `
  color: #b3b3b3;
  background-color: #f2f2f2;
  border-color: #b3b3b3;
  cursor: not-allowed;
  pointer-events: none;
`;
