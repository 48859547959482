import { GameInfo } from '../../../../hooks/use-games-list';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SyntheticEvent } from 'react';
import './game-tile.scss';
import { Link } from 'react-router-dom';
import { Routes } from 'common/routes';

export type GameTileProps = {
  gameInfo: GameInfo;
  withMarginTop?: boolean;
  isXl?: boolean;
  isFull?: boolean;
};

export const GameTileMk2 = ({
  gameInfo = {
    available: true,
    icon_url: '',
    id: 0,
    game_id: '',
    slug: '',
  },
  withMarginTop,
  isXl = false,
  isFull = false,
}: GameTileProps) => (
  <Link
    to={
      Routes.Game.path +
      '?gameId=' +
      gameInfo.game_id +
      '&id=' +
      gameInfo.id +
      '&or=' +
      gameInfo.orientation
    }
    className={[
      withMarginTop ? 'margin-top' : '',
      isXl ? 'xl-tile' : '',
      isFull ? 'full-tile' : '',
      'game-tile',
    ].join(' ')}
    data-testid={`game-tile-${gameInfo?.game_id}`}
  >
    <LazyLoadImage
      src={gameInfo?.icon_url || '/js_assets/images/banner-placeholder.webp'}
      alt='game tile'
      loading={'lazy'}
      width={isXl ? 194 : 126}
      height={isXl ? 143 : 126}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#121212',
        cursor: 'pointer',
      }}
    />
    {gameInfo?.available && (
      <>
        {gameInfo?.bottom_ribbon && (
          <span className='badge'>{gameInfo?.bottom_ribbon}</span>
        )}
      </>
    )}
  </Link>
);

export const GhostGameTile = ({ isXl }) => (
  <div className={`${isXl ? 'xl-tile' : ''} game-tile-ghost`}>
    <LazyLoadImage
      src={'/js_assets/images/banner-placeholder.webp'}
      alt='game tile'
      loading={'lazy'}
      width={isXl ? 194 : 126}
      height={isXl ? 143 : 126}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#121212',
        cursor: 'pointer',
      }}
    />
  </div>
);
