import { useQuery } from '@tanstack/react-query';
import { getDynamicContent } from 'services/dynamic-content/dynamic-content';

export const useDynamicContent = (slug: string) => {
  if (!slug) return;
  return useQuery({
    queryKey: ['dynamic-content'],
    queryFn: () => getDynamicContent(slug),
    keepPreviousData: true,
    staleTime: 30 * 60000,
  });
};
