import {
  AvailableAmounts,
  Button,
  InputAmount,
  Text,
  TextSize,
} from 'components';
import { AccountCard } from './styled';
import { Group } from 'pages/account-history/styled';
import { FC, useState } from 'react';
import { AvailableBanks } from 'hooks/use-available-banks';
import { FieldErrors, UseFormHandleSubmit } from 'react-hook-form';
import { Routes } from 'common/routes';
import { BANK_ID, makeBankDeposit } from 'services/make-deposit/make-deposit';
import { useNavigate } from 'hooks/use-navigate';
import { useLocation } from 'react-router-dom';
import { loadThirdPartyCheckout } from './load-third-party-checkout';
import { useAuth } from 'contexts/authentication';
import './payment-card.scss';

export const PaymentCardComponent: FC<{
  isModal: boolean;
  userFailedKyc: boolean;
  availableBanks: AvailableBanks[];
  paymentMethodId: any;
  errors: FieldErrors<{
    payment_method: any;
    amount: string;
  }>;
  depositRangeHint: string;
  amount: string;
  handleSubmit: UseFormHandleSubmit<
    {
      payment_method: any;
      amount: string;
    },
    undefined
  >;
  onSelectAmount: (amount) => void;
  onSelectPaymentMethod?: (paymentMethod) => void;
  setCheckout: React.Dispatch<React.SetStateAction<string>>;
  setNoPaymentResults: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  amount,
  isModal,
  paymentMethodId,
  errors,
  depositRangeHint,
  handleSubmit,
  onSelectAmount,
  setCheckout,
  setNoPaymentResults,
}) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const makeDeposit = () => {
    const payload = {
      Amount: +amount,
      BankId: paymentMethodId,
      ReturnSuccessUrl: resolveRedirectUrls('success'),
      ReturnCancelUrl: resolveRedirectUrls('error'),
      ReturnErrorUrl: resolveRedirectUrls('error'),
      ReturnPendingUrl: resolveRedirectUrls('pending'),
      AccountId: auth?.session.playerId,
      Locale: 'en_US',
      // AccountId: playerId,
      AccountPrefillRequested: false,
    };
    setIsLoading(true);
    makeBankDeposit(payload)
      .then((depositResponse) => {
        if (depositResponse.PaymentResult) {
          if (
            depositResponse.PaymentResultMessage === 'Deposit sent to Trustly'
          )
            navigate(Routes.MakeDepositSuccessPage);
          else
            setCheckout(
              loadThirdPartyCheckout(depositResponse, amount, paymentMethodId),
            );
          return;
        }
        setNoPaymentResults('Deposit canceled by the user');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resolveRedirectUrls = (page: string) => {
    const responseToIframe = paymentMethodId !== BANK_ID.TRUSTLY;
    //let baseUrl = window.origin + '/en/deposit/' + page;
    let baseUrl = window.origin + Routes.MakeDepositPage.path + '/' + page;
    if (responseToIframe) {
      baseUrl = baseUrl + '-i';
    }

    const topURL = new URL(window.top.location.href);
    // when in game
    if (
      location.pathname === Routes.Game.path &&
      paymentMethodId === BANK_ID.TRUSTLY
    ) {
      baseUrl = topURL.href + '&inGameMakeDeposit=true';
    }
    return baseUrl;
  };

  return (
    <AccountCard isModal={isModal}>
      {/*<Text size={TextSize.Body} className='pt-1 pb-3 d-block'>*/}
      {/*  Choose or enter the amount to deposit to your player account. See*/}
      {/*  details below about our accepted payment methods.*/}
      {/*</Text>*/}
      <div className='pt-0'>
        <InputAmount amount={amount} onChange={onSelectAmount} />
        <Group className='pt-1'>
          {errors.amount && (
            <Text bold size={TextSize.Hint} color='#d70022'>
              {errors.amount.message}
            </Text>
          )}
        </Group>
        {depositRangeHint && (
          <Text size={TextSize.Hint}>{depositRangeHint}</Text>
        )}
        <AvailableAmounts amount={amount} onSelect={onSelectAmount} />
        <Group className={'pt-4 text-center'}>
          <Button
            type={'submit'}
            isLoading={isLoading}
            text='Continue'
            disabled={isLoading}
            onClick={handleSubmit(makeDeposit)}
          />
        </Group>
        <div className='payments-info'>
          <div>
            <img
              className='instant-bank-transfer'
              src='/js_assets/images/group-34.png'
              alt='group-34'
              loading='lazy'
            />
            <p>Instant Bank Transfers have NO FEES! Simple, safe, secure.</p>
          </div>
          <div>
            <img
              className='cards'
              src='/js_assets/images/group-37.png'
              alt='group-37'
              loading='lazy'
            />
            <p>
              Major card brands accepted. Avoid credit card fees by using a
              debit card!
            </p>
          </div>
          <div>
            <img
              className='play'
              src='/js_assets/images/pp-logo-4.png'
              alt='pp logo'
              loading='lazy'
            />
            <p>
              Use your Sightline prepaid card to play your favorite games!&nbsp;
              <a
                href='https://playplusgo.com/faq/'
                target='_blank'
                rel='noreferrer noopener'
              >
                See Play+ FAQ.
              </a>
            </p>
          </div>
        </div>
      </div>
    </AccountCard>
  );
};
