'use client';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { useAuth } from 'contexts/authentication';
import { useBalanceAndCredits } from 'hooks/use-balance-and-credits/use-balance-and-credits';
import { Toast, ToastProps } from 'components';
import { toCurrency } from 'common/bspot-shared';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags';
import { ModalFreePlayAwarded } from '../../components/organisms/modal-free-play-awarded/modal-free-play-awarded';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../common/routes';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { getCookie } from 'common/utils';
import { useSession } from 'next-auth/react';

export const ToasterProvider = ({ children }: { children: ReactNode }) => {
  const { auth } = useAuth();
  return (
    <SnackbarProvider
      Components={{
        default: Toast,
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      preventDuplicate
      maxSnack={5}
      classes={{
        containerRoot: 'snackbar-container-root',
        containerAnchorOriginTopRight: 'snackbar-anchor-origin-top-right',
      }}
    >
      {auth?.session && <BalanceToasts state={auth.session.state} />}
      {children}
    </SnackbarProvider>
  );
};

/**
 * useToast
 * example usages
 * - Toast that self invokes a close:
 *   const toastKey = pushToast({
 *     title: 'Congratulations Matthew!',
 *     message: `You have been awarded $${1000} in Freeplay! The Credits will be added to
 *     your balance shortly. Happy spinning!`,
 *     icon: <Icon fill="#000000" name="clock" />,
 *     closeHandler: () => removeToast(toastKey),
 *   });
 */

type ToastOptions = ToastProps & {
  persist?: boolean;
};

export const useToaster = () => {
  const { enqueueSnackbar: originalEnqueueSnackbar, closeSnackbar } =
    useSnackbar();
  const pushToast = (options: Partial<ToastOptions>): SnackbarKey => {
    return originalEnqueueSnackbar(options);
  };
  const removeToast = (key: SnackbarKey) => closeSnackbar(key);
  return { pushToast, removeToast };
};

const BalanceToasts = ({ state }: { state: string }) => {
  const location = useLocation();
  const { auth } = useAuth();
  const [showFreePlayMessage, setShowFreePlayMessage] = useState(false);
  const { balanceAndCredits: balances, isLoading } = useBalanceAndCredits();
  const { pushToast, removeToast } = useToaster();
  const orderPendingRef = useRef(null);
  const orderCompleteRef = useRef(null);
  const timeToGetCreditsRef = useRef(null);
  const timeToDepositRef = useRef(null);
  const [order, setOrder] = useState(null);

  const { data: status } = usePlayerStatus();

  const isInDeposit = location?.pathname?.includes(Routes.MakeDepositPage.path);
  const isInWagers = location?.pathname?.includes(Routes.WagersPage.path);
  const isInGame = location?.pathname?.includes(Routes.Game.path);
  const hasRegistered = getCookie(`${auth?.session?.playerId}isRegistered`);
  useEffect(() => {
    const cleanUp = () => {
      if (timeToDepositRef.current) {
        removeToast(timeToDepositRef.current);
        timeToDepositRef.current = null;
      }
      if (timeToGetCreditsRef.current) {
        removeToast(timeToGetCreditsRef.current);
        timeToGetCreditsRef.current = null;
      }
    };

    // logged out or kyc failed, clear snackbars
    if (!status || status?.validator_status !== 1) {
      cleanUp();
      return;
    }
  }, [status, removeToast]);

  // show order pending toast
  useEffect(() => {
    if (
      balances?.credits_pending > 0 &&
      !orderPendingRef.current &&
      !isInGame
    ) {
      if (timeToGetCreditsRef.current) {
        removeToast(timeToGetCreditsRef.current);
        timeToGetCreditsRef.current = null;
      }

      orderPendingRef.current = pushToast({
        type: 'warning',
        icon: (
          <img
            src='/js_assets/images/alert-icon-1.png'
            width={61}
            height={41}
          />
        ),
        message: (
          <p>
            Your <b>${balances.credits_pending}</b> of credits are on the way!
          </p>
        ),
        closeHandler: () => removeToast(orderPendingRef.current),
        persist: true,
      });
      setOrder(balances.credits_pending);
    }
  }, [
    balances?.credits_pending,
    isLoading,
    pushToast,
    removeToast,
    location.pathname,
  ]);

  // show order complete toast when we no longer have pending credits and the order pending toast is present.
  useEffect(() => {
    if (
      balances?.credits_pending === 0 &&
      orderPendingRef.current &&
      !isInGame
    ) {
      const isFirstCreditsPurchase = balances.credits_purchased_count === 1;
      const isInPremiumState =
        FeatureFlagsService?.featureFlags?.activeInstantPlayReserveStates?.includes(
          state,
        );
      const isFreePlayAwardedModel =
        FeatureFlagsService?.featureFlags?.enableOnboardingPage &&
        isFirstCreditsPurchase &&
        isInPremiumState;
      if (isFreePlayAwardedModel && !showFreePlayMessage) {
        setShowFreePlayMessage(true);
      } else if (!orderCompleteRef.current) {
        orderCompleteRef.current = pushToast({
          type: 'success',
          icon: (
            <img
              src='/js_assets/images/alert-icon-2.png'
              width={50}
              height={41}
            />
          ),
          message: (
            <p>
              Your <b>{toCurrency(order)}</b> of credits have arrived!
            </p>
          ),
          closeHandler: () => {
            removeToast(orderCompleteRef.current);
            orderCompleteRef.current = null;
          },
        });
        removeToast(orderPendingRef.current);
        orderPendingRef.current = null;
      }
    }
  }, [
    balances,
    order,
    pushToast,
    removeToast,
    state,
    showFreePlayMessage,
    location.pathname,
  ]);

  // show time to get credits
  useEffect(() => {
    if (status?.validator_status !== 1) {
      return;
    }
    if (window.self !== window.top || hasRegistered) return;
    if (isInWagers || isInGame || !balances?.shouldConvertCash) {
      removeToast(timeToGetCreditsRef.current);
      timeToGetCreditsRef.current = null;
      return;
    }

    if (!timeToGetCreditsRef.current && balances?.shouldConvertCash) {
      timeToGetCreditsRef.current = pushToast({
        type: 'warning',
        icon: (
          <img
            src='/js_assets/images/alert-icon-1.png'
            width={61}
            height={41}
          />
        ),
        title: (
          <span style={{ fontSize: '14px', lineHeight: '14px' }}>
            <a
              className='no-underline'
              href={'/en/wagers'}
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <b>Time to get some Credits!</b>
            </a>
          </span>
        ),
        message: '',
        persist: true,
        closeHandler: () => {
          removeToast(timeToGetCreditsRef.current);
          timeToGetCreditsRef.current = null;
        },
      });
    }
  }, [
    balances,
    pushToast,
    removeToast,
    location.pathname,
    isInWagers,
    isInGame,
    status?.validator_status,
  ]);

  // show time to deposit
  useEffect(() => {
    if (status?.validator_status !== 1) {
      return;
    }
    if (window.self !== window.top || hasRegistered) return;
    if (isInDeposit || isInGame || !balances?.shouldDeposit) {
      removeToast(timeToDepositRef.current);
      timeToDepositRef.current = null;
      return;
    }

    if (!timeToDepositRef.current && balances?.shouldDeposit) {
      timeToDepositRef.current = pushToast({
        type: 'warning',
        icon: (
          <img
            src='/js_assets/images/alert-icon-1.png'
            width={61}
            height={41}
          />
        ),
        title: (
          <span style={{ fontSize: '14px', lineHeight: '14px' }}>
            <a
              href={'/en/account/wallet/deposit'}
              style={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: '700',
              }}
            >
              <b> Add funds to play!</b>
            </a>
          </span>
        ),
        message: '',
        persist: true,
        closeHandler: () => {
          removeToast(timeToDepositRef.current);
          timeToDepositRef.current = null;
        },
      });
    }
  }, [
    balances,
    pushToast,
    removeToast,
    location.pathname,
    isInDeposit,
    isInGame,
    status?.validator_status,
  ]);

  return (
    showFreePlayMessage && (
      <ModalFreePlayAwarded
        onclose={() => {
          setShowFreePlayMessage(false);
        }}
      />
    )
  );
};
