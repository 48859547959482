import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Button, OutlinedButton } from 'components/atoms/button';
import { deleteGTM, injectGTM } from 'common/gtm/gtm';
import {
  deleteAppsFlyer,
  deleteAppsFlyerCookies,
  injectAppsFlyer,
} from 'services/_af-events/appsflyer';
import { getCookie, setPersistentCookie } from 'common/utils';
import { useLocation } from 'react-router-dom';
import { Close } from 'icons/svg';
export const Gdpr = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const onAcceptGdpr = () => {
    localStorage.setItem('hp-gdpr', 'true');
    injectGTM();
    injectAppsFlyer();
    setShow(false);
  };
  const onDeclineGdpr = () => {
    localStorage.setItem('hp-gdpr', 'false');
    deleteAppsFlyer();
    deleteAppsFlyerCookies();
    deleteGTM();
    setShow(false);
  };
  const onClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (localStorage.getItem('hp-gdpr')) {
      return;
    }

    let timer;
    setTimeout(() => {
      setShow(true);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [location]);

  return (
    <div className={`gdpr ${show ? 'display-on' : ''}`}>
      <div className={'d-flex flex-column gap-1 pl-5'}>
        <div className='d-flex flex-row justify-content-between gap-3'>
          <div className='d-flex flex-column align-items-start justify-content-between gap-3'>
            <div>
              <h3 className={'m-0 mb-2'}>
                <strong>Cookies</strong>
              </h3>
              <div className={'gdpr-text'}>
                We use cookies to ensure you get the best user experience on our
                website. <br /> By continuing to use the site, you agree to the
                use of these cookies. Find out more in our
                <a href={'/en/privacy-policy'} style={{ color: '#0aeb88' }}>
                  {' '}
                  cookie policy
                </a>
              </div>
            </div>
            <div className='d-flex flex-row align-items-end  '>
              <Button text='Accept all' onClick={onAcceptGdpr} />
              <OutlinedButton text='Decline' onClick={onDeclineGdpr} />
            </div>
          </div>

          <div className='d-flex flex-column align-items-start'>
            <span
              className={'gdpr-icon-cta d-flex flex-row align-items-center'}
              onClick={onClose}
            >
              <Close fill={'white'} height={'10px'} width={'10px'} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
