import React from 'react';
import Error from 'pages/error/error';
import { AppLoader } from 'components/molecules/app-loader/app-loader';

export class ErrorBoundary extends React.Component {
  private static chunkError: boolean;
  constructor(props) {
    super(props);
    // initialize the error state
    this.state = { hasError: false };
  }

  // if an error happened, set the state to true
  static getDerivedStateFromError(error) {
    const chunksError =
      "Cannot read properties of undefined (reading 'default')";
    ErrorBoundary.chunkError = error.message.includes(chunksError);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // send error to somewhere here
    console.log(error, errorInfo);
  }

  render() {
    // if error happened, return a fallback component
    // @ts-ignore
    if (this.state.hasError) {
      // @ts-ignore
      return (
        <main
          style={{ backgroundColor: 'black' }}
          className={`d-flex flex-column flex-grow-1  pb-5 min-vh-100 p-0 m-0`}
        >
          {ErrorBoundary.chunkError ? <AppLoader /> : <Error />}
        </main>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}
