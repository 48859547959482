import { useAuth } from 'contexts/authentication';
import { useEffect } from 'react';

// TODO: Will be removed once web component repo is updated;
function upperCaseKeyObject(object) {
  return Object.entries(object).reduce((acc, [key, val]) => {
    acc[key.charAt(0).toUpperCase() + key.slice(1)] = val;
    return acc;
  }, {} as Record<string, any>);
}

export const useAuthTracker = () => {
  const { auth } = useAuth();

  const setBspotAuth = () => {
    if (typeof window.Bspot.auth?.set !== 'function') return;
    if (!auth.session) {
      window.Bspot.auth.set(null);
      return;
    }

    const uppercaseSession = auth.session
      ? upperCaseKeyObject(auth.session)
      : null;
    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed('Setting WC session:');
      console.info(JSON.stringify(uppercaseSession, null, 2));
      console.groupEnd();
    }
    window.Bspot.auth.set(uppercaseSession);
  };

  window.Bspot.onInit = setBspotAuth;

  useEffect(setBspotAuth, [auth?.token]);
};
