import { useState } from 'react';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { TermsModal } from 'components/organisms/terms-and-conditions/terms-modal/terms-modal';
import { AcceptModal } from 'components/organisms/terms-and-conditions/terms-modal/accept-modal';
import { useTerms } from 'hooks/use-content';

export const ModalTermsManager = () => {
  const { data: status } = usePlayerStatus();
  const { data: terms } = useTerms();
  const [showTerms, setShowTerms] = useState(false);

  const showTermsModal = () => {
    setShowTerms(true);
  };

  if (showTerms) {
    return (
      <TermsModal
        terms={terms?.content}
        show={showTerms}
        tncRequired={status.tnc_required}
        onCloseModal={() => setShowTerms(false)}
      />
    );
  }

  if (status?.tnc_required)
    return <AcceptModal onShowTermsModal={showTermsModal} />;
};
export default ModalTermsManager;
