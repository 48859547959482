import { useAuth } from 'contexts/authentication';
import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useGetBalance } from 'hooks/use-balance';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const PERSIST_FREEPLAY_MESSAGE = 'freeplay-message';

export enum Event {
  FREEPLAY = 'FREEPLAY',
  CREDITS_PURCHASED = 'CREDITS_PURCHASED',
}

export type WSMessage = {
  specversion: string;
  type: string;
  source: string;
  id: string;
  time: string;
  datacontenttype: string;
  data: {
    event: Event;
    parameters: {
      amount: number;
    };
  };
};

export const useWebpushListener = () => {
  const { auth } = useAuth();
  const { refetch: refetchBalances } = useGetBalance();

  const socket = useRef<ReturnType<typeof io> | null>(null);

  useEffect(() => {
    if (!auth?.auth?.playerId || !auth?.session?.playerSessionToken) {
      if (socket.current) disconnect();
      return;
    }

    connect();

    return disconnect;
  }, [auth.auth, auth.session]);

  function onConnect() {
    if (FeatureFlagsService?.featureFlags?.environment !== 'prod')
      console.log(
        'Connected socket with query',
        socket.current?.io?.opts?.query,
      );
  }

  function connect() {
    socket.current = io(FeatureFlagsService?.featureFlags?.webpushHref, {
      forceNew: true,
      query: {
        PlayerID: auth.auth.playerId.toString(),
        sessionToken: auth.session.playerSessionToken,
      },
    });

    socket.current.connect();
    socket.current.on('connect', onConnect);
    socket.current.on('listen', onMessage);
  }

  function disconnect() {
    socket.current.off('connect', onConnect);
    socket.current.off('listen', onMessage);
    socket.current.disconnect();
  }

  function onMessage(message: WSMessage) {
    if (message.data.event === Event.CREDITS_PURCHASED) {
      //TODO: should dispatch an event to push the toast when complete
      refetchBalances();
    }
  }

  return socket.current;
};
