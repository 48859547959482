import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useAuth } from '../../../contexts/authentication';
import { useLobbyBanners } from '../../../hooks/use-lobby-banners/use-lobby-banners';
import './style.scss';
import { CircularLoader } from '../../atoms/circular-loader';
import React from 'react';
import { AppLoader } from 'components/molecules/app-loader/app-loader';

export const LobbyBanners = ({ contentProp }) => {
  const { auth } = useAuth();
  // const { data: content } = useLobbyBanners();
  const content = contentProp;

  const skeletonBanner = (
    <section
      style={{
        borderRadius: '0',
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <picture className='position-relative w-100'>
        <source
          width={1366}
          height={768}
          media='(min-width: 1366px)'
          srcSet='/js_assets/images/banner-placeholder.webp'
        />
        <source
          width={1180}
          height={664}
          media='(min-width: 1180px)'
          srcSet='/js_assets/images/banner-placeholder.webp'
        />
        <source
          width={820}
          height={460}
          media='(min-width: 820px)'
          srcSet='/js_assets/images/banner-placeholder.webp'
        />
        <source
          width={512}
          height={768}
          media='(min-width: 512px)'
          srcSet='/js_assets/images/banner-placeholder-mobile.webp'
        />
        <img
          width={390}
          height={433}
          src='/js_assets/images/banner-placeholder-mobile.webp'
          alt='deposit now'
          loading='eager'
          style={{ width: '100%', height: 'auto', borderRadius: '0' }}
        />
      </picture>
      <div style={{ position: 'absolute', alignSelf: 'center' }}>
        <CircularLoader color={'white'} size={'2rem'} />
      </div>
    </section>
  );

  // if (!content) {
  //   return <div
  //       className={`d-flex flex-col items-center`}
  //       style={{
  //         height: '100vh',
  //         backgroundColor:'black'
  //       }}
  //   ><AppLoader fullHeight={true}/></div>;
  //     }

  const showLoggedOutBanners = !auth?.session;
  const showLoggedInBanners = auth?.session;

  return (
    <section>
      {showLoggedOutBanners && <LoggedOutCarousel content={content} />}
      {showLoggedInBanners && content && <LoggedInCarousel content={content} />}
    </section>
  );
};

const LoggedOutCarousel = ({ content }) => {
  const settings = {
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    dots: content.length > 1,
    infinite: content.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: content.length > 1,
    responsive: [
      {
        breakpoint: 819,
        settings: {
          arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
  };
  return (
    <Slider {...settings}>
      {content?.map((banner) => (
        <a
          className='log-out-container'
          key={banner.link}
          style={{ borderRadius: '0' }}
          href={banner.link}
        >
          <picture className={'img-fluid'}>
            <source
              width={1366}
              height={768}
              media='(min-width: 1366px)'
              srcSet={`${banner.desktop}?fm=webp&w=1800&h=1012&q=85`}
            />
            <source
              width={1180}
              height={664}
              media='(min-width: 1180px)'
              srcSet={`${banner.desktop}?fm=webp&w=1366&h=768&q=85`}
            />
            <source
              width={820}
              height={460}
              media='(min-width: 820px)'
              srcSet={`${banner.desktop}?fm=webp&w=1180&h=664&q=85`}
            />
            <source
              width={512}
              height={768}
              media='(min-width: 512px)'
              srcSet={`${banner.mobile}?fm=webp&w=820&h=910&q=100`}
            />
            <img
              width={390}
              height={433}
              src={`${banner.mobile}?fm=webp&w=512&h=568&q=100`}
              alt='deposit now'
              loading='eager'
              style={{ width: '100%', height: 'auto', borderRadius: '0' }}
            />
          </picture>
        </a>
      ))}
    </Slider>
  );
};

const LoggedInCarousel = ({ content }) => {
  const settings = {
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    dots: content.length > 1,
    infinite: content.length > 1,
    centerPadding: '15%',
    arrows: content.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          centerPadding: '0',
        },
      },
    ],
    appendDots: (dots) => (
      <div style={{ bottom: '18px' }}>
        <ul>{dots}</ul>
      </div>
    ),
  };
  return (
    <Slider {...settings} className='login-container'>
      {content?.map((banner) => (
        <a className='px-2' key={banner.link} href={banner.link}>
          <picture>
            <source
              width={1504}
              height={594}
              media='(min-width: 1366px)'
              srcSet={`${banner.desktop}?fm=webp&w=1504&h=594&q=85`}
            />
            <source
              width={1075}
              height={425}
              media='(min-width: 1180px)'
              srcSet={`${banner.desktop}?fm=webp&w=1075&h=425&q=85`}
            />
            <source
              width={928}
              height={367}
              media='(min-width: 992px)'
              srcSet={`${banner.desktop}?fm=webp&w=928&h=367&q=85`}
            />
            <source
              width={976}
              height={604}
              media='(min-width: 820px)'
              srcSet={`${banner.mobile}?fm=webp&w=976&h=604&q=100`}
            />
            <source
              width={804}
              height={497}
              media='(min-width: 512px)'
              srcSet={`${banner.mobile}?fm=webp&w=804&h=497&q=100`}
            />
            <img
              width={496}
              height={307}
              src={`${banner.mobile}?fm=webp&w=496&h=307&q=100`}
              alt='deposit now'
              loading='eager'
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '20px',
              }}
            />
          </picture>
        </a>
      ))}
    </Slider>
  );
};
