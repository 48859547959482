import { useAuthTracker } from 'hooks/use-auth-tracker';
import { useRouteTracker } from 'hooks/use-route-tracker';
import { useStriveSocketListener } from 'hooks/use-strive-socket-listener/use-strive-socket-listener';
import { useIdleTicker } from 'hooks/use-idle-ticker';

export const Trackers = () => {
  useAuthTracker();
  useRouteTracker();
  useStriveSocketListener();
  useIdleTicker();

  return <></>;
};
