import { useEffect, useState } from 'react';

export const useMiniModalProduct = ({
  miniModalCredits,
  gameProducts,
  cash,
}) => {
  const [miniModalProduct, setMiniModalProduct] = useState();

  useEffect(() => {
    if (gameProducts.length > 0 && miniModalCredits === 0) {
      setMiniModalProduct(null);
      return;
    }

    if (gameProducts.length > 0 && miniModalCredits > 0) {
      const affordableProducts = gameProducts.filter(
        (product) =>
          product.expected_wait === 'IMMEDIATE' && product?.cost <= cash,
      );

      setMiniModalProduct(
        affordableProducts.length > 0
          ? findNextCost(affordableProducts, Math.abs(miniModalCredits))
          : null,
      );
    }
  }, [cash, gameProducts, gameProducts.length, miniModalCredits]);

  return { miniModalPackage: miniModalProduct };
};

function findNextCost(items, value) {
  for (let item of items) {
    if (item.cost >= value) {
      return item;
    }
  }
  return items[items.length - 1];
}
