import { useLocation } from 'react-router-dom';
import { useDynamicContent } from 'hooks/use-dynamic-content/use-dynamic-content';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const DynamicContent = () => {
  const location = useLocation();
  const slug = location.pathname.split('/content/')[1];
  const { data: content } = useDynamicContent(slug);

  return (
    <>
      <Helmet>
        <title>{content?.title}</title>
        <meta name='description' content={content?.description} />
        <meta name='og:title' content={content?.title} />
        <meta name='twitter:title' content={content?.title} />
      </Helmet>
      <div
        className={'container mb-5'}
        dangerouslySetInnerHTML={{ __html: content?.content }}
      ></div>
    </>
  );
};
