import { Button, ButtonColor } from '../../../../atoms/button';
import { Routes } from '../../../../../common/routes';
import {
  Meta,
  TournamentType,
} from '../../../../../services/tournaments/tournaments';
import { useNavigate } from '../../../../../hooks/use-navigate';

export type CtaProps = {
  isOptedIn: boolean;
  meta: Meta;
  playerId: string;
  isActive: boolean;
  ctaLoading: boolean;
  onClickOptIn: (e: React.SyntheticEvent<Element, Event>) => void;
};

export const Cta = ({
  isOptedIn = false,
  meta,
  playerId,
  isActive,
  onClickOptIn,
  ctaLoading,
}: CtaProps) => {
  const navigate = useNavigate();

  const clickPlayNow = () => {
    if (isOptedIn) {
      if (meta?.opt_in_action) {
        const urlObj = new URL(meta?.opt_in_action);
        const params = urlObj.searchParams;
        navigate(Routes.Game, {
          query: {
            gameId: params.get('gameId'),
            id: params.get('id'),
            demo: '0',
          },
        });
      } else console.error('The opted in action does not exist');
      return;
    }
  };

  return (
    <div>
      {!playerId && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Secondary}
          className='cta-button'
          onClick={() => navigate(Routes.Login.path)}
          isLoading={ctaLoading}
          text={meta?.logged_out_label || 'Join Now!'}
        />
      )}

      {isOptedIn && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Primary}
          className='cta-button'
          onClick={clickPlayNow}
          disabled={ctaLoading || (!isActive && isOptedIn)}
          isLoading={ctaLoading}
          text={meta?.opted_in_label || 'Play Now!'}
        />
      )}

      {playerId && !isOptedIn && (
        <Button
          id='tournament-cta'
          color={ButtonColor.Secondary}
          className='cta-button'
          onClick={onClickOptIn}
          disabled={ctaLoading}
          isLoading={ctaLoading}
          text={meta?.logged_in_label || 'Opt In'}
        />
      )}
    </div>
  );
};
