import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'contexts/authentication';
import { Auth } from 'services/auth/login';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { Routes } from 'common/routes';
import { getCredits } from 'services/get-credits/get-credits';
import { useState } from 'react';

export type Balances = {
  credits: number;
  credits_pending: number;
  credits_purchased_count: number;
  deposits_count: number;
  credits_last_order: number;
  created_at: string;
};

const checkFp1 = (auth: Auth): boolean => {
  return (
    localStorage.getItem(`kyc-success-${auth.session?.playerId}`) === 'success'
  );
};

export const useGetCredits = () => {
  const { auth } = useAuth();
  let { state } = useLocation();
  const location = useLocation();

  const [isInterval, setIsInterval] = useState(false);

  return useQuery({
    queryKey: ['use-credits', auth.session?.playerId],
    queryFn: () => auth.session && getCredits(auth.session),
    keepPreviousData: false,
    refetchInterval: (data) => {
      const shouldRefetch =
        location.pathname === Routes.Game.path || // inGame - take every 5 seconds (no of seconds is from contentful)
        (location.pathname === Routes.WagerHistory.path &&
          data?.credits !== 0) || // Account history for the play all - take every 5 seconds
        data?.credits_pending !== 0 || // when credit purchase
        checkFp1(auth); // initial free play
      return shouldRefetch
        ? localStorage.getItem('lc')
          ? 2000
          : Number(FeatureFlagsService?.featureFlags?.balancesPool)
        : false;
    },
    enabled: !!auth.session?.playerId,
    refetchOnMount: !!auth.session?.playerId,
  });
};
