import {
  LeaderboardEntry,
  TournamentType,
} from 'services/tournaments/tournaments';
import { useAuth } from 'contexts/authentication';
import { useNewLeaderBoard } from 'hooks/use-leaderboard';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { TournamentInGameIcon } from 'icons/svg/tournament-in-game';
import { TournamentInGameStarIcon } from 'icons/svg/tournament-in-game-star';
import styled from '@emotion/styled';
import { UseOptInTournament } from 'hooks/use-opt-in-tournament/use-opt-in-tournament';
import { TournamentWidget } from 'components/organisms/tournament-widget/tournament-widget';

type TournamentInGameProps = {
  tournament: TournamentType;
  refetchTournaments?: () => void;
};
export const TournamentInGame = ({
  tournament,
  refetchTournaments,
}: TournamentInGameProps) => {
  const { auth } = useAuth();
  const { data: items, refetch: refetchLeaderboards } = useNewLeaderBoard(
    tournament?.id,
    tournament?.rewards,
    auth?.session?.playerId.toString(),
    tournament?.isOptedIn,
    auth?.member?.name,
  );
  const [player, setPlayer] = useState(null);
  const [reward, setReward] = useState(null);
  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    if (tournament?.isOptedIn && items?.length > 0) {
      const _player = items.find((it) => it.isCurrentUser === true);
      setPlayer(_player);
      if (_player?.reward) {
        const x = _player.reward.split('Prize: ')[1].split(' cash')[0];
        setReward(x);
      }
      // const referecnePositions = [];
      // tournament.rewards.forEach((r, index) => {
      //   referecnePositions.push({
      //     position: index,
      //     prize:
      //       r &&
      //       r
      //         ?.toLowerCase()
      //         .split('prize: ')[1]
      //         .split(' cash')[0]
      //         .split('$')[1],
      //   });
      // });
      // referecnePositions.shift();
      // if(_player.position!==1) {
      //
      // }
      // console.log('\'ARF== \',tournament positions ', referecnePositions);
      // console.log('\'ARF== \',user position ', _player.position);

      //
      // const nextPosition =
      //     referecnePositions.find((it) => it.position === _player.position)
      //     .position - 1;
      //
      // console.log('\'ARF== \',next position ', nextPosition);
      //
      // const nextPositioninList =
      //     referecnePositions.find((it) => it.position === nextPosition);
      //
      // console.log('\'ARF== \',next position item ', nextPositioninList);

      // if (nextPosition === 0) {
      //   //  he is the winner
      // }
      // if (nextPosition > 0) {
      //

      // check if next position has same price as current position - in this case we ignore it

      // const nextPositionHasSamePrize = referecnePositions.find(it => it.prize === nextPositioninList.prize)
      // if(nextPositionHasSamePrize) {
      //
      //   const nextPositionsWithHigherPrize = referecnePositions.filter(it => it.prize > nextPositioninList.prize)
      //   if(nextPositionsWithHigherPrize) {
      //     console.log('ARF== ', nextPositionsWithHigherPrize.sort((a,b) => a.prize - b.prize))
      //   }
      //
      // }

      // const nP = referecnePositions.filter(it => it.prize > nextPositioninList.prize)
      // console.log('ARF== ', nP.sort((a,b) => a.prize - b.prize))
      // console.log('ARF== next position ==> ',nP.find((it) => it.position === nextPositioninList.position))

      // }
    }
  }, [items, tournament?.isOptedIn]);

  const { clickOptIn, loading } = UseOptInTournament({
    tournament,
    refetchTournaments,
    refetchLeaderboards,
  });

  const onClickOptIn = () => {
    clickOptIn(tournament);
  };

  if (!tournament?.isOptedIn) {
    return <TournamentInGameNotOptedIn onClickOptIn={onClickOptIn} />;
  }
  if (tournament?.isOptedIn) {
    return (
      <>
        {player?.onBoard && (
          <TournamentInGameOnboard
            displayWidget={() => setShowWidget(!showWidget)}
            player={player}
            reward={reward}
          />
        )}
        {!player?.onBoard && (
          <TournamentInGameNotOnboard
            displayWidget={() => setShowWidget(!showWidget)}
          />
        )}
        {showWidget && (
          <div
            className={`position-absolute tourn-widget `}
            style={{ zIndex: '99' }}
          >
            <TournamentWidget
              tournament={tournament}
              refetchTournaments={refetchTournaments}
            />
          </div>
        )}
      </>
    );
  }
};

type TournamentInGameNotOptedInProps = {
  onClickOptIn?: () => void;
};

export const TournamentInGameNotOptedIn = ({
  onClickOptIn,
}: TournamentInGameNotOptedInProps) => {
  return (
    <div
      id='tourn-in--logged-out'
      className='tourn-in-game rounded-pill d-flex flex-column justify-content-center align-items-center position-relative'
    >
      <div className={`content__container__list`}>
        <span
          className={'content__container__list__item'}
          onClick={onClickOptIn}
        >
          <div className='d-flex flex-column justify-content-center align-items-center position-relative'>
            <span>OPT</span> <span>IN</span>
          </div>
        </span>
        <span className={'content__container__list__item'}>
          <div className='d-flex flex-column justify-content-center align-items-center position-relative'>
            <span className={'tourn-position'}>
              <TournamentInGameStarIcon />
            </span>
            <TournamentInGameIcon height={'28px'} width={'40px'} />
          </div>
        </span>
      </div>
    </div>
  );
};

type TournamentInGameOnBoardProps = {
  player: LeaderboardEntry;
  reward: string;
  displayWidget: () => void;
};

export const TournamentInGameOnboard = ({
  player,
  reward,
  displayWidget,
}: TournamentInGameOnBoardProps) => {
  return (
    <>
      <TournamentInGamePercentage
        className={'xx-ratio'}
        ratio={null}
        onClick={displayWidget}
      >
        <div className={`content__container__list`}>
          <span className={'content__container__list__item'}>
            <div className='d-flex flex-column justify-content-center align-items-center position-relative'>
              <span className={'tourn-in-game-reward'}>{reward}</span>
            </div>
          </span>
          <span className={'content__container__list__item'}>
            <div className='tourn-in-game-icon d-flex flex-column justify-content-center align-items-center position-relative'>
              <span
                className={
                  'tourn-in-game-icon-position text-center d-flex flex-column justify-content-center align-items-center'
                }
              >
                {player?.position}
              </span>
              <TournamentInGameIcon
                height={'28px'}
                width={'40px'}
                fill={'#FFB71A'}
              />
            </div>
          </span>
        </div>
      </TournamentInGamePercentage>
    </>
  );
};

type TournamentInGameNotOnBoardProps = {
  displayWidget: () => void;
};
export const TournamentInGameNotOnboard = ({
  displayWidget,
}: TournamentInGameNotOnBoardProps) => {
  return (
    <TournamentInGamePercentage
      className={'xx-ratio'}
      ratio={'0'}
      onClick={displayWidget}
    >
      <div
        className='tourn-in-game-icon d-flex flex-column justify-content-center align-items-center position-relative'
        style={{ marginLeft: '-2px' }}
      >
        <span
          className={
            'tourn-in-game-icon-star d-flex flex-column justify-content-center align-items-center'
          }
        >
          <TournamentInGameStarIcon fill={'#FFB71A'} />
        </span>
        <TournamentInGameIcon height={'28px'} width={'40px'} fill={'#FFB71A'} />
      </div>
    </TournamentInGamePercentage>
  );
};

interface tournamentInGamePercentageProps {
  ratio?: string;
}
export const TournamentInGamePercentage = styled.div<tournamentInGamePercentageProps>`
  cursor: pointer;

  --ratio: ${(props) => (props.ratio ? props.ratio : '0.1')};

  &.xx-ratio {
    height: 53px;
    width: 53px;
    border-radius: 50%;
    position: relative;
    clip-path: circle(50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  &.xx-ratio::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: conic-gradient(
      #ffb71a 0 calc(var(--ratio) * 360deg),
      #353535 calc(var(--ratio) * 360deg) 360deg
    );
    z-index: -2;
  }

  &.xx-ratio::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    background-color: #000000;
    border-radius: 50%;
    z-index: -1;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 160px;
    overflow: hidden;

    font-family: 'Poppins', sans-serif;
    color: #ecf0f1;

    &__container {
      font-weight: 600;
      overflow: hidden;
      @media (min-width: 992px) {
        height: 45px;
        width: 45px;
        line-height: 11px;
        font-size: 11px;
      }
      height: 45px;
      width: 45px;
      padding: 0;

      &__list {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        animation-name: change;
        animation-iteration-count: infinite;
        animation-delay: 2s;
        transform: translate3d(0, -25%, 0);
        animation-duration: 7s;

        &__item {
          @media (min-width: 992px) {
            height: 45px;
            width: 45px;
            line-height: 11px;
            font-size: 11px;
          }
          height: 45px;
          width: 45px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @keyframes change {
    0%,
    25%,
    100% {
      transform: translate3d(0, -25%, 0);
    }
    50%,
    75% {
      transform: translate3d(0, 25%, 0);
    }
  }
`;
