import React, { useState } from 'react';
import { Text, TextSize } from 'components/atoms/text';
import { Button, OutlinedButton } from 'components/atoms/button';
import { useAuth } from 'contexts/authentication';
import { acceptTermsAndConditions } from 'services/content/terms-and-conditions/terms-and-conditions';
import { Checkbox, FormControlLabel } from '@mui/material';
import { BspotModal } from 'components/atoms/modal/modal';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { CONFIG } from 'common/utils';
import { signOut } from 'next-auth/react';
import { useNavigate } from 'react-router-dom';

type AcceptModalProps = {
  onShowTermsModal?(e: boolean): void;
};

export const AcceptModal = ({ onShowTermsModal }: AcceptModalProps) => {
  const { refetch } = usePlayerStatus();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showAcceptTerms, setShowAcceptTerms] = useState(true);

  const onDecline = () => {
    ENABLE_NEXT_AUTH ? navigate('/en/logout') : signOut();
    setShowAcceptTerms(false);
  };

  const onAccept = () => {
    setLoading(true);
    acceptTermsAndConditions().finally(() => {
      refetch();
      setShowAcceptTerms(false);
    });
  };

  return (
    <BspotModal show={showAcceptTerms} smallLg={true}>
      <h2 className={'my-0'}>{"We've updated our T&Cs"}</h2>
      <div className={'d-flex flex-column gap-2 text-left'}>
        <div className={'fw-bold pb-2'}>
          Our terms and conditions have changed. You must approve them before
          continuing
        </div>
        <div className={'d-flex flex-row align-items-center gap-2'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onClick={() => setChecked(!checked)}
                name={'agreement'}
                sx={{
                  marginTop: -1,
                  '&.Mui-checked': {
                    color: 'var(--primary-color)',
                  },
                }}
              />
            }
            label={
              <>
                Yes, I have read and agree to the new
                <a onClick={() => onShowTermsModal(true)}>
                  <Text
                    text={' terms and conditions'}
                    color={'#0aeb88'}
                    size={TextSize.Body}
                  />
                </a>{' '}
                of {CONFIG.BRAND}
              </>
            }
          />
        </div>
      </div>
      <div className={'d-flex flex-row gap-2 '}>
        <OutlinedButton text="I Don't agree" onClick={onDecline} />
        <Button
          disabled={!checked}
          isLoading={loading}
          text='I Agree'
          onClick={onAccept}
        />
      </div>
    </BspotModal>
  );
};
