import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLaunchGame } from 'hooks/use-launch-game';
import { useIsMobile } from '../../hooks/use-is-mobile/use-is-mobile';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags';
import {
  AlertDepositError,
  AlertDepositSuccess,
  GameHeader,
} from '../../components';
import BuyCreditsModal from '../../components/organisms/modal-buy-credits/modal-buy-credits';
import DepositModal from '../../components/organisms/modal-make-deposit/modal-make-deposit';
import { SwiperUpOverlay } from './swipe-up-overlay';
import { SdkActions, useGameSdk } from '../../contexts/game-sdk/game-sdk';
import './game.scss';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { LogoHorseplayDesktop } from 'icons/svg/logo-horseplayDesktop';

const Game = () => {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const id = Number(searchParams.get('id'));
  const orientaion = Number(searchParams.get('or'));
  const isIOS =
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/);
  const isAndroid = navigator.userAgent.match(/Android/);
  const isIOSorAndroid = useRef(isIOS || isAndroid);
  const isMobile = useIsMobile();
  const [showOverlay, setShowOverlay] = useState(false);

  const { data, isLoading, error, mutate } = useLaunchGame();

  useEffect(() => {
    if (
      FeatureFlagsService?.featureFlags?.gameScrollOverlay &&
      isMobile &&
      isIOSorAndroid.current
    ) {
      setShowOverlay(true);
    }
  }, [isMobile]);

  useEffect(() => {
    mutate({
      id: id,
      game_provider: 'CasinoGames::GPN',
      game_id: gameId,
      demo: '0',
    });
  }, [gameId, id, mutate]);

  const goFullScreen = async () => {
    setShowOverlay(false);
    if (!document.fullscreenElement) {
      const el = document.querySelector('body');
      if (el.requestFullscreen) await el.requestFullscreen();
      // @ts-ignore
      else if (el.webkitRequestFullscreen)
        // @ts-ignore
        await el.webkitRequestFullscreen();
      // @ts-ignore
      else if (el.msRequestFullscreen) await el.msRequestFullscreen();
    }
  };

  // Orinetation:
  // 0 - landscape
  // 1 - portrait (Thundering Buffalo Golden Stampede)
  // 2- undetermined (Night Jasmine)
  const [gameOrientation, setGameOrientation] = useState('rotate0');

  const changeOrientation = () => {
    if (isIOSorAndroid?.current) {
      // landscape mode
      if ([0].includes(orientaion)) {
        setGameOrientation(
          screen.orientation.type.startsWith('portrait')
            ? 'rotate90'
            : 'rotate0',
        );
      }
      // portrait mode
      if ([1].includes(orientaion)) {
        setGameOrientation(
          screen.orientation.type.startsWith('landscape')
            ? 'rotate90'
            : 'rotate0',
        );
      }
      // undetermined mode (the game support both landscape and portrait mode)
      if ([2].includes(orientaion)) {
        setGameOrientation('');
      }
    }
  };

  useEffect(() => {
    localStorage.removeItem('lc');
    localStorage.removeItem('lc-');
    changeOrientation();
    screen.orientation.addEventListener('change', () => {
      changeOrientation();
    });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '3000px',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          top: '0',
          left: '0',
        }}
        className={gameOrientation}
      >
        <GameHeader />
        <main
          style={{
            flex: 1,
            boxSizing: 'border-box',
          }}
        >
          <IframeWrapper>
            {isLoading ? (
              <AppLoader />
            ) : error ? (
              <Typography>An error occured while loading the game</Typography>
            ) : data ? (
              <StyledIframe
                src={data.url}
                id='iframe-game'
                name='iframe-game'
              />
            ) : (
              <Typography>This game does not exist</Typography>
            )}
          </IframeWrapper>
        </main>
      </div>
      <Modals />
      {showOverlay && isIOS && <SwiperUpOverlay hideOverlay={goFullScreen} />}
      {showOverlay && isAndroid && (
        <button
          className='android-overlay'
          onClick={() => {
            goFullScreen();
          }}
        >
          <div className='top'>
            <LogoHorseplayDesktop />
            <br />
            <br />
            <p>
              America's{' '}
              <i>
                <b>Best</b>
              </i>{' '}
              Real Money Games
            </p>
          </div>
          <div className='middle'>
            <img src='/js_assets/images/android-tap.gif' alt='android tap' />
          </div>
          <p className='bottom'>
            Tap to enter <br /> full screen mode
          </p>
        </button>
      )}
    </div>
  );
};

const Modals = () => {
  const { setSdkAction, sdkAction } = useGameSdk();
  const [modalState, setModalState] = useState<
    'hidden' | 'deposit' | 'credits'
  >('hidden');
  const [searchParams, setSearchParams] = useSearchParams();
  const inGameCash = searchParams.get('inGameCash');
  const inGamePaymentSuccess = searchParams.get('inGamePaymentSuccess');
  const inGamePaymentError = searchParams.get('inGamePaymentError');
  const inGamePaymentStatus = searchParams.get('status');
  const nameOnCard = searchParams.get('nameOnCard');

  const onCloseCreditsModal = () => {
    if (searchParams.has('inGamePaymentSuccess')) {
      searchParams.delete('inGamePaymentSuccess');
    }
    if (searchParams.has('deposit')) {
      searchParams.delete('deposit');
    }
    if (searchParams.has('fromIframe')) {
      searchParams.delete('fromIframe');
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (sdkAction === SdkActions.DEPOSIT_CASH) {
      setModalState('deposit');
    }
  }, [sdkAction, setModalState]);

  useEffect(() => {
    if (
      inGamePaymentSuccess ||
      inGamePaymentError ||
      inGamePaymentStatus ||
      inGameCash
    ) {
      setModalState('credits');
    }
  }, [
    inGameCash,
    inGamePaymentError,
    inGamePaymentStatus,
    inGamePaymentSuccess,
    setModalState,
  ]);

  const onBuyCreditsInGame = () => {
    setModalState('hidden');
    document.getElementById('horseplay-credits-button').click();
  };

  return (
    <>
      {modalState === 'credits' && (
        <BuyCreditsModal
          show
          onClose={() => {
            setSdkAction(SdkActions.IDLE);
            setModalState('hidden');
            onCloseCreditsModal();
          }}
        />
      )}

      {modalState === 'deposit' && (
        <DepositModal
          show
          onClose={() => {
            setSdkAction(SdkActions.IDLE);
            setModalState('hidden');
          }}
          onDepositSuccess={() => {
            console.log('ARF');
            setSdkAction(SdkActions.IDLE);
            setModalState('credits');
          }}
        >
          {inGamePaymentSuccess && (
            <div className={'pb-3'}>
              <AlertDepositSuccess
                inGame
                onBuyCreditsInGame={onBuyCreditsInGame}
              />
            </div>
          )}
          {inGamePaymentStatus === '2' && (
            <div className={'pb-3'}>
              <AlertDepositSuccess
                inGame
                onBuyCreditsInGame={onBuyCreditsInGame}
              />
            </div>
          )}
          {inGamePaymentStatus && inGamePaymentStatus !== '2' && (
            <div className={'pb-3'}>
              <AlertDepositError
                paymentStatus={inGamePaymentStatus}
                nameOnCard={Boolean(nameOnCard)}
              />
            </div>
          )}
        </DepositModal>
      )}
    </>
  );
};

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default Game;
