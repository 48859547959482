import './swipe-up-overlay.scss';
import { FC, useEffect, useRef } from 'react';
import { LogoHorseplayDesktop } from 'icons/svg/logo-horseplayDesktop';

export const HideOverlayChoiceKey = 'stop-showing-swipe-overlay-';

export const SwiperUpOverlay: FC<{
  hideOverlay(): void;
}> = ({ hideOverlay }) => {
  const scrollStartY = useRef(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollStartY.current) {
        hideOverlay();
        return;
      } else scrollStartY.current = window.scrollY;
    };

    const timeout = setTimeout(() => {
      scrollStartY.current = window.scrollY;
      window.addEventListener('scroll', handleScroll);
    }, 100);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hideOverlay]);

  return (
    <div className={`overlay`}>
      <div className='d-flex flex-column align-items-center title-col'>
        <LogoHorseplayDesktop />
        <span>
          America's <i>Best</i> Real Money Games
        </span>
      </div>
      <img
        className='swipe-up'
        src='/js_assets/images/swipe_up.png'
        alt='swipe up motion'
      />
      <span className='bottom-text'>Swipe up for full screen mode</span>
    </div>
  );
};
