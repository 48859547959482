import { Counter } from './counter';
import { LeaderboardEntry } from '../../../../../../services/tournaments/tournaments';

export const PlayerItemWidget = ({
  id,
  player,
  position,
  isCurrentUser,
  pointDifference,
  reward,
}: LeaderboardEntry) => {
  const points = player?.score;

  return (
    <div
      className={`tournament-widget-leaderboards-player-item ${
        position % 2 === 0
          ? 'tournament-widget-leaderboards-player-item-color'
          : ''
      } ${isCurrentUser ? 'tournament-widget-sticky-row' : ''}`}
    >
      <div
        className={`tournament-widget-leaderboards-position-space position-${position}`}
      >
        {position && (
          <span className={isCurrentUser ? 'black-text' : 'white-text'}>
            {position ? position : '--'}
          </span>
        )}
      </div>
      <div className='tournament-widget-leaderboards-position-player d-flex flex-column justify-content-center align-items-start'>
        <span
          className={
            isCurrentUser
              ? 'tournament-widget-leaderboards-black-text'
              : 'tournament-widget-leaderboards-white-text'
          }
          data-testid={`${id}-${player?.id}-name`}
        >
          <b>
            {player?.fullName ? player.fullName : ''}
            {isCurrentUser ? ' (You)' : ''}
          </b>
        </span>
        <span
          className={`tournament-widget-prize ${
            isCurrentUser ? 'tournament-widget-leaderboards-black-text' : ''
          }`}
          data-testid={`${id}-${player?.id}-prize`}
        >
          {reward
            ? reward
            : isCurrentUser && !!pointDifference
            ? `${(pointDifference + 1)?.toLocaleString(
                'en-US',
              )} points to get a prize`
            : ''}
        </span>
      </div>
      <span
        data-testid={`${id}-${player?.id}-score`}
        className={
          isCurrentUser
            ? 'tournament-widget-leaderboards-black-text'
            : 'tournament-widget-leaderboards-white-text'
        }
      >
        <b>{points ? <Counter from={0} to={points} /> : '0pts'}</b>
      </span>
    </div>
  );
};

export default PlayerItemWidget;
