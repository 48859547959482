import './bottom-nav.scss';
import { useAuth } from '../../../../contexts/authentication';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../../../common/routes';
import { useNavigate } from '../../../../hooks/use-navigate';
import { useXtremeNotifications } from '../../../../hooks/use-xtreme-notifications/use-xtreme-notifications';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import React from 'react';
import {
  Credits,
  Home,
  HomeFill,
  Profile,
  Promotion,
  PromotionFill,
  Tournament,
  TournamentFill,
} from '../../../../icons/svg';

export const BottomNav = ({
  toggleSideMenu,
}: {
  toggleSideMenu: () => void;
}) => {
  const { auth } = useAuth();
  const location = useLocation();
  const showLoggedOutNav =
    FeatureFlagsService?.featureFlags?.enableBottomNavLoggedOut &&
    !auth?.session &&
    location.pathname === Routes.Lobby.path;
  const showLoggedInNav =
    (FeatureFlagsService?.featureFlags?.enableBottomNavLoggedIn &&
      auth?.session &&
      [
        Routes.Home.path,
        Routes.Tournaments.path,
        Routes.Promotions.path,
        Routes.About.path,
        Routes.TermsOfUse.path,
        Routes.PrivacyPolicy.path,
        Routes.ResponsibleGaming.path,
        Routes.PageNotFound.path,
      ].includes(location.pathname)) ||
    location.pathname.includes(
      Routes.GamesByCategory.path.replace(':category', ''),
    );

  return (
    <>
      {showLoggedOutNav && (
        <nav className='bottom-nav logged-out d-flex d-sm-none'>
          <LoggedOutButtons />
        </nav>
      )}
      {showLoggedInNav && (
        <nav className='bottom-nav logged-in d-flex d-sm-none'>
          <img
            width={575}
            height={84}
            src='/js_assets/images/bottom-nav.svg'
            className='w-100 h-auto'
            alt='bottom navigation'
          />
          <div className='position-absolute w-100 h-100 d-flex justify-content-between px-3'>
            <LoggedInButtons
              page={location.pathname}
              toggleSideMenu={toggleSideMenu}
            />
          </div>
        </nav>
      )}
    </>
  );
};

const LoggedOutButtons = () => (
  <>
    <a href={Routes.Login.path} className='link login'>
      <span>Login</span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='19'
        viewBox='0 0 20 19'
        fill='none'
      >
        <path
          d='M1.83999 18.4002C1.83999 18.4002 0.23999 18.4002 0.23999 16.8802C0.23999 15.3602 1.83999 10.8002 9.83999 10.8002C17.84 10.8002 19.44 15.3602 19.44 16.8802C19.44 18.4002 17.84 18.4002 17.84 18.4002H1.83999ZM9.83999 9.28016C11.113 9.28016 12.3339 8.79973 13.2341 7.94456C14.1343 7.0894 14.64 5.92954 14.64 4.72016C14.64 3.51077 14.1343 2.35092 13.2341 1.49575C12.3339 0.640584 11.113 0.160156 9.83999 0.160156C8.56695 0.160156 7.34605 0.640584 6.44588 1.49575C5.5457 2.35092 5.03999 3.51077 5.03999 4.72016C5.03999 5.92954 5.5457 7.0894 6.44588 7.94456C7.34605 8.79973 8.56695 9.28016 9.83999 9.28016Z'
          fill='black'
        />
      </svg>
    </a>
    <a href={Routes.CreateAccount.path} className='link sign-up'>
      <span>Sign up</span>
      <span className='sub-text'>IT'S FREE</span>
    </a>
  </>
);

const LoggedInButtons = ({ toggleSideMenu, page }) => {
  const navigate = useNavigate();
  const { data: notifications } = useXtremeNotifications();
  return (
    <>
      <a className='btn' onClick={() => navigate(Routes.Home)}>
        {page === Routes.Home.path ? <HomeFill /> : <Home />}
      </a>
      <a className='btn' onClick={() => navigate(Routes.Tournaments)}>
        {page === Routes.Tournaments.path ? <TournamentFill /> : <Tournament />}
      </a>
      <a className='btn credits' onClick={() => navigate(Routes.WagersPage)}>
        <Credits width={40} height={37} />
      </a>
      <a className='btn' onClick={() => navigate(Routes.Promotions)}>
        {page === Routes.Promotions.path ? <PromotionFill /> : <Promotion />}
      </a>
      <button
        className='btn'
        onClick={toggleSideMenu}
        style={{ paddingInline: 'unset' }}
      >
        {notifications > 0 && (
          <span
            className='position-absolute'
            style={{
              transform: 'translate(8px, 8px)',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              border: '1px solid #0AEB88',
              background:
                'linear-gradient(199deg, #FF96AF 11.58%, #FF415F 28.38%, #FF2833 46.88%, #DD2E6D 64.04%, #931D33 87.36%)',
            }}
          ></span>
        )}
        <Profile />
      </button>
    </>
  );
};
