import { Api } from 'common/api';
import { Routes } from 'common/routes';

type DynamicContent = {
  slug: string;
  content: string;
  title: string;
  description: string;
};
export const getDynamicContent = async (
  slug: string,
): Promise<DynamicContent> => {
  const { data, ok, status } = await Api.get<any>(
    `${API_HOST}/api/v1/content?slug=${slug}`,
  );
  if (status === 404) {
    location.href = Routes.PageNotFound.path;
    return;
  }
  if (!ok) {
    throw new Error(
      'There was an error fetching the dynamic content for the slug ' + slug,
    );
  }
  return data;
};
