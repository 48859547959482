import { MaxWidth, useMediaQuery } from 'hooks/use-media-query';
import React, { useEffect, useRef } from 'react';
import { HeaderMk2 } from './header-mk2/header-mk2';
import { Position } from 'hooks/use-drawer';
import { GameProduct } from 'services/get-game-products/get-game-products';

interface BpostHeaderProps extends React.PropsWithChildren {
  setShowBottomNav?: React.Dispatch<React.SetStateAction<boolean>>;
  setDrawerPosition?: React.Dispatch<React.SetStateAction<string>>;
  showQuickModal?(instantPackage: GameProduct): void;
  dismissQuickModal?: boolean;
  isNavOpen?: boolean;
  toggleNav?: () => void;
}

export const BspotHeader = ({
  setShowBottomNav,
  setDrawerPosition,
  isNavOpen,
  toggleNav,
}: BpostHeaderProps) => {
  const [isLarge] = useMediaQuery(MaxWidth.Large);
  const topNavRef = useRef(null);

  useEffect(() => {
    const ref = topNavRef?.current;
    const topNavObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) =>
          setShowBottomNav(entry.intersectionRatio === 0),
        );
      },
      {
        root: null,
        threshold: 0,
      },
    );
    if (ref) {
      topNavObserver.observe(ref);
    }
    return () => {
      if (ref) {
        topNavObserver.unobserve(ref);
      }
    };
  }, [setShowBottomNav]);

  useEffect(() => {
    setDrawerPosition(isLarge ? Position.Top : Position.Right);
  }, [isLarge, setDrawerPosition]);

  return (
    <div ref={topNavRef}>
      <HeaderMk2 isNavOpen={isNavOpen} toggleNav={toggleNav} />
    </div>
  );
};
