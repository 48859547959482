import React from 'react';
import { SvgProps } from './svg';

export function TournamentInGameIcon({
  size,
  width = '31',
  height = '25',
  fill = '#0AEB88',
}: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size || width}
      height={size || height}
      viewBox='0 0 31 25'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7629 0.944115H6.16089V3.8864H0.814941V8.38847C0.814941 12.1638 3.88517 15.234 7.66051 15.234H8.56544C10.0187 16.9862 12.035 18.2268 14.345 18.6261V22.1948H10.5437C10.2689 22.1948 10.0188 22.2977 9.83742 22.4633C9.65601 22.6289 9.54321 22.8571 9.54321 23.108V24.1621H22.0172V23.108C22.0172 22.8571 21.9044 22.6289 21.723 22.4633C21.5416 22.2977 21.2916 22.1948 21.0168 22.1948H17.909V18.5629C20.0808 18.1067 21.9747 16.9022 23.3583 15.234H23.8297C27.6051 15.234 30.6753 12.1638 30.6753 8.38847V3.8864H25.7629V0.944115ZM24.5761 13.3971C25.3347 11.9371 25.7629 10.2707 25.7629 8.51761V5.6684H28.8933V8.38847C28.8933 10.9261 27.015 13.035 24.5761 13.3971ZM7.37213 13.4439C4.7145 13.2938 2.59694 11.0829 2.59694 8.38847V5.6684H6.16089V8.51761C6.16089 10.2895 6.59826 11.9728 7.37213 13.4439ZM7.94289 8.51761V2.72611H23.9809V8.51761C23.9809 13.1849 20.3835 16.9821 15.9619 16.9821C11.5403 16.9821 7.94289 13.1849 7.94289 8.51761Z'
        fill={fill}
      />
    </svg>
  );
}
