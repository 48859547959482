import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './next/App';

const target = document.getElementById('root');
const root = createRoot(target);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

declare global {
  interface Window {
    Bspot: {
      auth?: any;
      onInit?: () => void;
      liveChat?: {
        open?: () => void;
        close?: () => void;
      };
    };
  }
}

if (!window.Bspot) window.Bspot = {};
