import { Profile } from 'services/auth/login';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const playAll = async (session: Profile): Promise<number> => {
  let headers: any = {
    Accept: '*/*',
    'x-player-id': session.playerId,
    'x-gpn-pam-player-id': session.playerId,
    'x-session-id': session.sessionId,
    'x-gpn-pam-session-token': session.playerSessionToken,
  };

  const url = `${FeatureFlagsService?.featureFlags?.gpnRgsHost}/api/v1/play_all`;
  const request = {
    method: 'POST',
    headers: headers,
  };

  const response: Response = await fetch(url, request);
  if (!response.ok) throw new Error('Failed to request buy credits');
  //
  if (response.ok) {
    try {
      const data = await response.json();
      return data.revealed_prize_amount;
    } catch (e) {
      throw new Error('Failed to play all');
    }
  }
};
