import { BspotModal } from '../../../../atoms/modal/modal';
import { Close } from '../../../../../icons/svg';

export const TermsAndConditionsModal = ({ show, setShow, tnc }) => {
  return (
    <BspotModal show={show}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Terms and Conditions'}</h2>
        <Close size={'16px'} color={'#ffffff'} onClick={setShow} />
      </div>
      <div
        style={{ overflow: 'auto' }}
        dangerouslySetInnerHTML={{ __html: tnc }}
      />
    </BspotModal>
  );
};
