// @ts-nocheck
import React from 'react';
import styled from '@emotion/styled';
import { TextProps, StyledText } from 'components/atoms/text';

const StyledLink = styled(StyledText)`
  &,
  :hover,
  :focus,
  :active {
    text-decoration: none;
  }

  cursor: pointer;
`;

export type LinkProps = {
  children?: React.ReactNode;
  href?: string;
  style?: any;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement>;
};

export function Link({
  children,
  href,
  text,
  size,
  style,
  color = 'var(--primary-color)',
  onClick,
  onMouseDown,
}: LinkProps & TextProps) {
  return (
    <StyledLink
      onClick={onClick}
      as={'a'}
      href={href}
      style={style}
      $size={size}
      $color={color}
      onMouseDown={onMouseDown}
    >
      {text}
      {children}
    </StyledLink>
  );
}
