import { toCurrency } from '../../../../../common/bspot-shared';
import {
  SdkActions,
  useGameSdk,
} from '../../../../../contexts/game-sdk/game-sdk';
import React, { useEffect, useState } from 'react';
import DepositModal from '../../../modal-make-deposit/modal-make-deposit';
import { useBalanceIncrease } from '../../../../../hooks/use-balance-increase/use-balance-increase';
import { motion } from 'framer-motion';
import { CashStack, Spinner } from '../../../../../icons/svg';
import { useBalanceAndCredits } from 'hooks/use-balance-and-credits/use-balance-and-credits';
import { useSearchParams } from 'react-router-dom';
import BuyCreditsModal from 'components/organisms/modal-buy-credits/modal-buy-credits';
import { useRemovePaymentQueryParams } from 'hooks/use-remove-payment-query-params/use-remove-payment-query-params';

export const CashButton = () => {
  const { sdkAction } = useGameSdk();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [showModalCredits, setShowModalCredits] = useState(false);
  const { balanceAndCredits: balances, isLoading: isLoadingBalances } =
    useBalanceAndCredits();
  const { removeSearchParams } = useRemovePaymentQueryParams();
  const { start, rounded } = useBalanceIncrease({
    balance: balances?.cash,
  });

  useEffect(() => {
    if (sdkAction === SdkActions.DEPOSIT_CASH) {
      setShowModal(true);
    }
  }, [sdkAction]);

  return (
    <>
      <button
        id='horseplay-cash-button'
        onClick={() => {
          removeSearchParams();
          setShowModal(true);
        }}
        className={`header-button ${
          balances?.shouldDeposit ? ' immediate' : ' initial'
        }  ${start ? ' cashIncrease' : ''}`}
        style={{
          cursor: 'pointer',
          appearance: 'none',
          border: 'unset',
        }}
      >
        {isLoadingBalances && (
          <>
            {start && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!start && (
              <Spinner
                className='animate'
                color={'var(--primary-color)'}
                size='1rem'
              />
            )}
          </>
        )}
        {!isLoadingBalances && (
          <>
            {start && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!start && (
              <CashStack
                displayState={balances?.shouldDeposit ? 'immediate' : 'initial'}
                width={'25'}
                height={'26'}
              />
            )}
          </>
        )}
        <div className='amount'>
          <span>Cash</span>
          <span
            className={`value ${
              balances?.shouldDeposit ? 'immediate' : 'initial'
            }`}
          >
            {start ? (
              <motion.div>{rounded}</motion.div>
            ) : (
              toCurrency(balances?.cash)
            )}
          </span>
        </div>
      </button>
      {showModal && (
        <DepositModal
          show
          onClose={() => setShowModal(false)}
          onDepositSuccess={() => {
            setShowModal(false);
            setShowModalCredits(true);
          }}
        ></DepositModal>
      )}
      {showModalCredits && (
        <BuyCreditsModal
          show
          onClose={() => {
            removeSearchParams();
            setShowModalCredits(false);
          }}
        />
      )}
    </>
  );
};
