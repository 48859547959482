import { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import { Blur } from '../blur/blur';

type BspotModalLayoutProps = {
  children?: ReactNode;
  smallLg?: boolean;
  bg: '#121212' | '#000000';
};
const BspotModalLayout = ({
  children,
  smallLg = false,
  bg,
}: BspotModalLayoutProps) => {
  const desktopSize = smallLg ? 'col-xl-4 col-lg-6' : 'col-xl-6 col-lg-10';
  return (
    <div className='row justify-content-center align-items-center flex-grow-1'>
      <div className={'px-lg-0 px-4 col col-12 ' + desktopSize}>
        <div
          className={'d-flex flex-column p-4 gap-4'}
          style={{
            borderRadius: '8px',
            maxHeight: '90vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor: bg,
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

type BaseModalType = {
  show: boolean;
  children?: ReactNode;
  smallLg?: boolean;
  bg?: '#121212' | '#000000';
};

export const BspotModal = ({
  show,
  children,
  smallLg = false,
  bg = '#121212',
}: BaseModalType) => {
  useEffect(() => {
    document.querySelector('body').style.overflow = show ? 'hidden' : 'auto';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, [show]);

  return (
    <>
      <Modal
        className='bspot-modal_content'
        overlayClassName={'bspot-modal-overlay'}
        isOpen={show}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <BspotModalLayout bg={bg} smallLg={smallLg}>
          {children}
        </BspotModalLayout>
      </Modal>
      <Blur $isVisible={show} />
    </>
  );
};
