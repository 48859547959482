import 'components/organisms/layout/game-header/buttons-sdk/styles.scss';
import {
  SdkActions,
  useGameSdk,
} from '../../../../../contexts/game-sdk/game-sdk';
import React, { useEffect, useState } from 'react';
import { toCurrency } from '../../../../../common/bspot-shared';
import { useBalanceIncrease } from '../../../../../hooks/use-balance-increase/use-balance-increase';
import { motion } from 'framer-motion';
import { QuickCreditsModalManager } from '../../../buy-credits/modals/quick-credits-modal-manager/quick-credits-modal-manager';
import { Spinner } from '../../../../../icons/svg';
import { CreditsMk2 } from 'icons/svg/credits/credits-mk2';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { KeepPlayingPrompt } from '../../../buy-credits/modals/quick-credits-modal-manager/keep-playing-modal';
import { useMiniModalProduct } from '../use-mini-modal-product/use-mini-modal-product';
import { useProductsAndBalances } from '../../../../../hooks/use-products-and-balances/use-products-and-balances';

type CreditsButtonType = {
  setShowGameHeader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreditsButton = ({ setShowGameHeader }: CreditsButtonType) => {
  const {
    sdkAction,
    sdkWager,
    sdkSpinsRemaining,
    sdkCredits,
    setSdkAction,
    isLoadingCreditsButton,
  } = useGameSdk();
  const { start, rounded } = useBalanceIncrease({ balance: sdkCredits });
  const [animationState, setAnimationState] = useState<
    'initial' | 'slow' | 'fast' | 'immediate'
  >('initial');
  const [showModal, setShowModal] = useState(false);
  const [miniModalCredits, setMiniModalCredits] = useState(0);
  const [prevWager, setPrevWager] = useState(null);
  const { gameProducts, instantPlayEligible, balances } =
    useProductsAndBalances();

  useEffect(() => {
    if (prevWager && prevWager !== sdkWager) {
      return;
    }

    setPrevWager(sdkWager);
  }, [sdkWager, prevWager]);

  useEffect(() => {
    if (sdkAction === SdkActions.PURCHASE_CREDITS) {
      setShowModal(true);
      setMiniModalCredits(0);
      return;
    }

    if (isLoadingCreditsButton) {
      setAnimationState('initial');
      setMiniModalCredits(0);
      return;
    }

    if (sdkSpinsRemaining === null || sdkSpinsRemaining > 20) {
      setAnimationState('initial');
      localStorage.removeItem('lc');
    } else {
      const _credsNeeded = 22 * sdkWager.toFixed(2);
      if (sdkSpinsRemaining <= 20 && sdkSpinsRemaining > 10) {
        localStorage.setItem('lc', 'true');
        setAnimationState('slow');
        setMiniModalCredits(0);
      }
      if (sdkSpinsRemaining <= 10 && sdkSpinsRemaining > 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'fast' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'fast';
        });
        localStorage.setItem('lc', 'true');
      }
      if (sdkSpinsRemaining <= 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'immediate' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'immediate';
        });
        localStorage.setItem('lc', 'true');
      }
    }

    if (sdkAction === SdkActions.IDLE) {
      setShowModal(false);
      setMiniModalCredits(0);
    }

    if (sdkCredits === 0) {
      setAnimationState('immediate');
    }
  }, [
    sdkSpinsRemaining,
    sdkAction,
    sdkCredits,
    isLoadingCreditsButton,
    sdkWager,
    miniModalCredits,
  ]);

  const { miniModalPackage } = useMiniModalProduct({
    miniModalCredits,
    gameProducts,
    cash: balances?.cash,
  });

  const showMiniModal =
    FeatureFlagsService.featureFlags?.enableChargeUpCredits && miniModalPackage;

  useEffect(() => {
    if (showMiniModal) {
      setShowGameHeader(true);
    }
  }, [showMiniModal]);

  return (
    <>
      <button
        id='horseplay-credits-button'
        onClick={() => setShowModal(true)}
        className={`header-button ${animationState} ${
          !sdkCredits && !isLoadingCreditsButton ? 'immediate' : ''
        } ${start ? ' cashIncrease' : ''}`}
        style={{
          cursor: 'pointer',
          appearance: 'none',
          border: 'unset',
        }}
      >
        {isLoadingCreditsButton && (
          <>
            {start && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!start && (
              <Spinner
                className='animate'
                color={'var(--primary-color)'}
                size='1rem'
              />
            )}
          </>
        )}

        {!isLoadingCreditsButton && (
          <>
            {start && (
              <Spinner className='animate' color={'#353535'} size='1rem' />
            )}
            {!start && (
              <CreditsMk2
                displayState={`${!sdkCredits ? 'immediate' : animationState}`}
                width={25}
                height={26}
              />
            )}
          </>
        )}
        <div className='amount'>
          <span>Credits</span>
          <span
            className={`value ${animationState} ${
              !sdkCredits && !isLoadingCreditsButton ? 'immediate' : 'initial'
            }`}
          >
            {start ? (
              <motion.div>{rounded}</motion.div>
            ) : (
              toCurrency(sdkCredits)
            )}
          </span>
        </div>
      </button>
      {showModal && (
        <QuickCreditsModalManager
          hide={() => {
            setShowModal(false);
            setSdkAction(SdkActions.IDLE);
          }}
          isLowCreditMode={animationState !== 'initial' || !sdkCredits}
          stage={animationState}
          spinsRemaining={sdkSpinsRemaining}
        />
      )}
      {showMiniModal && (
        <KeepPlayingPrompt
          hide={() => setMiniModalCredits(0)}
          stage={animationState}
          showQuickCreditsModal={() => {
            setShowModal(true);
          }}
          instantPlayEligible={instantPlayEligible}
          miniModalPackage={miniModalPackage}
        />
      )}
    </>
  );
};
