import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';
import { useAuth } from 'contexts/authentication';

export type AvailableBanks = {
  bank_id: number;
  code: string;
  icon: string;
  id: number;
  max_deposit: number;
  min_deposit: number;
  name: string;
  prefill: boolean;
  transfer_time: string;
  uniq_id?: string;
};

export const getAvailableBanks = async (): Promise<AvailableBanks[]> => {
  const { data, ok } = await Api.get<StriveResponse<AvailableBanks[]>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/available_banks`,
  );
  if (!ok) {
    throw new Error('Failed to retrieve available banks');
  }
  return data.Data;
};

export const useGetAvailableBanks = () => {
  const { token, profile } = useAuth();
  return useQuery({
    queryKey: ['available-banks', profile?.playerId],
    queryFn: getAvailableBanks,
    cacheTime: 1200000,
    enabled: !!token && !!profile,
  });
};
