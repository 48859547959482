import styled from '@emotion/styled';

export const ModalContentTableFlex = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
  height: 300px;
  @media screen and (max-width: 500px) {
    height: 170px;
  }

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  #scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  border-radius: 10px;
  border: 0.5px solid rgba(68, 68, 68, 1);

  table {
    box-sizing: border-box;
    border-collapse: collapse;
    color: white;
    width: 100%;

    .table-wrap {
      max-height: 400px;
      overflow-y: scroll;
    }

    @media screen and (max-width: 500px) {
      font-size: 11px;
    }
  }

  .table-header {
    font-family: 'Roboto';
    padding: 8px;
    border-radius: 10px 10px 0 0;
    background-color: rgba(68, 68, 68, 1);
    display: table-caption;
    //top: -10px;
    position: sticky;
  }
  .table-header tr {
    display: flex;
    justify-content: space-between;
  }

  .table-row {
    border-bottom: 1px solid rgba(68, 68, 68, 1);
    display: flex;
    justify-content: space-between;
  }

  td {
    padding: 8px;
  }
  .position {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: rgba(194, 194, 194, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .jockey-name {
    font-weight: 400;
  }
  .td-flex {
    display: flex;
  }
  .chance {
    background-color: rgba(115, 115, 115, 1);
    width: 38px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ml {
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .ml p,
  .names p {
    margin: 0;
    font-size: 16px;
    @media screen and (max-width: 500px) {
      font-size: 11px;
    }
  }
  .names {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }
  .race-name {
    color: #d24f1c;
    cursor: pointer;
  }
  .winnings {
    color: #73b46a;
  }
`;
