import './index.scss';
import React, { useState } from 'react';
import { CrossCircle, Info } from 'icons/svg';
import { Collapse } from '@mui/material';
import { TournamentType } from 'services/tournaments/tournaments';
import { useNewLeaderBoard } from 'hooks/use-leaderboard';
import { useAuth } from 'contexts/authentication';
import { LeaderboardWidget } from 'components/organisms/tournament-widget/tournament-widget-components/leaderboards/leaderboard';
import { TimerWidget } from 'components/organisms/tournament-widget/tournament-widget-components/timer/timer';
import { TournamentInGameIcon } from 'icons/svg/tournament-in-game';
import { DescriptionWidget } from 'components/organisms/tournament-widget/tournament-widget-components/description/description';

type TournamentProps = {
  tournament: TournamentType;
  refetchTournaments?: () => void;
};

export const TournamentWidget = ({
  tournament,
  refetchTournaments,
}: TournamentProps) => {
  const { auth } = useAuth();
  const { data: items } = useNewLeaderBoard(
    tournament?.id,
    tournament?.rewards,
    auth?.session?.playerId?.toString(),
    tournament?.isOptedIn,
    auth?.member?.name,
  );

  const [showInfo, setShowInfo] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  return (
    <div className='tournament-widget'>
      <div className='tournament-widget-top'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <span
            className='tournament-widget-title'
            data-testid={`${tournament?.id}-title`}
          >
            {tournament?.title}
          </span>
        </div>
        <TimerWidget
          isActive={tournament?.status === 'Active'}
          startTime={new Date(tournament?.startTime)}
          endTime={new Date(tournament?.endTime)}
          contestId={tournament?.id}
          id={tournament?.id}
          fetchTournament={refetchTournaments}
          removeTournament={refetchTournaments}
        />

        <button
          className='leads-button-widget'
          aria-label='show leaderboards'
          data-toggle='collapse'
          onClick={() => setShowLeaderboard(!showLeaderboard)}
        >
          <TournamentInGameIcon
            height={'16px'}
            width={'16px'}
            fill={'#FFB71A'}
          />
        </button>

        <button
          className='info-button-widget'
          aria-label='more information'
          data-testid={`${tournament?.id}-info-toggle`}
          data-toggle='collapse'
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? (
            <CrossCircle size='16px' color='white' />
          ) : (
            <Info size='16px' color='white' />
          )}
        </button>
      </div>
      <Collapse in={showInfo}>
        <DescriptionWidget
          description={tournament?.description}
          id={tournament?.id}
          tnc={tournament?.tnc}
          handleClose={() => setShowInfo(!showInfo)}
        />
      </Collapse>

      <Collapse in={showLeaderboard}>
        <LeaderboardWidget
          contestId={tournament?.id}
          leaderboardItems={items}
        />
      </Collapse>
    </div>
  );
};
