import { useMutation } from '@tanstack/react-query';
import { Api } from 'common/api';

export const postLaunchGame = async (payload) => {
  const { ok, data } = await Api.post<{ Data: { url: string } }>(
    `${API_HOST}/api/strive-proxy/restapi/v1/casino/launch_url`,
    {
      data: {
        id: payload.id,
        game_provider: 'CasinoGames::GPN',
        source: 1,
        game_id: payload.game_id,
        demo: 0,
      },
    },
  );

  if (!ok) throw new Error('Could not retrieve game url');

  return data.Data;
};

export const useLaunchGame = () => {
  return useMutation({ mutationFn: postLaunchGame });
};
