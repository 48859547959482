export const interceptHttpRequests = (invalidateUserAuth: () => void) => {
  const originalFetch = window.fetch;

  window.fetch = async (...args) => {
    let [resource, config] = args;

    // todo, check where we need to invoke a sign out to the next auth session, e.g. websocket tracker.
    const isNextAuthRequest = resource.toString().includes(`/api/auth`);
    if (isNextAuthRequest) {
      const isSignOut = resource.toString().includes(`/api/auth/signout`);
      config = { method: isSignOut ? 'POST' : 'GET', credentials: 'include' };
    }

    let response = await originalFetch(resource, config);

    const isMadeToBFF =
      response.url.startsWith(API_HOST) || response.url.startsWith(NEXT_HOST);
    const isLogin = response.url.includes('/api/v1/auth');
    const isRegister = response.url.includes('/api/v1/register');
    const isBuyCredits = response.url.includes('/rgs-proxy/api/v1/orders');
    if (!isMadeToBFF || isLogin || isRegister || isBuyCredits) return response;

    if (response.status === 401 || response.status === 403) {
      // todo test this
      invalidateUserAuth();
      return Promise.reject(response);
    }

    return response;
  };
};
