import { useQuery } from '@tanstack/react-query';
import { Api } from 'common/api';
import { useAuth } from '../../contexts/authentication';

export const changeProfileSettings = async (
  payload,
): Promise<Record<string, any>> => {
  const { data, ok } = await Api.post<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/user/settings/change_gdpr_settings`,
    { data: payload },
  );
  if (!ok) {
    throw new Error(data.Message);
  }
  return data;
};

export const getCommunicationPreferences = async (): Promise<
  Record<string, any>
> => {
  const { data, ok } = await Api.get<StriveResponse<any>>(
    `${API_HOST}/api/strive-proxy/railsapi/v1/user/profile/communication_preferences`,
  );
  if (!ok) {
    throw new Error(data.Message);
  }
  return data;
};

export const useCommunicationPreferences = () => {
  // !important, we have a racing condition.
  // this request was firing off once we had a nex session but,
  // auth headers have NOT been set yet and throwing us a logout.
  // We need to listen to the internal auth state which is set after strive headers to truly know if we can make this request.
  const { auth } = useAuth();
  return useQuery(['communication-preferences'], {
    queryFn: getCommunicationPreferences,
    enabled: !!auth.session,
  });
};
