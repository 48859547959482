import { Api } from 'common/api';

export const getFreePlayAwardedContent = () => {
  return Api.get<any>(`${API_HOST}/api/v1/free-play-awarded-content`).then(
    (res) => {
      if (!res.ok) {
        throw new Error('Could not retrieve content');
      }
      return res.data;
    },
  );
};
