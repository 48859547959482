import React from 'react';
import { PlayerItemWidget } from './item/player-item';
import { LeaderboardEntry } from '../../../../../services/tournaments/tournaments';
import SkeletonLoader from 'components/organisms/tournament-card/tournament-components/leaderboards/skeleton-loader';
type LeaderboardProps = {
  contestId: string;
  leaderboardItems: LeaderboardEntry[];
};
export const LeaderboardWidget = ({
  leaderboardItems,
  contestId,
}: LeaderboardProps) => {
  return (
    <div
      className='tournament-widget-leaderboards'
      data-testid={`${contestId}-leaderboard`}
    >
      {!leaderboardItems
        ? [1, 2, 3, 4, 5, 6].map((item) => (
            <SkeletonLoader
              key={item}
              bgColor={item % 2 === 0 ? '#141e21' : '#1e262d'}
            />
          ))
        : leaderboardItems &&
          leaderboardItems.map((item, index) => (
            <PlayerItemWidget key={index} {...item} />
          ))}
    </div>
  );
};
