import { SnackbarContent } from 'notistack';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import './Toast.scss';

export type ToastProps = {
  title?: ReactNode;
  message?: ReactNode;
  icon?: ReactNode;
  type?: 'default' | 'success' | 'warning';
  closeHandler?: () => void;
  href?: string;
};

export const Toast = forwardRef(
  (
    { message, title, icon, type = 'default', closeHandler, href }: ToastProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <SnackbarContent ref={ref}>
        <div className={'toast ' + type}>
          <div className='icon'>{icon}</div>

          <div
            className={
              'd-flex flex-row justify-content-between align-items-center'
            }
            style={{ width: '100%' }}
          >
            <div className='d-flex flex-column align-items-center align-middle'>
              {title && <div className='title'>{title}</div>}
              {message && <div className='message'>{message}</div>}
            </div>

            <button className='close' onClick={closeHandler}>
              <img
                src='/js_assets/images/alert-close.png'
                alt='alert close'
                width={36}
                height={36}
              />
            </button>
          </div>
        </div>
      </SnackbarContent>
    );
  },
);
