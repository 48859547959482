import { Routes } from '../../../../common/routes';
import { CreditsButton as CreditsButtonSDK } from './buttons-sdk/credits-button';
import { CashButton as CashButtonSDK } from './buttons-sdk/cash-button';
import { CreditsButton } from './buttons/credits-button';
import { CashButton } from './buttons/cash-button';
import { useGameSdk } from '../../../../contexts/game-sdk/game-sdk';
import { ArrowRight } from '../../../../icons/svg';
import { Link } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import './game-header.scss';
import { useTournamentsInGame } from 'contexts/tournaments/tournaments-context';
import { TournamentCard } from 'components/organisms/tournament-card/tournament-card';
import { TournamentInGame } from 'components/organisms/layout/game-header/tournament-in-game/tournament-in-game';

export const GameHeader = () => {
  const { sdkStatus, sdkSpinsRemaining } = useGameSdk();
  const [showGameHeader, setShowGameHeader] = useState(true);

  const [showT, setShowT] = useState(null);

  const { tournament, refetchTournaments } = useTournamentsInGame();

  useEffect(() => {
    if (tournament) {
      setShowT(tournament);
      return;
    }
  }, [tournament]);

  const exitFullScreen = () => {
    if (document.exitFullscreen) document.exitFullscreen();
    // @ts-ignore
    else if (document.webkitExitFullscreen)
      // @ts-ignore
      document.webkitExitFullscreen();
    // @ts-ignore
    else if (document.msExitFullscreen) document.msExitFullscreen();
  };

  return (
    <nav className='nav-game'>
      <div
        className='game-header'
        style={{ marginTop: showGameHeader ? '0' : '-65px' }}
      >
        <div
          className={
            'd-flex flex-row gap-2 align-items-center justify-content-center'
          }
        >
          <Link
            to={Routes.Lobby.path}
            onClick={exitFullScreen}
            className='back-arrow'
          >
            <ArrowRight fill='#0AEB88' size='30px' />
          </Link>

          {showT &&
            FeatureFlagsService.featureFlags?.enableInGameTournament && (
              <TournamentInGame
                tournament={showT}
                refetchTournaments={refetchTournaments}
              />
            )}
        </div>

        <div className='game-header-actions'>
          {sdkStatus === 'disabled' && (
            <>
              <CashButton />
              <CreditsButton setShowGameHeader={setShowGameHeader} />
            </>
          )}

          {sdkStatus === 'enabled' && (
            <>
              <CashButtonSDK />
              <CreditsButtonSDK setShowGameHeader={setShowGameHeader} />
            </>
          )}
          <a
            className='powered-by-horse-racing'
            href='https://gameplaynetwork.com/powered-by/'
            target='_blank'
            rel='noreferrer noopener'
          >
            <img
              src='/js_assets/images/powered_by_horse_racing_circle.webp'
              alt='powered by horse racing'
            />
          </a>
        </div>
      </div>
      <div className='game-header-toggle'>
        <button onClick={() => setShowGameHeader(!showGameHeader)}>
          <span className={showGameHeader ? 'active' : ''}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='38'
              height='9'
              viewBox='0 0 32 9'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.7734 7.69778L35.4524 0.286369C35.8555 0.107229 36.4277 0.155477 36.7303 0.394135C37.0329 0.632792 36.9514 0.971482 36.5482 1.15062L19.3876 8.77605C19.2041 8.85759 18.9855 8.89202 18.7734 8.88282C18.5612 8.89202 18.3427 8.8576 18.1592 8.77605L0.998482 1.15062C0.595338 0.971482 0.513833 0.632791 0.816441 0.394134C1.11905 0.155477 1.69117 0.107229 2.09432 0.286369L18.7734 7.69778Z'
                fill='#0AEB88'
              />
            </svg>
          </span>
        </button>
      </div>
      <div className='bottom-line'></div>
    </nav>
  );
};
