import styled from '@emotion/styled';
import confetti from 'canvas-confetti';
import { Text } from 'components/atoms/text';
import { useToaster } from 'contexts/toaster-provider/toaster-provider';
import { WSMessage } from 'hooks/use-webpush-listener';

const ToastTitle = styled(Text)`
  font-size: 18px;
  font-weight: 700;
`;

export const useShowFreeplayAward = () => {
  const { pushToast, removeToast } = useToaster();
  const showFreeplayAwardToast = (
    firstName: string,
    message: WSMessage,
    onClose: () => void = () => {},
  ) => {
    const amount = message?.data?.parameters?.amount / 100;

    const successToast = pushToast({
      title: <ToastTitle>Congratulations {firstName}!</ToastTitle>,
      message: `You have been awarded $${amount} in Freeplay! The Credits will be added to
      your balance shortly. Happy spinning!`,
      type: 'default',
      icon: (
        <img
          src='/js_assets/images/gift-animation.gif'
          style={{ width: '30px', height: '30px' }}
        />
      ),
      closeHandler: () => {
        onClose();
        removeToast(successToast);
      },
      persist: true,
      // id: message.id,
    });

    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 0, y: 1.3 },
    });
    confetti({
      particleCount: 450,
      spread: 80,
      startVelocity: 130,
      origin: { x: 1, y: 1.3 },
    });
  };

  return showFreeplayAwardToast;
};
