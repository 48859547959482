import { useQuery } from '@tanstack/react-query';

export type GeoLocation = {
  latitude: number | string;
  longitude: number | string;
};
export const fetchGeolocation = (): Promise<{
  ok: boolean;
  data: GeoLocation;
}> => {
  return new Promise((res, rej) =>
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const data = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        res({
          ok: true,
          data,
        });
      },
      () => {
        rej({
          ok: false,
          data: {
            latitude: 0,
            longitude: 0,
          },
        });
      },
      { maximumAge: Infinity },
    ),
  );
};

export const useGeolocation = () => {
  return useQuery({
    queryKey: ['use-geolocation'],
    queryFn: () => fetchGeolocation(),
    keepPreviousData: false,
  });
};
