import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'contexts/authentication';
import { getKYCAttempts } from 'services/kyc/kyc';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';

export const useKycStatus = () => {
  const { auth } = useAuth();
  const { data: status } = usePlayerStatus();

  return useQuery({
    queryKey: ['kyc-status'],
    queryFn: async () => {
      const kyc = await getKYCAttempts();
      // await updateKyc(kyc);
      return kyc;
    },
    keepPreviousData: false,
    enabled: status?.validator_status !== 1 && !!auth.session,
  });
};
