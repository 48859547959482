import { Close } from 'icons/svg';
import './styles.scss';
import { useEffect, useState } from 'react';
import { useAuth } from 'contexts/authentication';
import { Link } from 'react-router-dom';
import { Routes } from '../../../common/routes';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { AppLoader } from '../../molecules/app-loader/app-loader';
import { useFreePlayAwardedContent } from '../../../hooks/use-free-play-awarded-content/use-free-play-awarded-content';

type ModalFreePlayAwardedProps = {
  onclose(): void;
};
export const ModalFreePlayAwarded = ({
  onclose,
}: ModalFreePlayAwardedProps) => {
  const { data: content = null } = useFreePlayAwardedContent();
  const [isHovered, setIsHovered] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { auth } = useAuth();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeIndex < 3) {
        setActiveIndex(activeIndex + 1);
      } else {
        setActiveIndex(0);
      }
    }, 2000);
    if (isHovered) {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex, isHovered]);

  const onClose = () => {
    document.body.style.overflow = 'auto';
    onclose();
  };

  if (!auth?.session || !content) {
    return (
      <div className='modal-free-play-awarded-overlay'>
        <div className='modal-free-play-awarded d-flex flex-column justify-content-center align-items-center gap-2 my-4 text-center'>
          <AppLoader />
        </div>
      </div>
    );
  }

  return (
    <div className='modal-free-play-awarded-overlay'>
      <div className='modal-free-play-awarded d-flex flex-column justify-content-center align-items-center gap-2 my-4 text-center'>
        <div className='modal-free-play-awarded-close' onClick={onClose}>
          <Close size={'16px'} color={'#ffffff'} />
        </div>
        <h3 className='modal-free-play-awarded-header'>
          {content.title} {auth.session.firstName}
        </h3>
        <RichTextRenderer content={content.richText} />
        <div className='modal-free-play-awarded-games'>
          {content?.featuredGames?.map((game, index) => (
            <Link
              key={index}
              to={
                Routes.Game.path +
                '?gameId=' +
                game.game_id +
                '&id=' +
                game.id +
                '&or=' +
                game.orientation
              }
              onClick={onClose}
              onMouseEnter={() => {
                setIsHovered(true);
                setActiveIndex(index);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
              className={activeIndex === index ? 'active' : ''}
            >
              <img src={game.icon_url} alt='' />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const RichTextRenderer = ({ content }: { content: Document }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      ),
      [MARKS.ITALIC]: (text) => <span className='text-primary'>{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageProps = node.data.target.fields;
        return (
          <img
            src={`https:${imageProps.file.url}?fm=webp&q=100`}
            alt={imageProps.description || 'rich text image'}
            width={imageProps.file.details.image.width}
            height={imageProps.file.details.image.height}
          />
        );
      },
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className='richText heading2 app-container'>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className='richText heading3 leading-none app-container'>
          {children}
        </h3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className='richText paragraph app-container'>{children}</p>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { target } = node.data;
        if (target.sys.contentType.sys.id === 'richTextImage') {
          return <RichTextImage fields={target.fields} />;
        }
        return <></>;
      },
    },
  };

  return <>{documentToReactComponents(content, options)}</>;
};

type Image = {
  description: string;
  file: {
    url: string;
    details: {
      image: {
        width: number;
        height: number;
      };
    };
  };
};

export type RichTextImageProps = {
  fields: {
    image: { fields: Image };
    mobileSize: '1/12' | '1/6' | '1/4' | '1/2' | '1';
    fullscreenSize: '1/12' | '1/6' | '1/4' | '1/2' | '1';
    position: 'left' | 'center' | 'right';
    imageFullscreen?: { fields: Image };
  };
};

const sizeMapping = {
  '1/12': 'w-twelfth',
  '1/6': 'w-sixth',
  '1/4': 'w-quarter',
  '1/2': 'w-half',
  '1': 'w-full',
};
export const RichTextImage = ({
  fields: { image, position, mobileSize, fullscreenSize, imageFullscreen },
}: RichTextImageProps) => {
  const mobile = sizeMapping[mobileSize];
  const fullscreen = sizeMapping[fullscreenSize];
  return (
    <div
      style={{
        width: '100%',
      }}
      className={[
        'd-flex',
        position === 'left' ? 'justify-content-start' : '',
        position === 'center' ? 'justify-content-center' : '',
        position === 'right' ? 'justify-content-end' : '',
      ].join(' ')}
    >
      <img
        className={`richTextImage ${
          imageFullscreen ? 'hidden' : ''
        } h-auto ${mobile}`}
        src={`https:${image.fields.file.url}?fm=webp`}
        alt={image.fields.description || 'rich text image'}
        width={image.fields.file.details.image.width}
        height={image.fields.file.details.image.height}
      />
      {imageFullscreen && (
        <>
          <img
            className={`richTextImageFullscreen h-auto ${fullscreen}`}
            src={`https:${imageFullscreen.fields.file.url}?fm=webp`}
            alt={imageFullscreen.fields.description || 'rich text image'}
            width={imageFullscreen.fields.file.details.image.width}
            height={imageFullscreen.fields.file.details.image.height}
          />
        </>
      )}
    </div>
  );
};
