import { Api } from 'common/api';

export type KycAttempts = {
  attempts: number;
  max_attempts: number;
};
export const getKYCAttempts = async (): Promise<KycAttempts> => {
  const { ok, data } = await Api.get<StriveResponse<KycAttempts>>(
    `${API_HOST}/api/strive-proxy/restapi/v1/user/kyc_attempts`,
  );
  if (!ok) {
    throw new Error('Could not retrieve kyc status');
  }
  return data.Data;
};
