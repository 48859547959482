import React from 'react';
import { SvgProps } from './svg';

export function TournamentInGameStarIcon({
  size,
  width = '8',
  height = '8',
  fill = '#0AEB88',
}: SvgProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size || width}
      height={size || height}
      viewBox='0 0 8 8'
      fill='none'
    >
      <path
        d='M1.55159 7.65863L2.18591 4.91638L0.0584717 3.07194L2.86904 2.82797L3.96204 0.241859L5.05503 2.82797L7.8656 3.07194L5.73816 4.91638L6.37249 7.65863L3.96204 6.20455L1.55159 7.65863Z'
        fill={fill}
      />
    </svg>
  );
}
