import React from 'react';

export function Home({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3 14.2C11.2333 14.1333 11.1667 14.1 11.1 14.1C11.0333 14.0333 10.9667 14 10.9 14C10.7667 14 10.6667 14.0333 10.6 14.1C10.5333 14.1 10.4667 14.1333 10.4 14.2L1.5 23.3C1.36667 23.4333 1.23333 23.6333 1.1 23.9C1.03333 24.1 1 24.3333 1 24.6V34.2C1 34.6667 1.16667 35.0667 1.5 35.4C1.83333 35.8 2.23333 36 2.7 36H19C19.4667 36 19.8667 35.8 20.2 35.4C20.5333 35.0667 20.7 34.6667 20.7 34.2V24.6C20.7 24.0667 20.5333 23.6333 20.2 23.3L11.3 14.2ZM15.5 34.7C15.2 34.7 15 34.5 15 34.2V26.1C15 25.9667 14.9333 25.8333 14.8 25.7C14.6667 25.5667 14.5 25.5 14.3 25.5H7.4C7.2 25.5 7.03333 25.5667 6.9 25.7C6.83333 25.8333 6.8 25.9667 6.8 26.1V34.2C6.8 34.5 6.5 34.7 6.3 34.7H2.8C2.5 34.7 2.3 34.5 2.3 34.2V24.5C2.3 24.4 2.3 24.3 2.4 24.2L10.5 15.9C10.7 15.8 11 15.8 11.2 15.9L19.3 24.2C19.4 24.3 19.4 24.4 19.4 24.6V34.2C19.4 34.5 19.2 34.7 18.9 34.7H15.5ZM8.1 27.3C8.1 27 8.3 26.8 8.6 26.8H13.2C13.4 26.8 13.7 27 13.7 27.3V34.2C13.7 34.5 13.4 34.7 13.2 34.7H8.6C8.3 34.7 8.1 34.5 8.1 34.2V27.3Z'
        fill='#0AEB88'
      />
      <path
        d='M11.3 14.2C11.2333 14.1333 11.1667 14.1 11.1 14.1C11.0333 14.0333 10.9667 14 10.9 14C10.7667 14 10.6667 14.0333 10.6 14.1C10.5333 14.1 10.4667 14.1333 10.4 14.2L1.5 23.3C1.36667 23.4333 1.23333 23.6333 1.1 23.9C1.03333 24.1 1 24.3333 1 24.6V34.2C1 34.6667 1.16667 35.0667 1.5 35.4C1.83333 35.8 2.23333 36 2.7 36H19C19.4667 36 19.8667 35.8 20.2 35.4C20.5333 35.0667 20.7 34.6667 20.7 34.2V24.6C20.7 24.0667 20.5333 23.6333 20.2 23.3L11.3 14.2ZM11.3 14.2V14.2ZM10.5 15.9L2.4 24.2C2.3 24.3 2.3 24.4 2.3 24.5V34.2C2.3 34.5 2.5 34.7 2.8 34.7H6.3C6.5 34.7 6.8 34.5 6.8 34.2V26.1C6.8 25.9667 6.83333 25.8333 6.9 25.7C7.03333 25.5667 7.2 25.5 7.4 25.5H14.3C14.5 25.5 14.6667 25.5667 14.8 25.7C14.9333 25.8333 15 25.9667 15 26.1V34.2C15 34.5 15.2 34.7 15.5 34.7H18.9C19.2 34.7 19.4 34.5 19.4 34.2V24.6C19.4 24.4 19.4 24.3 19.3 24.2L11.2 15.9C11 15.8 10.7 15.8 10.5 15.9ZM10.5 15.9V15.9ZM8.1 27.3C8.1 27 8.3 26.8 8.6 26.8H13.2C13.4 26.8 13.7 27 13.7 27.3V34.2C13.7 34.5 13.4 34.7 13.2 34.7H8.6C8.3 34.7 8.1 34.5 8.1 34.2V27.3Z'
        stroke='#0AEB88'
        strokeWidth='0.2'
      />
    </svg>
  );
}

export function HomeFill({
  width = 22,
  height = 55,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6834 14.3254L20.1662 22.9953C20.5763 23.4206 20.8052 23.9887 20.8043 24.5796V33.7995C20.8051 34.3628 20.59 34.9049 20.2031 35.3142C19.8163 35.7236 19.2872 35.969 18.7249 36H15.3031V26.0979C15.3031 25.8061 15.1872 25.5263 14.9809 25.3199C14.7745 25.1136 14.4947 24.9977 14.2029 24.9977H7.60151C7.30971 24.9977 7.02987 25.1136 6.82353 25.3199C6.6172 25.5263 6.50128 25.8061 6.50128 26.0979V36H3.07956C2.51719 35.969 1.98811 35.7236 1.60127 35.3142C1.21442 34.9049 0.999274 34.3628 1.00013 33.7995V24.5796C0.996997 24.2882 1.0518 23.999 1.16134 23.7289C1.27089 23.4588 1.433 23.2132 1.63826 23.0063L10.121 14.3254C10.2233 14.2223 10.345 14.1405 10.4791 14.0846C10.6132 14.0288 10.757 14 10.9022 14C11.0475 14 11.1913 14.0288 11.3253 14.0846C11.4594 14.1405 11.5811 14.2223 11.6834 14.3254ZM8.70166 27.1981H13.1026V34.8997H8.70166V27.1981Z'
        fill='#0AEB88'
      />
    </svg>
  );
}
