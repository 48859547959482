import React from 'react';
import { useStateRef } from 'hooks/use-state-ref';

export const useDelayUnmount = (
  isMounted: boolean,
  delayTime: number,
): [boolean, React.MutableRefObject<boolean>] => {
  const [shouldRender, setShouldRender, shouldRenderRef] =
    useStateRef<boolean>(false);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender, setShouldRender]);
  return [shouldRender, shouldRenderRef];
};
