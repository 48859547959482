import './styles.scss';

type CreditsProps = {
  displayState?: 'initial' | 'slow' | 'fast' | 'immediate';
  width?: number;
  height?: number;
};
export const CreditsMk2 = ({
  displayState = 'initial',
  width = 89,
  height = 81,
}: CreditsProps) => {
  const icon = {
    initial: (
      <>
        <path
          d='M50.7171 77.5372C30.8309 77.5372 15.9697 70.9696 15.9697 65.0893V49.9685C15.9697 44.0881 30.8309 37.5205 50.7171 37.5205C70.6032 37.5205 85.4644 44.0881 85.4644 49.9685V65.0893C85.4644 70.9696 70.6032 77.5372 50.7171 77.5372Z'
          fill='#002B0C'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543V65.0751C82.7915 67.916 71.5502 74.8502 50.7171 74.8502C29.884 74.8502 18.6426 67.9007 18.6426 65.0751V49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543V65.0751C13.2969 73.4298 30.052 80.196 50.7171 80.196C71.3822 80.196 88.1373 73.4298 88.1373 65.0751V49.9543C88.1373 41.5997 71.3822 34.8335 50.7171 34.8335Z'
          fill='#0AEB88'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543C82.7915 50.5347 82.0737 51.726 80.0576 53.1312C74.3147 57.1482 62.7985 59.7294 50.7171 59.7294C38.6357 59.7294 27.1194 57.1329 21.3766 53.1312C19.3605 51.726 18.6426 50.5347 18.6426 49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543C13.2969 52.7188 15.1297 55.3001 18.3219 57.53C24.7979 62.051 36.8792 65.0904 50.7324 65.0904C64.5855 65.0904 76.6669 62.051 83.1428 57.53C86.335 55.3001 88.1678 52.7188 88.1678 49.9696C88.1678 41.615 71.4128 34.8488 50.7476 34.8488L50.7171 34.8335Z'
          fill='#0AEB88'
        />
        <path
          d='M40.1567 60.9658V76.7129'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M68.9785 60.9658V76.7129'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M50.7173 56.7654C63.092 56.7654 73.1236 53.7702 73.1236 50.0756C73.1236 46.3809 63.092 43.3857 50.7173 43.3857C38.3427 43.3857 28.311 46.3809 28.311 50.0756C28.311 53.7702 38.3427 56.7654 50.7173 56.7654Z'
          fill='#0AEB88'
        />
        <path
          d='M37.8176 60.6471C17.9315 60.6471 3.07031 54.0795 3.07031 48.1991V33.0783C3.07031 27.198 17.9315 20.6304 37.8176 20.6304C57.7038 20.6304 72.565 27.198 72.565 33.0783V48.1991C72.565 54.0795 57.7038 60.6471 37.8176 60.6471Z'
          fill='#002B0C'
        />
        <path
          d='M37.8177 23.2876C58.6508 23.2876 69.8921 30.2371 69.8921 33.0627V48.1835C69.8921 51.0244 58.6508 57.9586 37.8177 57.9586C16.9845 57.9586 5.74321 51.0091 5.74321 48.1835V33.0627C5.74321 30.2218 16.9845 23.2876 37.8177 23.2876ZM37.8177 17.9419C17.1526 17.9419 0.397461 24.7081 0.397461 33.0627V48.1835C0.397461 56.5382 17.1526 63.3044 37.8177 63.3044C58.4828 63.3044 75.2379 56.5382 75.2379 48.1835V33.0627C75.2379 24.7081 58.4828 17.9419 37.8177 17.9419Z'
          fill='#0AEB88'
        />
        <path
          d='M37.8177 23.2876C58.6508 23.2876 69.8921 30.2371 69.8921 33.0627C69.8921 33.6431 69.1743 34.8344 67.1582 36.2396C61.4153 40.2566 49.8991 42.8378 37.8177 42.8378C25.7363 42.8378 14.22 40.2413 8.47717 36.2396C6.46106 34.8344 5.74321 33.6431 5.74321 33.0627C5.74321 30.2218 16.9845 23.2876 37.8177 23.2876ZM37.8177 17.9419C17.1526 17.9419 0.397461 24.7081 0.397461 33.0627C0.397461 35.812 2.23029 38.3932 5.42246 40.6231C11.8984 45.1441 23.9798 48.1835 37.8329 48.1835C51.6861 48.1835 63.7674 45.1441 70.2434 40.6231C73.4356 38.3932 75.2684 35.812 75.2684 33.0627C75.2684 24.7081 58.5133 17.9419 37.8482 17.9419H37.8177Z'
          fill='#0AEB88'
        />
        <path
          d='M30.1348 44.0591V59.8061'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M58.9561 44.0591V59.8061'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M37.8179 39.8748C50.1926 39.8748 60.2242 36.8796 60.2242 33.1849C60.2242 29.4903 50.1926 26.4951 37.8179 26.4951C25.4433 26.4951 15.4116 29.4903 15.4116 33.1849C15.4116 36.8796 25.4433 39.8748 37.8179 39.8748Z'
          fill='#0AEB88'
        />
        <path
          d='M48.1375 43.2209C28.2513 43.2209 13.3901 36.6533 13.3901 30.773V15.6522C13.3901 9.75659 28.2513 3.18896 48.1375 3.18896C68.0236 3.18896 82.8848 9.75659 82.8848 15.6369V30.7577C82.8848 36.638 68.0236 43.2057 48.1375 43.2057V43.2209Z'
          fill='#002B0C'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379V30.7587C80.212 33.5996 68.9706 40.5338 48.1375 40.5338C27.3044 40.5338 16.063 33.6149 16.063 30.774V15.6532C16.063 12.8123 27.3044 5.87811 48.1375 5.87811M48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379V30.7587C10.7173 39.1134 27.4724 45.8796 48.1375 45.8796C68.8026 45.8796 85.5577 39.1134 85.5577 30.7587V15.6379C85.5577 7.28327 68.8026 0.51709 48.1375 0.51709Z'
          fill='#0AEB88'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379C80.212 16.2183 79.4941 17.4096 77.478 18.8148C71.7351 22.8318 60.2189 25.413 48.1375 25.413C36.0561 25.413 24.5399 22.8165 18.797 18.8148C16.7809 17.4096 16.063 16.2183 16.063 15.6379C16.063 12.797 27.3044 5.86283 48.1375 5.86283ZM48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379C10.7173 18.4024 12.5501 20.9837 15.7423 23.2136C22.2183 27.7346 34.2997 30.774 48.1528 30.774C62.0059 30.774 74.0873 27.7346 80.5633 23.2136C83.7554 20.9837 85.5883 18.4024 85.5883 15.6532C85.573 7.28327 68.8179 0.51709 48.1375 0.51709Z'
          fill='#0AEB88'
        />
        <path
          d='M33.8574 26.6494V42.3964'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M62.6777 26.6494V42.3964'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M48.1377 22.4499C60.5124 22.4499 70.5441 19.4548 70.5441 15.7601C70.5441 12.0654 60.5124 9.07031 48.1377 9.07031C35.7631 9.07031 25.7314 12.0654 25.7314 15.7601C25.7314 19.4548 35.7631 22.4499 48.1377 22.4499Z'
          fill='#0AEB88'
        />
      </>
    ),
    slow: (
      <>
        <path
          d='M50.7171 77.5372C30.8309 77.5372 15.9697 70.9696 15.9697 65.0893V49.9685C15.9697 44.0881 30.8309 37.5205 50.7171 37.5205C70.6032 37.5205 85.4644 44.0881 85.4644 49.9685V65.0893C85.4644 70.9696 70.6032 77.5372 50.7171 77.5372Z'
          fill='#002B0C'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543V65.0751C82.7915 67.916 71.5502 74.8502 50.7171 74.8502C29.884 74.8502 18.6426 67.9007 18.6426 65.0751V49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543V65.0751C13.2969 73.4298 30.052 80.196 50.7171 80.196C71.3822 80.196 88.1373 73.4298 88.1373 65.0751V49.9543C88.1373 41.5997 71.3822 34.8335 50.7171 34.8335Z'
          fill='#0AEB88'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543C82.7915 50.5347 82.0737 51.726 80.0576 53.1312C74.3147 57.1482 62.7985 59.7294 50.7171 59.7294C38.6357 59.7294 27.1194 57.1329 21.3766 53.1312C19.3605 51.726 18.6426 50.5347 18.6426 49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543C13.2969 52.7188 15.1297 55.3001 18.3219 57.53C24.7979 62.051 36.8792 65.0904 50.7324 65.0904C64.5855 65.0904 76.6669 62.051 83.1428 57.53C86.335 55.3001 88.1678 52.7188 88.1678 49.9696C88.1678 41.615 71.4128 34.8488 50.7476 34.8488L50.7171 34.8335Z'
          fill='#0AEB88'
        />
        <path
          d='M40.1567 60.9658V76.7129'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M68.9785 60.9658V76.7129'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M50.7173 56.7654C63.092 56.7654 73.1236 53.7702 73.1236 50.0756C73.1236 46.3809 63.092 43.3857 50.7173 43.3857C38.3427 43.3857 28.311 46.3809 28.311 50.0756C28.311 53.7702 38.3427 56.7654 50.7173 56.7654Z'
          fill='#0AEB88'
        />
        <path
          d='M37.8176 60.6471C17.9315 60.6471 3.07031 54.0795 3.07031 48.1991V33.0783C3.07031 27.198 17.9315 20.6304 37.8176 20.6304C57.7038 20.6304 72.565 27.198 72.565 33.0783V48.1991C72.565 54.0795 57.7038 60.6471 37.8176 60.6471Z'
          fill='#002B0C'
        />
        <path
          d='M37.8177 23.2876C58.6508 23.2876 69.8921 30.2371 69.8921 33.0627V48.1835C69.8921 51.0244 58.6508 57.9586 37.8177 57.9586C16.9845 57.9586 5.74321 51.0091 5.74321 48.1835V33.0627C5.74321 30.2218 16.9845 23.2876 37.8177 23.2876ZM37.8177 17.9419C17.1526 17.9419 0.397461 24.7081 0.397461 33.0627V48.1835C0.397461 56.5382 17.1526 63.3044 37.8177 63.3044C58.4828 63.3044 75.2379 56.5382 75.2379 48.1835V33.0627C75.2379 24.7081 58.4828 17.9419 37.8177 17.9419Z'
          fill='#0AEB88'
        />
        <path
          d='M37.8177 23.2876C58.6508 23.2876 69.8921 30.2371 69.8921 33.0627C69.8921 33.6431 69.1743 34.8344 67.1582 36.2396C61.4153 40.2566 49.8991 42.8378 37.8177 42.8378C25.7363 42.8378 14.22 40.2413 8.47717 36.2396C6.46106 34.8344 5.74321 33.6431 5.74321 33.0627C5.74321 30.2218 16.9845 23.2876 37.8177 23.2876ZM37.8177 17.9419C17.1526 17.9419 0.397461 24.7081 0.397461 33.0627C0.397461 35.812 2.23029 38.3932 5.42246 40.6231C11.8984 45.1441 23.9798 48.1835 37.8329 48.1835C51.6861 48.1835 63.7674 45.1441 70.2434 40.6231C73.4356 38.3932 75.2684 35.812 75.2684 33.0627C75.2684 24.7081 58.5133 17.9419 37.8482 17.9419H37.8177Z'
          fill='#0AEB88'
        />
        <path
          d='M30.1348 44.0591V59.8061'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M58.9561 44.0591V59.8061'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M37.8179 39.8748C50.1926 39.8748 60.2242 36.8796 60.2242 33.1849C60.2242 29.4903 50.1926 26.4951 37.8179 26.4951C25.4433 26.4951 15.4116 29.4903 15.4116 33.1849C15.4116 36.8796 25.4433 39.8748 37.8179 39.8748Z'
          fill='#0AEB88'
        />
        <path
          d='M48.1375 43.2209C28.2513 43.2209 13.3901 36.6533 13.3901 30.773V15.6522C13.3901 9.75659 28.2513 3.18896 48.1375 3.18896C68.0236 3.18896 82.8848 9.75659 82.8848 15.6369V30.7577C82.8848 36.638 68.0236 43.2057 48.1375 43.2057V43.2209Z'
          fill='#002B0C'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379V30.7587C80.212 33.5996 68.9706 40.5338 48.1375 40.5338C27.3044 40.5338 16.063 33.6149 16.063 30.774V15.6532C16.063 12.8123 27.3044 5.87811 48.1375 5.87811M48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379V30.7587C10.7173 39.1134 27.4724 45.8796 48.1375 45.8796C68.8026 45.8796 85.5577 39.1134 85.5577 30.7587V15.6379C85.5577 7.28327 68.8026 0.51709 48.1375 0.51709Z'
          fill='#0AEB88'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379C80.212 16.2183 79.4941 17.4096 77.478 18.8148C71.7351 22.8318 60.2189 25.413 48.1375 25.413C36.0561 25.413 24.5399 22.8165 18.797 18.8148C16.7809 17.4096 16.063 16.2183 16.063 15.6379C16.063 12.797 27.3044 5.86283 48.1375 5.86283ZM48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379C10.7173 18.4024 12.5501 20.9837 15.7423 23.2136C22.2183 27.7346 34.2997 30.774 48.1528 30.774C62.0059 30.774 74.0873 27.7346 80.5633 23.2136C83.7554 20.9837 85.5883 18.4024 85.5883 15.6532C85.573 7.28327 68.8179 0.51709 48.1375 0.51709Z'
          fill='#0AEB88'
        />
        <path
          d='M33.8574 26.6494V42.3964'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M62.6777 26.6494V42.3964'
          stroke='#0AEB88'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M48.1377 22.4499C60.5124 22.4499 70.5441 19.4548 70.5441 15.7601C70.5441 12.0654 60.5124 9.07031 48.1377 9.07031C35.7631 9.07031 25.7314 12.0654 25.7314 15.7601C25.7314 19.4548 35.7631 22.4499 48.1377 22.4499Z'
          fill='#0AEB88'
        />
      </>
    ),
    fast: (
      <>
        <path
          d='M50.7171 77.5372C30.8309 77.5372 15.9697 70.9696 15.9697 65.0893V49.9685C15.9697 44.0881 30.8309 37.5205 50.7171 37.5205C70.6032 37.5205 85.4644 44.0881 85.4644 49.9685V65.0893C85.4644 70.9696 70.6032 77.5372 50.7171 77.5372Z'
          fill='#70411D'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543V65.0751C82.7915 67.916 71.5502 74.8502 50.7171 74.8502C29.884 74.8502 18.6426 67.9007 18.6426 65.0751V49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543V65.0751C13.2969 73.4298 30.052 80.196 50.7171 80.196C71.3822 80.196 88.1373 73.4298 88.1373 65.0751V49.9543C88.1373 41.5997 71.3822 34.8335 50.7171 34.8335Z'
          fill='#FAE187'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543C82.7915 50.5347 82.0737 51.726 80.0576 53.1312C74.3147 57.1482 62.7985 59.7294 50.7171 59.7294C38.6357 59.7294 27.1194 57.1329 21.3766 53.1312C19.3605 51.726 18.6426 50.5347 18.6426 49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543C13.2969 52.7188 15.1297 55.3001 18.3219 57.53C24.7979 62.051 36.8792 65.0904 50.7324 65.0904C64.5855 65.0904 76.6669 62.051 83.1428 57.53C86.335 55.3001 88.1678 52.7188 88.1678 49.9696C88.1678 41.615 71.4128 34.8488 50.7476 34.8488L50.7171 34.8335Z'
          fill='#FAE187'
        />
        <path
          d='M40.1567 60.9658V76.7129'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M68.9785 60.9658V76.7129'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M50.7173 56.7651C63.092 56.7651 73.1236 53.77 73.1236 50.0753C73.1236 46.3806 63.092 43.3855 50.7173 43.3855C38.3427 43.3855 28.311 46.3806 28.311 50.0753C28.311 53.77 38.3427 56.7651 50.7173 56.7651Z'
          fill='#FAE187'
        />
        <path
          d='M37.8176 60.6466C17.9315 60.6466 3.07031 54.079 3.07031 48.1986V33.0778C3.07031 27.1975 17.9315 20.6299 37.8176 20.6299C57.7038 20.6299 72.565 27.1975 72.565 33.0778V48.1986C72.565 54.079 57.7038 60.6466 37.8176 60.6466Z'
          fill='#653916'
        />
        <path
          d='M37.8177 23.2874C58.6508 23.2874 69.8921 30.2369 69.8921 33.0625V48.1833C69.8921 51.0242 58.6508 57.9584 37.8177 57.9584C16.9845 57.9584 5.74321 51.0089 5.74321 48.1833V33.0625C5.74321 30.2216 16.9845 23.2874 37.8177 23.2874ZM37.8177 17.9417C17.1526 17.9417 0.397461 24.7078 0.397461 33.0625V48.1833C0.397461 56.5379 17.1526 63.3041 37.8177 63.3041C58.4828 63.3041 75.2379 56.5379 75.2379 48.1833V33.0625C75.2379 24.7078 58.4828 17.9417 37.8177 17.9417Z'
          fill='#FAE187'
        />
        <path
          d='M37.8177 23.2874C58.6508 23.2874 69.8921 30.2369 69.8921 33.0625C69.8921 33.6429 69.1743 34.8342 67.1582 36.2394C61.4153 40.2563 49.8991 42.8375 37.8177 42.8375C25.7363 42.8375 14.22 40.241 8.47717 36.2394C6.46106 34.8342 5.74321 33.6429 5.74321 33.0625C5.74321 30.2216 16.9845 23.2874 37.8177 23.2874ZM37.8177 17.9417C17.1526 17.9417 0.397461 24.7078 0.397461 33.0625C0.397461 35.8117 2.23029 38.3929 5.42246 40.6229C11.8984 45.1439 23.9798 48.1833 37.8329 48.1833C51.6861 48.1833 63.7674 45.1439 70.2434 40.6229C73.4356 38.3929 75.2684 35.8117 75.2684 33.0625C75.2684 24.7078 58.5133 17.9417 37.8482 17.9417H37.8177Z'
          fill='#FAE187'
        />
        <path
          d='M30.1348 44.0591V59.8061'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M58.9561 44.0591V59.8061'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M37.8179 39.8748C50.1926 39.8748 60.2242 36.8796 60.2242 33.1849C60.2242 29.4903 50.1926 26.4951 37.8179 26.4951C25.4433 26.4951 15.4116 29.4903 15.4116 33.1849C15.4116 36.8796 25.4433 39.8748 37.8179 39.8748Z'
          fill='#FAE187'
        />
        <path
          d='M48.1375 43.2209C28.2513 43.2209 13.3901 36.6533 13.3901 30.773V15.6522C13.3901 9.75659 28.2513 3.18896 48.1375 3.18896C68.0236 3.18896 82.8848 9.75659 82.8848 15.6369V30.7577C82.8848 36.638 68.0236 43.2057 48.1375 43.2057V43.2209Z'
          fill='#5D3210'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379V30.7587C80.212 33.5996 68.9706 40.5338 48.1375 40.5338C27.3044 40.5338 16.063 33.6149 16.063 30.774V15.6532C16.063 12.8123 27.3044 5.87811 48.1375 5.87811M48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379V30.7587C10.7173 39.1134 27.4724 45.8796 48.1375 45.8796C68.8026 45.8796 85.5577 39.1134 85.5577 30.7587V15.6379C85.5577 7.28327 68.8026 0.51709 48.1375 0.51709Z'
          fill='#FAE187'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379C80.212 16.2183 79.4941 17.4096 77.478 18.8148C71.7351 22.8318 60.2189 25.413 48.1375 25.413C36.0561 25.413 24.5399 22.8165 18.797 18.8148C16.7809 17.4096 16.063 16.2183 16.063 15.6379C16.063 12.797 27.3044 5.86283 48.1375 5.86283ZM48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379C10.7173 18.4024 12.5501 20.9837 15.7423 23.2136C22.2183 27.7346 34.2997 30.774 48.1528 30.774C62.0059 30.774 74.0873 27.7346 80.5633 23.2136C83.7554 20.9837 85.5883 18.4024 85.5883 15.6532C85.573 7.28327 68.8179 0.51709 48.1375 0.51709Z'
          fill='#FAE187'
        />
        <path
          d='M33.8574 26.6494V42.3964'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M62.6777 26.6494V42.3964'
          stroke='#FAE187'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M48.1377 22.4499C60.5124 22.4499 70.5441 19.4548 70.5441 15.7601C70.5441 12.0654 60.5124 9.07031 48.1377 9.07031C35.7631 9.07031 25.7314 12.0654 25.7314 15.7601C25.7314 19.4548 35.7631 22.4499 48.1377 22.4499Z'
          fill='#FAE187'
        />
      </>
    ),
    immediate: (
      <>
        <path
          d='M50.7171 77.5372C30.8309 77.5372 15.9697 70.9696 15.9697 65.0893V49.9685C15.9697 44.0881 30.8309 37.5205 50.7171 37.5205C70.6032 37.5205 85.4644 44.0881 85.4644 49.9685V65.0893C85.4644 70.9696 70.6032 77.5372 50.7171 77.5372Z'
          fill='#5D0101'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543V65.0751C82.7915 67.916 71.5502 74.8502 50.7171 74.8502C29.884 74.8502 18.6426 67.9007 18.6426 65.0751V49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543V65.0751C13.2969 73.4298 30.052 80.196 50.7171 80.196C71.3822 80.196 88.1373 73.4298 88.1373 65.0751V49.9543C88.1373 41.5997 71.3822 34.8335 50.7171 34.8335Z'
          fill='#FF281D'
        />
        <path
          d='M50.7171 40.1792C71.5502 40.1792 82.7915 47.1287 82.7915 49.9543C82.7915 50.5347 82.0737 51.726 80.0576 53.1312C74.3147 57.1482 62.7985 59.7294 50.7171 59.7294C38.6357 59.7294 27.1194 57.1329 21.3766 53.1312C19.3605 51.726 18.6426 50.5347 18.6426 49.9543C18.6426 47.1134 29.884 40.1792 50.7171 40.1792ZM50.7171 34.8335C30.052 34.8335 13.2969 41.615 13.2969 49.9543C13.2969 52.7188 15.1297 55.3001 18.3219 57.53C24.7979 62.051 36.8792 65.0904 50.7324 65.0904C64.5855 65.0904 76.6669 62.051 83.1428 57.53C86.335 55.3001 88.1678 52.7188 88.1678 49.9696C88.1678 41.615 71.4128 34.8488 50.7476 34.8488L50.7171 34.8335Z'
          fill='#FF281D'
        />
        <path
          d='M40.1567 60.9658V76.7129'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M68.9785 60.9658V76.7129'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M50.7173 56.7651C63.092 56.7651 73.1236 53.77 73.1236 50.0753C73.1236 46.3806 63.092 43.3855 50.7173 43.3855C38.3427 43.3855 28.311 46.3806 28.311 50.0753C28.311 53.77 38.3427 56.7651 50.7173 56.7651Z'
          fill='#FF281D'
        />
        <path
          d='M37.8176 60.6466C17.9315 60.6466 3.07031 54.079 3.07031 48.1986V33.0778C3.07031 27.1975 17.9315 20.6299 37.8176 20.6299C57.7038 20.6299 72.565 27.1975 72.565 33.0778V48.1986C72.565 54.079 57.7038 60.6466 37.8176 60.6466Z'
          fill='#510101'
        />
        <path
          d='M37.8177 23.2874C58.6508 23.2874 69.8921 30.2369 69.8921 33.0625V48.1833C69.8921 51.0242 58.6508 57.9584 37.8177 57.9584C16.9845 57.9584 5.74321 51.0089 5.74321 48.1833V33.0625C5.74321 30.2216 16.9845 23.2874 37.8177 23.2874ZM37.8177 17.9417C17.1526 17.9417 0.397461 24.7078 0.397461 33.0625V48.1833C0.397461 56.5379 17.1526 63.3041 37.8177 63.3041C58.4828 63.3041 75.2379 56.5379 75.2379 48.1833V33.0625C75.2379 24.7078 58.4828 17.9417 37.8177 17.9417Z'
          fill='#FF281D'
        />
        <path
          d='M37.8177 23.2874C58.6508 23.2874 69.8921 30.2369 69.8921 33.0625C69.8921 33.6429 69.1743 34.8342 67.1582 36.2394C61.4153 40.2563 49.8991 42.8375 37.8177 42.8375C25.7363 42.8375 14.22 40.241 8.47717 36.2394C6.46106 34.8342 5.74321 33.6429 5.74321 33.0625C5.74321 30.2216 16.9845 23.2874 37.8177 23.2874ZM37.8177 17.9417C17.1526 17.9417 0.397461 24.7078 0.397461 33.0625C0.397461 35.8117 2.23029 38.3929 5.42246 40.6229C11.8984 45.1439 23.9798 48.1833 37.8329 48.1833C51.6861 48.1833 63.7674 45.1439 70.2434 40.6229C73.4356 38.3929 75.2684 35.8117 75.2684 33.0625C75.2684 24.7078 58.5133 17.9417 37.8482 17.9417H37.8177Z'
          fill='#FF281D'
        />
        <path
          d='M30.1348 44.0591V59.8061'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M58.9561 44.0591V59.8061'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M37.8179 39.8748C50.1926 39.8748 60.2242 36.8796 60.2242 33.1849C60.2242 29.4903 50.1926 26.4951 37.8179 26.4951C25.4433 26.4951 15.4116 29.4903 15.4116 33.1849C15.4116 36.8796 25.4433 39.8748 37.8179 39.8748Z'
          fill='#FF281D'
        />
        <path
          d='M48.1375 43.2209C28.2513 43.2209 13.3901 36.6533 13.3901 30.773V15.6522C13.3901 9.75659 28.2513 3.18896 48.1375 3.18896C68.0236 3.18896 82.8848 9.75659 82.8848 15.6369V30.7577C82.8848 36.638 68.0236 43.2057 48.1375 43.2057V43.2209Z'
          fill='#460101'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379V30.7587C80.212 33.5996 68.9706 40.5338 48.1375 40.5338C27.3044 40.5338 16.063 33.6149 16.063 30.774V15.6532C16.063 12.8123 27.3044 5.87811 48.1375 5.87811M48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379V30.7587C10.7173 39.1134 27.4724 45.8796 48.1375 45.8796C68.8026 45.8796 85.5577 39.1134 85.5577 30.7587V15.6379C85.5577 7.28327 68.8026 0.51709 48.1375 0.51709Z'
          fill='#FF281D'
        />
        <path
          d='M48.1375 5.86283C68.9706 5.86283 80.212 12.8123 80.212 15.6379C80.212 16.2183 79.4941 17.4096 77.478 18.8148C71.7351 22.8318 60.2189 25.413 48.1375 25.413C36.0561 25.413 24.5399 22.8165 18.797 18.8148C16.7809 17.4096 16.063 16.2183 16.063 15.6379C16.063 12.797 27.3044 5.86283 48.1375 5.86283ZM48.1375 0.51709C27.4724 0.51709 10.7173 7.28327 10.7173 15.6379C10.7173 18.4024 12.5501 20.9837 15.7423 23.2136C22.2183 27.7346 34.2997 30.774 48.1528 30.774C62.0059 30.774 74.0873 27.7346 80.5633 23.2136C83.7554 20.9837 85.5883 18.4024 85.5883 15.6532C85.573 7.28327 68.8179 0.51709 48.1375 0.51709Z'
          fill='#FF281D'
        />
        <path
          d='M33.8574 26.6494V42.3964'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M62.6777 26.6494V42.3964'
          stroke='#FF0C00'
          strokeWidth='5.34574'
          strokeMiterlimit='10'
        />
        <path
          d='M48.1377 22.4499C60.5124 22.4499 70.5441 19.4548 70.5441 15.7601C70.5441 12.0654 60.5124 9.07031 48.1377 9.07031C35.7631 9.07031 25.7314 12.0654 25.7314 15.7601C25.7314 19.4548 35.7631 22.4499 48.1377 22.4499Z'
          fill='#FF281D'
        />
      </>
    ),
  };

  return (
    <svg
      className={`${displayState}-shake`}
      width={width}
      height={height}
      viewBox='0 0 89 81'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {icon[displayState]}
    </svg>
  );
};
