import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../icons/svg';
import { motion } from 'framer-motion';
import { toCurrency } from '../../../../../common/bspot-shared';
import { useBalanceIncrease } from '../../../../../hooks/use-balance-increase/use-balance-increase';
import './styles.scss';
import 'components/organisms/layout/game-header/buttons-sdk/styles.scss';
import { QuickCreditsModalManager } from '../../../buy-credits/modals/quick-credits-modal-manager/quick-credits-modal-manager';
import { CreditsMk2 } from 'icons/svg/credits/credits-mk2';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { KeepPlayingPrompt } from '../../../buy-credits/modals/quick-credits-modal-manager/keep-playing-modal';
import { useProductsAndBalances } from '../../../../../hooks/use-products-and-balances/use-products-and-balances';
import { useMiniModalProduct } from '../use-mini-modal-product/use-mini-modal-product';

/**
 * flag  - enabled : used to switch polling interval to 2 seconds in (useGetCredits hook)
 * localStorage.setItem('lc', 'true');
 */

type CreditsButtonType = {
  setShowGameHeader: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreditsButton = ({ setShowGameHeader }: CreditsButtonType) => {
  const { gameProducts, balances, isLoadingBalances, instantPlayEligible } =
    useProductsAndBalances();
  const { start, rounded } = useBalanceIncrease({
    balance: balances?.credits,
  });
  const [prevValue, setPrevValue] = useState(null);
  const isLoadingCreditsButton =
    isLoadingBalances || balances?.credits_pending > 0;
  const [showModal, setShowModal] = useState(false);
  const [miniModalCredits, setMiniModalCredits] = useState(0);
  const [wager, setWager] = useState(0);
  const [animationState, setAnimationState] = useState<
    'initial' | 'slow' | 'fast' | 'immediate'
  >('initial');
  const [spinsRemaining, setSpinsRemaining] = useState(null);
  const [prevWager, setPrevWager] = useState(null);

  useEffect(() => {
    if (isLoadingCreditsButton) {
      setAnimationState('initial');
      setMiniModalCredits(0);
      return;
    }

    const hasMaybeWagered = balances?.credits < prevValue;
    const hasMaybeAddedCredits = balances?.credits > prevValue;

    if (hasMaybeWagered) {
      const _wager = prevValue - balances?.credits;
      const _spins = Math.floor(balances?.credits / _wager);
      const _credsNeeded = 22 * _wager;

      if (prevWager && prevWager !== _wager) {
        if (animationState === 'fast' || animationState === 'immediate') {
          setPrevWager(_wager);
          setSpinsRemaining(_spins);
          localStorage.removeItem('lc-');
          return;
        }
      }
      setWager(_wager);
      setSpinsRemaining(_spins);

      if (isLoadingCreditsButton || start) {
        setAnimationState('initial');
        setMiniModalCredits(0);
        return;
      }
      if (_spins === null || _spins > 20) {
        localStorage.removeItem('lc');
        setAnimationState('initial');
        setMiniModalCredits(0);
      }
      if (_spins <= 20 && _spins > 10) {
        localStorage.setItem('lc', 'true');
        setAnimationState('slow');
        setMiniModalCredits(0);
      }
      if (_spins <= 10 && _spins > 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'fast' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'fast';
        });
        localStorage.setItem('lc', 'true');
      }
      if (_spins <= 1) {
        setAnimationState((prevState) => {
          if (prevState !== 'immediate' && miniModalCredits === 0) {
            setMiniModalCredits(_credsNeeded);
          }
          return 'immediate';
        });
        localStorage.setItem('lc', 'true');
      }
    }

    if (hasMaybeAddedCredits) {
      localStorage.removeItem('lc');
      setAnimationState('initial');
      setMiniModalCredits(0);
    }

    setPrevValue(balances?.credits);

    if (!prevWager) {
      setPrevWager(wager);
    }

    return () => {};
  }, [
    balances?.credits,
    start,
    isLoadingCreditsButton,
    prevValue,
    spinsRemaining,
    prevWager,
    wager,
    animationState,
    miniModalCredits,
  ]);

  const { miniModalPackage } = useMiniModalProduct({
    miniModalCredits,
    gameProducts,
    cash: balances?.cash,
  });

  const showMiniModal =
    FeatureFlagsService.featureFlags?.enableChargeUpCredits && miniModalPackage;

  useEffect(() => {
    if (showMiniModal) {
      setShowGameHeader(true);
    }
  }, [showMiniModal]);

  return (
    <>
      <div className='position-relative'>
        <button
          id='horseplay-credits-button'
          onClick={() => setShowModal(true)}
          className={`header-button ${
            balances?.shouldConvertCash ? ' immediate' : animationState
          } ${start ? ' cashIncrease' : ''}`}
          style={{
            cursor: 'pointer',
            appearance: 'none',
            border: 'unset',
          }}
        >
          {isLoadingCreditsButton && (
            <>
              {start && (
                <Spinner className='animate' color={'#353535'} size='1rem' />
              )}
              {!start && (
                <Spinner
                  className='animate'
                  color={'var(--primary-color)'}
                  size='1rem'
                />
              )}
            </>
          )}

          {!isLoadingCreditsButton && (
            <>
              {start && (
                <Spinner className='animate' color={'#353535'} size='1rem' />
              )}
              {!start && (
                <CreditsMk2
                  displayState={
                    balances?.shouldConvertCash ? 'immediate' : animationState
                  }
                  width={25}
                  height={26}
                />
              )}
            </>
          )}
          <div className='amount'>
            <span>Credits</span>
            <span
              className={`text-start value ${
                balances?.shouldConvertCash ? 'immediate' : animationState
              }`}
            >
              {start ? (
                <motion.div>{rounded}</motion.div>
              ) : (
                toCurrency(balances?.credits)
              )}
            </span>
          </div>
        </button>
      </div>

      {showModal && (
        <QuickCreditsModalManager
          isLowCreditMode={
            animationState !== 'initial' || balances.credits === 0
          }
          hide={() => setShowModal(false)}
          stage={animationState}
          spinsRemaining={spinsRemaining}
        />
      )}

      {showMiniModal && (
        <KeepPlayingPrompt
          hide={() => setMiniModalCredits(0)}
          stage={animationState}
          showQuickCreditsModal={() => {
            setShowModal(true);
          }}
          instantPlayEligible={instantPlayEligible}
          miniModalPackage={miniModalPackage}
        />
      )}
    </>
  );
};
