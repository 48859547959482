import './styles.scss';

type CreditsProps = {
  displayState?: 'initial' | 'slow' | 'fast' | 'immediate';
  width?: number;
  height?: number;
};
export const Credits = ({
  displayState = 'initial',
  width = 36,
  height = 33,
}: CreditsProps) => {
  const bg = {
    initial: '#353535',
    slow: '#002B0C',
    fast: '#715339',
    immediate: '#390000',
  };

  return (
    <svg
      className={`${displayState}-shake`}
      width={width}
      height={height}
      viewBox='0 0 36 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6295 31.6225C12.5358 31.6225 6.4873 28.9495 6.4873 26.5562V20.402C6.4873 18.0087 12.5358 15.3357 20.6295 15.3357C28.7232 15.3357 34.7717 18.0087 34.7717 20.402V26.5562C34.7717 28.9495 28.7232 31.6225 20.6295 31.6225Z'
        fill={bg[displayState]}
      />
      <path
        d='M20.6295 16.4179C29.1085 16.4179 33.6838 19.2463 33.6838 20.3964V26.5505C33.6838 27.7068 29.1085 30.529 20.6295 30.529C12.1504 30.529 7.57513 27.7006 7.57513 26.5505V20.3964C7.57513 19.2401 12.1504 16.4179 20.6295 16.4179ZM20.6295 14.2422C12.2187 14.2422 5.39941 17.0022 5.39941 20.3964V26.5505C5.39941 29.9509 12.2187 32.7047 20.6295 32.7047C29.0402 32.7047 35.8595 29.9509 35.8595 26.5505V20.3964C35.8595 16.996 29.0402 14.2422 20.6295 14.2422Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M20.6295 16.4179C29.1085 16.4179 33.6838 19.2463 33.6838 20.3964C33.6838 20.6326 33.3916 21.1175 32.571 21.6894C30.2337 23.3243 25.5466 24.3748 20.6295 24.3748C15.7123 24.3748 11.0252 23.318 8.68786 21.6894C7.8673 21.1175 7.57513 20.6326 7.57513 20.3964C7.57513 19.2401 12.1504 16.4179 20.6295 16.4179ZM20.6295 14.2422C12.2187 14.2422 5.39941 17.0022 5.39941 20.3964C5.39941 21.5215 6.14538 22.5721 7.44459 23.4797C10.0803 25.3197 14.9974 26.5568 20.6357 26.5568C26.2739 26.5568 31.191 25.3197 33.8267 23.4797C35.126 22.5721 35.8719 21.5215 35.8719 20.4026C35.8719 17.0022 29.0526 14.2484 20.6419 14.2484L20.6295 14.2422Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M16.3317 24.8782V31.2872'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M28.0618 24.8782V31.2872'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M20.6295 23.1684C25.666 23.1684 29.7489 21.9494 29.7489 20.4457C29.7489 18.9419 25.666 17.7229 20.6295 17.7229C15.593 17.7229 11.5101 18.9419 11.5101 20.4457C11.5101 21.9494 15.593 23.1684 20.6295 23.1684Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M15.3795 24.7482C7.28581 24.7482 1.2373 22.0752 1.2373 19.6819V13.5277C1.2373 11.1345 7.28581 8.46143 15.3795 8.46143C23.4732 8.46143 29.5217 11.1345 29.5217 13.5277V19.6819C29.5217 22.0752 23.4732 24.7482 15.3795 24.7482Z'
        fill={bg[displayState]}
      />
      <path
        d='M15.3795 9.54291C23.8585 9.54291 28.4338 12.3713 28.4338 13.5214V19.6755C28.4338 20.8318 23.8585 23.654 15.3795 23.654C6.90036 23.654 2.32513 20.8256 2.32513 19.6755V13.5214C2.32513 12.3651 6.90036 9.54291 15.3795 9.54291ZM15.3795 7.36719C6.96874 7.36719 0.149414 10.121 0.149414 13.5214V19.6755C0.149414 23.0759 6.96874 25.8297 15.3795 25.8297C23.7902 25.8297 30.6095 23.0759 30.6095 19.6755V13.5214C30.6095 10.121 23.7902 7.36719 15.3795 7.36719Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M15.3795 9.54291C23.8585 9.54291 28.4338 12.3713 28.4338 13.5214C28.4338 13.7576 28.1416 14.2425 27.321 14.8144C24.9837 16.4493 20.2966 17.4998 15.3795 17.4998C10.4623 17.4998 5.7752 16.443 3.43786 14.8144C2.6173 14.2425 2.32513 13.7576 2.32513 13.5214C2.32513 12.3651 6.90036 9.54291 15.3795 9.54291ZM15.3795 7.36719C6.96874 7.36719 0.149414 10.121 0.149414 13.5214C0.149414 14.6403 0.895375 15.6909 2.19459 16.5985C4.83032 18.4385 9.74745 19.6755 15.3857 19.6755C21.0239 19.6755 25.941 18.4385 28.5767 16.5985C29.876 15.6909 30.6219 14.6403 30.6219 13.5214C30.6219 10.121 23.8026 7.36719 15.3919 7.36719H15.3795Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M12.2524 17.9971V24.4061'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M23.9828 17.9971V24.4061'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M15.3795 16.2939C20.416 16.2939 24.4989 15.0749 24.4989 13.5711C24.4989 12.0674 20.416 10.8484 15.3795 10.8484C10.343 10.8484 6.26013 12.0674 6.26013 13.5711C6.26013 15.0749 10.343 16.2939 15.3795 16.2939Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M19.5794 17.6558C11.4858 17.6558 5.43726 14.9828 5.43726 12.5895V6.43533C5.43726 4.03582 11.4858 1.36279 19.5794 1.36279C27.6731 1.36279 33.7216 4.03582 33.7216 6.42911V12.5833C33.7216 14.9766 27.6731 17.6496 19.5794 17.6496V17.6558Z'
        fill={bg[displayState]}
      />
      <path
        d='M19.5794 2.45111C28.0585 2.45111 32.6337 5.27955 32.6337 6.42957V12.5837C32.6337 13.74 28.0585 16.5622 19.5794 16.5622C11.1003 16.5622 6.52509 13.7462 6.52509 12.59V6.43579C6.52509 5.27955 11.1003 2.45733 19.5794 2.45733M19.5794 0.275391C11.1687 0.275391 4.34937 3.02923 4.34937 6.42957V12.5837C4.34937 15.9841 11.1687 18.7379 19.5794 18.7379C27.9901 18.7379 34.8094 15.9841 34.8094 12.5837V6.42957C34.8094 3.02923 27.9901 0.275391 19.5794 0.275391Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M19.5794 2.45111C28.0585 2.45111 32.6337 5.27955 32.6337 6.42957C32.6337 6.66579 32.3416 7.15067 31.521 7.72257C29.1837 9.35747 24.4965 10.408 19.5794 10.408C14.6623 10.408 9.97516 9.35125 7.63781 7.72257C6.81725 7.15067 6.52509 6.66579 6.52509 6.42957C6.52509 5.27333 11.1003 2.45111 19.5794 2.45111ZM19.5794 0.275391C11.1687 0.275391 4.34937 3.02923 4.34937 6.42957C4.34937 7.55473 5.09533 8.60529 6.39454 9.51288C9.03027 11.3529 13.9474 12.59 19.5856 12.59C25.2238 12.59 30.141 11.3529 32.7767 9.51288C34.0759 8.60529 34.8219 7.55473 34.8219 6.43579C34.8157 3.02923 27.9963 0.275391 19.5794 0.275391Z'
        className={`credits-fill-pulse ${displayState}`}
      />
      <path
        d='M13.7672 10.9111V17.3202'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M25.4974 10.9111V17.3202'
        className={`credits-stroke-pulse ${displayState}`}
        strokeWidth='2.17572'
        strokeMiterlimit='10'
      />
      <path
        d='M19.5793 9.20211C24.6158 9.20211 28.6987 7.98309 28.6987 6.47935C28.6987 4.97561 24.6158 3.75659 19.5793 3.75659C14.5428 3.75659 10.46 4.97561 10.46 6.47935C10.46 7.98309 14.5428 9.20211 19.5793 9.20211Z'
        className={`credits-fill-pulse ${displayState}`}
      />
    </svg>
  );
};
