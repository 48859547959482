import { SvgProps } from './svg';

export function LogoHorseplay({
  size,
  width = '44',
  height = '50',
  onClick,
  className = '',
}: SvgProps) {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      style={{ overflow: 'visible' }}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      className={className}
    >
      <path
        d='M23.0919 22.2741L21.751 23.4105L21.1033 20.3536L23.0919 22.2798V22.2741ZM39.6774 17.8422L12.9611 1.27953C7.3474 -2.20347 0.0916194 1.83635 0.0916194 8.43871V11.0069C1.96096 10.6035 4.88714 10.024 7.14285 10.3592C5.81897 9.43304 4.45531 8.70576 3.01211 8.1603C6.42125 7.72279 10.4952 8.67735 14.2964 10.4558C13.9384 9.35918 13.9838 8.4103 14.4384 7.55802C15.3816 10.3649 18.143 11.0467 20.3476 12.9445L26.5067 12.0126C26.5067 12.0126 22.8703 15.7911 22.2226 16.4672L26.4897 21.4673L26.0067 23.5185L31.1375 33.1209C31.5864 33.9618 31.3079 35.0016 30.4954 35.5016L27.4215 37.4107C26.9613 37.6948 26.3761 37.6948 25.9158 37.3993L22.734 35.3538C22.3305 35.0925 22.0749 34.655 22.0465 34.1777L21.9783 32.9618C21.6146 33.0072 21.2396 33.0356 20.8589 33.0356C16.6827 33.0356 13.4725 31.5413 13.052 26.2003L4.44963 32.7004C2.1996 34.6777 0.36435 37.1039 0.0859375 41.5585H0.0916194C0.0916194 48.1665 7.3474 52.2007 12.9554 48.7233L39.6717 32.1606C44.9843 28.8651 44.9843 21.1321 39.6717 17.8366L39.6774 17.8422Z'
        fill='#0AEB88'
      />
      <path
        d='M12.4661 12.2627C15.6025 12.7286 18.5571 13.9104 18.5571 13.9104C18.5571 13.9104 12.8411 12.7911 7.23873 14.0581C1.64207 15.3252 0 16.7968 0 16.7968V13.5979C0 13.5979 6.94327 11.4445 12.4661 12.2627ZM15.7388 29.3083C15.7388 29.3083 14.6252 27.8538 15.0684 25.1549C15.5116 22.456 16.5968 20.1946 16.5968 20.1946C16.5968 20.1946 12.9604 22.7912 13.1877 26.2003C13.324 28.2345 15.7388 29.3027 15.7388 29.3027V29.3083Z'
        fill='#444444'
      />
    </svg>
  );
}
