import { Api } from 'common/api';

export type FeatureFlags = {
  environment: string;
  gameScrollOverlay: boolean;
  disabledWagersStates: string[];
  disabledWagersStartDate: string;
  disabledWagersEndDate: string;
  enableTestimonials: boolean;
  enableLastMonthsWinners: boolean;
  enableBottomNavLoggedOut: boolean;
  enableBottomNavLoggedIn: boolean;

  livechatDeploymentKey: string;
  livechatOrgGuid: string;
  livechatQueue: string;
  smartyEmbeddedKey: string;
  xtremePushKey: string; // maybe this can be deleted. :-/
  gtmKey: string;
  webpushHref: string;
  balancesPool: number;
  striveSocketHref: string;
  depositAmounts: string;
  enableBalancesPoll: boolean;
  enableTournaments: boolean;
  enableIncomeAccess: boolean;
  gpnRgsHost: string;
  salesforceLiveAgent: boolean;
  cloudfareSiteKey: string;
  enableCloudfare: boolean;
  enableBuy250Credits: boolean;
  depositCountRgs: boolean;
  enableSdkComms: boolean;
  allowed250States: string;
  allowed250PlayerIds: string;
  salesforceTestPlayerIds: string;
  enableChargeUpCredits: boolean;
  chargeUpCreditsTimeout: number;
  enableNpp: boolean;
  enableOnboardingPage: boolean;
  activeInstantPlayReserveStates: string[];
  enableInGameTournament: boolean;
};

export type FeatureFlagsServiceType = {
  featureFlags: FeatureFlags | null;
  getFeatureFlags: () => Promise<FeatureFlags>;
};

export const FeatureFlagsService: FeatureFlagsServiceType = {
  featureFlags: null,
  getFeatureFlags: async (): Promise<FeatureFlags> => {
    const response = await fetch(
      '/api/contentful/feature-flags?d=' +
        new Date().getMilliseconds().toString(),
    );

    if (!response.ok) {
      console.log('LIBERTY FETCHING FEATURE FLAGS', JSON.stringify(response));
      throw new Error('Could not get feature flags');
    }
    const data = await response.json();
    FeatureFlagsService.featureFlags = data.ff;
    return data.ff;
  },
};
