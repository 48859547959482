import 'common/theme/main.scss';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text, TextSize } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import {
  changeProfileSettings,
  useCommunicationPreferences,
} from 'services/profile-settings/profile-settings';
import { Checkbox, FormControlLabel } from '@mui/material';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'common/utils';

const Card = styled.div`
  display: flex;
  background-color: rgb(34, 34, 34);
  flex-direction: column;
  gap: 1px;
  padding: 16px 18px;
  border: 3px solid var(--bg-dark-color);
  border-radius: 32px;
  .header {
    border-bottom: solid 1px var(--bg-darkest-color);
    border-top: solid 1px var(--bg-darkest-color);
  }
`;

const TextLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FCLabelStyled = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
`;

export default function CommunicationPreferencePage() {
  const [checked, setChecked] = useState(false);
  const [isSavedEnabled, setIsSaveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: communicationPreferences,
    refetch: refetchCommunicationPreferences,
  } = useCommunicationPreferences();
  const [message, setMessage] = useState();
  const sendNewSettings = () => {
    setIsLoading(true);
    // this should be a mutation
    changeProfileSettings({
      gdpr_config: { email_marketing: checked ? 1 : 0 },
    })
      .then((data) => {
        setMessage(data?.Message || null);
        refetchCommunicationPreferences();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setChecked(
      communicationPreferences?.blocks[0].fields[0].default ? true : false,
    );
  }, [communicationPreferences]);

  const pageTitle = 'Communication preferences - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <div className='col col-12'>
        <SectionTitle title={'Communication Preferences'} />
      </div>
      <div className='col col-12'>
        <Text text={'Opt In / Out Options'} size={TextSize.Body} />
      </div>
      <div className='col col-12  mt-4'>
        <Card>
          {!!message && <BspotAlert type={'success'} message={message} />}
          <div className='d-flex flex-column pb-3 '>
            <Text size={TextSize.H6} bold>
              Email Preferences
            </Text>
            <Text size={TextSize.Hint}>
              Want to keep special offers and big prize promotions coming
              straight to your inbox? Keep this box checked to stay in the loop!
            </Text>
          </div>
          <div className={'header py-2'}>
            <FCLabelStyled
              control={
                <Checkbox
                  sx={{
                    color: 'var(--primary-color)',
                    '&.Mui-checked': {
                      color: 'var(--primary-color)',
                    },
                  }}
                  checked={checked}
                  onClick={() => {
                    setChecked(!checked);
                    setIsSaveEnabled(true);
                  }}
                />
              }
              label={
                <TextLabel className={'ml-0'}>
                  <Text size={TextSize.H6}>Emails</Text>
                </TextLabel>
              }
              labelPlacement='start'
            />
          </div>
          <div className={'align-self-start mt-3'}>
            <Button
              disabled={!isSavedEnabled}
              text={'Save'}
              onClick={() => sendNewSettings()}
              isLoading={isLoading}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
