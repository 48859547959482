import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useBffTournaments } from 'hooks/use-tournaments/use-tournaments';
import { useSearchParams } from 'react-router-dom';

const TournamentsContext = createContext(null);

export const useTournamentsInGame = () => useContext(TournamentsContext);

export const TournamentsContextProvider = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const { data: tournaments, refetch: refetchTournaments } =
    useBffTournaments();

  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    if (gameId && tournaments?.length > 0) {
      tournaments.forEach((tour) => {
        const current = tour;
        current.products.forEach((game) => {
          const pGameId = game.meta.game_id;
          if (gameId.includes(pGameId)) {
            setTournament(current);
            console.log(
              '================================================================ >  GAME IN TOURNAMENT',
            );
          } //console.log('================================================================ >  GAME NOT IN ANY TOURNAMENT')
        });
      });
    }
  }, [gameId, tournaments]);

  return (
    <TournamentsContext.Provider value={{ tournament, refetchTournaments }}>
      {children}
    </TournamentsContext.Provider>
  );
};
