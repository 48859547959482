import React, { Suspense, useEffect, useState } from 'react';
import { useOutlet } from 'react-router-dom';
import { BspotHeader } from 'components/organisms/layout/header';
import { FooterMk2 } from '../footer-mk2/footer-mk2';
import { BottomNav } from '../bottom-nav/bottom-nav';
import { Position, useDrawer } from '../../../../hooks/use-drawer';
import { lazyWithRetry } from '../../../../common/utils';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { Routes as AppRoutes } from 'common/routes';
import { DevBanner } from 'components/atoms/dev-banner/dev-banner';
const MyAccountNav = lazyWithRetry(
  () => import('components/organisms/layout/my-account-nav/my-account-nav'),
);

export const FullScreenLoader = () => {
  return (
    <div
      className='modal-welcome-overlay'
      style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}
    >
      <AppLoader fullHeight={true} />
    </div>
  );
};

export const SuspenseFallback = ({ children }) => {
  return <Suspense fallback={<FullScreenLoader />}>{children}</Suspense>;
};

export function Layout() {
  const outlet = useOutlet();
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [drawerPosition, setDrawerPosition] = useState(Position.Top);
  const {
    isOpen: isNavOpen,
    toggle: toggleNav,
    Drawer: NavDrawer,
  } = useDrawer(drawerPosition);
  const showDevBanner =
    FeatureFlagsService?.featureFlags?.environment !== 'prod' &&
    location.pathname !== AppRoutes.Game.path;
  return (
    <>
      {showDevBanner && <DevBanner />}
      <div className='d-flex flex-column min-vh-100'>
        <BspotHeader
          setDrawerPosition={setDrawerPosition}
          isNavOpen={isNavOpen}
          toggleNav={toggleNav}
          setShowBottomNav={setShowBottomNav}
        />
        <main className='d-flex flex-column flex-grow-1 min-vh-100'>
          {outlet}
        </main>
        {showBottomNav && <BottomNav toggleSideMenu={toggleNav} />}
        <NavDrawer>
          <MyAccountNav toggle={toggleNav} />
        </NavDrawer>
        <FooterMk2 />
      </div>
    </>
  );
}
