import { useAuth } from '../../contexts/authentication';
import { useBalanceAndCredits } from '../use-balance-and-credits/use-balance-and-credits';
import { useEffect, useState } from 'react';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags';
import { useGameProducts } from '../use-get-game-products';

export const useProductsAndBalances = () => {
  const { auth } = useAuth();
  const { data } = useGameProducts();
  const { balanceAndCredits: balances, isLoading: isLoadingBalances } =
    useBalanceAndCredits();
  const [gameProducts, setGameProducts] = useState([]);
  useEffect(() => {
    if (
      balances &&
      data?.gameProducts?.length > 0 &&
      auth?.session?.state &&
      FeatureFlagsService?.featureFlags
    ) {
      const featureFlags = FeatureFlagsService.featureFlags;
      const isPLayerIn250State = featureFlags.allowed250States?.includes(
        auth.session.state,
      );
      const isPLayerInList = featureFlags.allowed250PlayerIds?.includes(
        auth.session.playerId.toString(),
      );
      const isNewUser =
        balances.credits_purchased_count === 0 &&
        balances.credits_pending === 0 &&
        featureFlags.enableBuy250Credits;
      const filteredProducts = data.gameProducts.filter((gameProduct) => {
        if (gameProduct.cost === 250) {
          return isPLayerIn250State || isPLayerInList || isNewUser;
        }
        return true;
      });
      setGameProducts(filteredProducts);
    }
  }, [
    balances,
    data?.gameProducts,
    auth?.session?.state,
    auth?.session?.playerId,
  ]);
  return {
    gameProducts,
    balances,
    instantPlayEligible: data?.instantPlayEligible,
    isLoadingBalances,
  };
};
