import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Text, TextSize } from 'components/atoms/text';
import { ChevronDown } from 'icons/svg/chevron/chevron-down';
import { Badge } from 'components/atoms/button';
import { useLocation } from 'react-router-dom';
import { Routes } from 'common/routes';

interface ListItemActive {
  active?: boolean;
}

const ListItem = styled.a<ListItemActive>`
  color: inherit;
  text-decoration: none;
  display: flex;
  width: 100%;
  gap: 0.5em;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid black;
  background-color: rgb(34, 34, 34);
  &:hover {
    background-color: var(--bg-dark-color);
    * {
      background-color: var(--bg-dark-color);
    }
  }
`;

const ListItemParent = styled(ListItem)`
  :first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  :last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const ListItemChild = styled(ListItem)`
  border-left: ${({ active }) =>
    active ? `5px solid #0aeb88` : `5px solid transparent`};
  padding-right: 0.5em;
`;

const BorderContainer = styled.div`
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;

  align-items: center;
  padding-right: 20px;
  position: relative;
`;

const BorderContainerChild = styled.div`
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 28%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: rgb(34, 34, 34);
`;

export type MenuItemProps = {
  text?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  href?: string;
  path?: string;
  icon?: React.ReactNode;
  withChildren?: any;
  notifications?: boolean;
};

export const SideMenuItem = ({
  text,
  onClick,
  href,
  icon,
  notifications,
  withChildren,
}: MenuItemProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  const ACCBalanceRoutes = [
    Routes.MakeDepositPage.path,
    Routes.MakeDepositSuccessPage.path,
    Routes.MakeDepositErrorPage.path,
    Routes.WithdrawPage.path,
    Routes.WagerHistory.path,
    Routes.WagersPage.path,
  ];
  const ACCSettingsRoutes = [
    Routes.PlayerInfo.path,
    Routes.ChangePassword.path,
    Routes.CommunicationPreferences.path,
    Routes.DocumentCenter.path,
  ];

  useEffect(() => {
    if (withChildren?.length > 0) {
      if (
        text === 'Account Balance' &&
        ACCBalanceRoutes.includes(location.pathname)
      ) {
        setCollapsed(false);
      }
      if (
        text === 'Account Settings' &&
        ACCSettingsRoutes.includes(location.pathname)
      ) {
        setCollapsed(false);
      }
      if (
        text === 'Account Balance' &&
        !ACCBalanceRoutes.includes(location.pathname)
      ) {
        setCollapsed(true);
      }
      if (
        text === 'Account Settings' &&
        !ACCSettingsRoutes.includes(location.pathname)
      ) {
        setCollapsed(true);
      }
    }
  }, [location]);

  const handleActiveState = (it) => {
    if (it.text === 'Account History') {
      return location.pathname === it.path;
    }

    if (it.text === 'Deposit') {
      return (
        location.pathname === it.path ||
        location.pathname === Routes.MakeDepositSuccessPage.path ||
        location.pathname === Routes.MakeDepositErrorPage.path
      );
    }

    return location.pathname === it.path;
  };

  if (withChildren?.length > 0) {
    return (
      <>
        <ListItemParent>
          <IconContainer>{icon}</IconContainer>
          <BorderContainer onClick={() => setCollapsed(!collapsed)}>
            <Text size={TextSize.Body} bold>
              {text}
            </Text>
            <ChevronDown size='20px' fill='var(--secondary-color)' />
          </BorderContainer>
        </ListItemParent>
        {!collapsed && (
          <div className={'d-flex flex-column overflow-hidden'}>
            {withChildren?.map((it) => (
              <ListItemChild
                active={handleActiveState(it)}
                key={it.text}
                href={it.onClick ? undefined : it.href ? it.href : ''}
                target='_blank'
                rel='noreferrer noopener'
                onClick={it.onClick}
              >
                <BorderContainerChild className='py-3'>
                  <Text className={'pl-5'} bold size={TextSize.Body}>
                    &nbsp;{it.text}
                  </Text>
                </BorderContainerChild>
              </ListItemChild>
            ))}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <ListItemParent
        href={onClick ? undefined : href ? href : ''}
        target='_blank'
        rel='noreferrer noopener'
        onClick={onClick}
      >
        <IconContainer>{icon}</IconContainer>

        <BorderContainer>
          <div style={{ position: 'relative' }}>
            {notifications && <Badge isMenu={true}></Badge>}
            <Text bold size={TextSize.Body}>
              {text}
            </Text>
          </div>
        </BorderContainer>
      </ListItemParent>
    </>
  );
};
