import React from 'react';
import { Close } from 'icons/svg';
import { BspotModal } from 'components/atoms/modal/modal';

export type SlotsModalProps = {
  show: boolean;
};
export const SlotsModal = ({ show }: SlotsModalProps) => {
  return (
    <BspotModal show={show} smallLg={true}>
      <div className={'d-flex flex-row justify-content-between'}>
        <h2 className={'my-0'}>{'Hang On'} </h2>
      </div>

      <div
        className={'d-flex col-12 text-center justify-content-center py-3 pb-4'}
      >
        <img
          style={{ borderRadius: '4px' }}
          className={'img-fluid'}
          width={'320px'}
          src={'/js_assets/images/slots.gif'}
        />
      </div>
    </BspotModal>
  );
};
export default SlotsModal;
