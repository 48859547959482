import styled from '@emotion/styled';
import { bp } from '../../../../../common/theme';
import { Routes } from '../../../../../common/routes';
import { useAuth } from '../../../../../contexts/authentication';
import { AccountButtons } from './account-buttons/account-buttons';
import { useNavigate } from '../../../../../hooks/use-navigate';
import { NavLinks } from './nav-links/nav-links';
import { useLocation, useNavigation } from 'react-router-dom';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { LogoHorseplay } from 'icons/svg/logo-horseplay';
import { LogoHorseplayDesktop } from 'icons/svg/logo-horseplayDesktop';
import { useEffect, useState } from 'react';
import { animate } from 'framer-motion';

const StyledHeader = styled.header<{ isLoggedOutLobby: boolean }>`
  @media ${bp.lg} {
    padding: 16px;
    min-height: 90px;
  }
  padding: 8px 12px;
  min-height: 56px;
  width: 100%;
  background: ${(props) =>
    props.isLoggedOutLobby ? 'transparent' : '#000000'};
  position: ${(props) => (props.isLoggedOutLobby ? 'absolute' : 'relative')};
  z-index: 1;
`;

export const HeaderMk2 = ({ isNavOpen, toggleNav }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLobby = location.pathname === Routes.Lobby.path;
  const showNavLink =
    (auth?.session &&
      [
        Routes.Home.path,
        Routes.Tournaments.path,
        Routes.Promotions.path,
        Routes.About.path,
        Routes.TermsOfUse.path,
        Routes.PrivacyPolicy.path,
        Routes.ResponsibleGaming.path,
        Routes.PageNotFound.path,
      ].includes(location.pathname)) ||
    location.pathname.includes(
      Routes.GamesByCategory.path.replace(':category', ''),
    );

  const [show, setShow] = useState(
    !location.pathname.includes(Routes.Home.path),
  );

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setShow(true);
    }, 1000);
    return () => {
      timer && clearTimeout(timer);
      setShow(!location.pathname.includes(Routes.Home.path));
    };
  }, [location.pathname]);

  return (
    <>
      {show && (
        <>
          <StyledHeader
            isLoggedOutLobby={
              !auth?.session && location.pathname === Routes.Lobby.path
            }
            className={['d-flex gap-3', isNavOpen ? 'zIndex' : ''].join(' ')}
          >
            <Logo
              isAuth={auth?.session?.playerId}
              onClick={() => {
                if (isNavOpen) toggleNav();
              }}
            />
            {showNavLink && (
              <NavLinks className='d-none d-lg-flex align-items-end' />
            )}
            {!auth?.session && <AuthLinks />}
            {auth?.session && (
              <AccountButtons
                onCashClick={() => {
                  navigate(Routes.MakeDepositPage);
                  if (isNavOpen) toggleNav();
                }}
                onProfileClick={() => {
                  toggleNav();
                }}
                onChipsClick={() => {
                  navigate(Routes.WagersPage);
                  if (isNavOpen) toggleNav();
                }}
              />
            )}
          </StyledHeader>

          {showNavLink && <NavLinks className='d-grid d-lg-none pb-3' />}
        </>
      )}
    </>
  );
};

const Logo = ({ onClick, isAuth }) => (
  <a
    href={isAuth ? Routes.Home.path : Routes.Lobby.path}
    onClick={onClick}
    id='header-logo'
    aria-label='lobby page link'
    style={{ cursor: 'pointer', margin: 'auto 0' }}
  >
    <>
      <LogoHorseplay className='d-block d-lg-none' />
      <LogoHorseplayDesktop className='d-none d-lg-block' />
    </>
  </a>
);

const StyledAuthLink = styled.a`
  @media ${bp.lg} {
    font-size: 14px;
  }
  font-size: 12px;
  cursor: pointer;
`;

const AuthLinks = () => (
  <nav
    className='d-flex align-items-center text-nowrap'
    style={{
      marginLeft: 'auto',
      gap: '8px',
      fontSize: '12px',
      fontWeight: 700,
    }}
  >
    <StyledAuthLink
      className={'rounded-pill w-100 text-decoration-none'}
      style={{
        color: '#ffffff',
        padding: '4px 12px',
      }}
      href={Routes.Login.path}
    >
      <span style={{ minHeight: '20px', display: 'block' }}>Login</span>
    </StyledAuthLink>
    <StyledAuthLink
      className={'rounded-pill w-100 text-decoration-none'}
      style={{
        background: '#353535',
        padding: '4px 12px',
        color: 'var(--primary-color)',
      }}
      href={Routes.CreateAccount.path}
    >
      <span style={{ minHeight: '20px', display: 'block' }}>Sign up</span>
    </StyledAuthLink>
  </nav>
);
