import React from 'react';
import { Button } from 'components/atoms/button';
import { GameGroup, GameInfo } from 'hooks/use-games-list';
import './styles.scss';
import { GameByCategorySkeleton } from './games-by-category-skeleton';
import { GameTileMk2 } from 'components/organisms/game-list/game-tile/game-tile';

type GameCategoryProps = {
  category: GameGroup;
  displayCta: boolean;
  displayAll: boolean;
  isSpotlight?: boolean;
};

export const GameCategory = ({
  category,
  displayCta,
  displayAll,
}: GameCategoryProps) => {
  const gamesToDisplay = displayAll
    ? category?.games
    : category?.games?.slice(0, category?.frontPageQuantity);

  if (!category?.games) return <GameByCategorySkeleton />;

  return (
    <div className={'bspot-games-category-grid'}>
      <div className={'grid-container'}>
        <div className={'first-column'}>
          <div className={'header'}>
            <h3
              className={'fw-bold d-flex flex-row justify-content-between my-0'}
            >
              <div className={'align-self-start'}>{category.name}</div>
              {displayCta && (
                <Button
                  isALink={true}
                  id={'btn see all ' + category.slug}
                  text={'See All'}
                  isSmall={true}
                  href={'/en/games/' + category.slug}
                />
              )}
            </h3>
          </div>
          <div className={'sub-row'}>
            {gamesToDisplay?.map((game, index) => (
              <div
                key={index + game.game_id + '_' + game.icon_url}
                className={'sub-column'}
              >
                <GameTileMk2 gameInfo={game} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
