export const DevBanner = () => {
  return (
    <div
      className={
        'd-flex flex-column text-center justify-content-center justify-items-center fw-bold text-white px-3 py-1 py-lg-2'
      }
      style={{
        backgroundColor: `#f48245`,
        fontSize: window?.innerWidth < 620 ? '10px' : '14px',
        lineHeight: '1',
      }}
    >
      Heads up: This is a test environment for game developers. None of the
      money wagered and won here is real.
    </div>
  );
};
