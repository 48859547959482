import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { AuthContextProvider } from '../contexts/authentication';
import { Trackers, LiveChat } from '../components';
import { ThemeProvider, createTheme, css } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Modal from 'react-modal';
import { KYCContextProvider } from '../contexts/kyc/kyc-context';
import { ErrorBoundary } from '../components/molecules/error-boundary/error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BtagContextProvider } from '../contexts/btag/btag-context';
import { ModalTermsManager } from '../components';
import { SessionProvider } from 'next-auth/react';
import { NextAuthContextProvider } from '../contexts/next-auth/next-auth';
import { useEffect } from 'react';
import { Gdpr } from 'components/molecules/gdpr/gdpr';
import { ToasterProvider } from 'contexts/toaster-provider/toaster-provider';

// Undo global css applied by CPIG
const globalStyles = css`
  body {
    margin: 0;
    color: var(--text-light-color);
    background-color: var(--bg-darkest-color);
  }

  header {
    border-image: none;
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

Modal.setAppElement('#root');
export const AppWrapper = ({ children, isNextAuthEnabled }) => {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider
            theme={createTheme({
              palette: {
                mode: 'dark',
                primary: { main: '#0aeb88' },
              },
              typography: {
                fontFamily: ['Poppins'].join(','),
              },
              components: {
                MuiCssBaseline: {
                  styleOverrides: `
                  input:-webkit-autofill,
                  input:-webkit-autofill:hover, 
                  input:-webkit-autofill:focus, 
                  input:-webkit-autofill:active  {
                    -webkit-box-shadow: unset !important;
                  }
                `,
                },
              },
            })}
          >
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <BrowserRouter>
              <AuthHandler isNextAuthEnabled={isNextAuthEnabled}>
                <BtagContextProvider>
                  <ToasterProvider>
                    <KYCContextProvider>
                      <LogRouteChanges />
                      <HelmetProvider>{children}</HelmetProvider>
                      <Trackers />
                      <LiveChat />
                      <ModalTermsManager />
                      <Gdpr />
                    </KYCContextProvider>
                  </ToasterProvider>
                </BtagContextProvider>
              </AuthHandler>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

const AuthHandler = ({ children, isNextAuthEnabled }) => {
  return isNextAuthEnabled ? (
    <SessionProvider basePath={`/api/auth`}>
      <AuthContextProvider>
        <NextAuthContextProvider>{children}</NextAuthContextProvider>
      </AuthContextProvider>
    </SessionProvider>
  ) : (
    <AuthContextProvider>{children}</AuthContextProvider>
  );
};

function LogRouteChanges() {
  const location = useLocation();

  useEffect(() => {
    console.log('Current Path:', location.pathname);
  }, [location]);

  return null; // This component doesn't render anything
}
