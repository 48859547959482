import React from 'react';
import { SvgProps } from 'icons/svg/svg';

export function BSafe({ color }: SvgProps) {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='44' height='44' rx='22' fill='none' />
      <path
        d='M11.5 13.775V21.412C11.5294 23.9354 12.3336 26.3889 13.8037 28.4402C15.2738 30.4914 17.3388 32.0413 19.719 32.88L20.84 33.294C21.5889 33.569 22.4111 33.569 23.16 33.294L24.281 32.88C26.6612 32.0413 28.7262 30.4914 30.1963 28.4402C31.6664 26.3889 32.4706 23.9354 32.5 21.412V13.775C32.499 13.4749 32.41 13.1818 32.2439 12.9318C32.0779 12.6819 31.8422 12.4862 31.566 12.369C28.5366 11.1051 25.2823 10.4693 22 10.5C18.7177 10.4693 15.4634 11.1051 12.434 12.369C12.1578 12.4862 11.9221 12.6819 11.7561 12.9318C11.59 13.1818 11.501 13.4749 11.5 13.775V13.775Z'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.361 25.229C22.3143 25.2777 22.2583 25.3165 22.1963 25.343C22.1342 25.3694 22.0675 25.3831 22 25.3831C21.9325 25.3831 21.8658 25.3694 21.8038 25.343C21.7417 25.3165 21.6857 25.2777 21.639 25.229L17.861 21.288C17.4969 20.9248 17.2565 20.4561 17.1738 19.9485C17.091 19.4409 17.1702 18.9201 17.4 18.46V18.46C17.5736 18.113 17.8272 17.8122 18.1398 17.5824C18.4524 17.3526 18.8152 17.2004 19.1982 17.1383C19.5812 17.0761 19.9735 17.1059 20.3428 17.2252C20.712 17.3444 21.0476 17.5497 21.322 17.824L22 18.5L22.676 17.824C22.9504 17.5492 23.2861 17.3436 23.6556 17.2242C24.025 17.1047 24.4176 17.0748 24.8009 17.1369C25.1842 17.199 25.5472 17.3514 25.8601 17.5815C26.1729 17.8115 26.4265 18.1127 26.6 18.46V18.46C26.8301 18.9199 26.9096 19.4404 26.8274 19.948C26.7452 20.4555 26.5054 20.9243 26.142 21.288L22.361 25.229Z'
        stroke={color || '#0AEB88'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
