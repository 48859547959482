import { NavItemProps } from 'components/organisms/layout/my-account-nav/nav-item';
import {
  Promotion,
  BSafe,
  HowItWorks,
  PlayerInfo,
  Tournament,
  MakeADeposit,
} from 'icons/svg';
import { Routes } from 'common/routes';
import { useNavigate } from 'hooks/use-navigate';
import { SideMenuItem } from 'components/organisms/layout/side-menu/side-menu-item';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const SideMenu = () => {
  const navigate = useNavigate();

  const items: NavItemProps[] = [
    {
      text: 'Account Balance',
      icon: <MakeADeposit color='#ffffff' />,
      withChildren: [
        {
          text: 'Deposit',
          path: Routes.MakeDepositPage.path,
          onClick: () => {
            navigate(Routes.MakeDepositPage);
          },
        },
        {
          text: 'Withdraw',
          path: Routes.WithdrawPage.path,
          onClick: () => {
            navigate(Routes.WithdrawPage);
          },
        },
        {
          text: 'Buy Credits',
          path: Routes.WagersPage.path,
          onClick: () => {
            navigate(Routes.WagersPage);
          },
        },
        {
          text: 'Account History',
          path: Routes.WagerHistory.path,
          onClick: () => {
            navigate(Routes.WagerHistory.path);
          },
        },
      ],
    },
    {
      text: 'Promotions',
      icon: <Promotion width={44} fill='white' />,
      onClick: () => {
        navigate(Routes.Promotions);
      },
    },

    {
      text: 'Account Settings',
      icon: <PlayerInfo color={'#ffffff'} />,
      withChildren: [
        {
          text: 'Personal Details',
          icon: <PlayerInfo />,
          path: Routes.PlayerInfo.path,
          onClick: () => {
            navigate(Routes.PlayerInfo);
          },
        },
        {
          text: 'Communication Preferences',
          icon: <PlayerInfo />,
          path: Routes.CommunicationPreferences.path,
          onClick: () => {
            navigate(Routes.CommunicationPreferences);
          },
        },
        {
          text: 'Documents Center',
          icon: <PlayerInfo />,
          path: Routes.DocumentCenter.path,
          onClick: () => navigate(Routes.DocumentCenter),
        },

        {
          text: 'Change Password',
          icon: <PlayerInfo />,
          path: Routes.ChangePassword.path,
          onClick: () => {
            navigate(Routes.ChangePassword);
          },
        },
      ],
    },
    {
      text: 'Responsible Gaming',
      icon: <BSafe color={'#ffffff'} />,
      onClick: () => {
        navigate(Routes.ResponsibleGamingSettings);
      },
    },
    {
      text: 'Tournaments',
      icon: <Tournament width={44} fill='white' />,
      onClick: () => {
        navigate(Routes.Tournaments);
      },
      display: FeatureFlagsService?.featureFlags?.enableTournaments,
    },
    {
      text: 'Help Center',
      icon: <HowItWorks color={'#ffffff'} />,
      href: 'https://support.bspot.com/hc/en-us',
    },
  ];

  return (
    <>
      {items?.map((item, index) => {
        if (item?.display !== undefined && item?.display === false) return;
        return <SideMenuItem key={`${item.text}-${index}`} {...item} />;
      })}
    </>
  );
};
