import { useAuth } from 'contexts/authentication';
import { useQuery } from '@tanstack/react-query';
import { getFreePlayAwardedContent } from '../../services/free-play-awarded-content/free-play-awarded-content';

export const useFreePlayAwardedContent = () => {
  const { auth } = useAuth();
  return useQuery({
    queryKey: ['free-play-awarded-' + auth.session?.playerId],
    queryFn: async () => await getFreePlayAwardedContent(),
    keepPreviousData: false,
    enabled: !!auth.session?.playerId,
  });
};
