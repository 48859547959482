import { OutlinedButton } from 'components/atoms/button';
import { useState } from 'react';
import { playAll } from 'services/play-all/play-all';
import SlotsModal from 'components/organisms/play-all/modals/slots';
import PlayModal from 'components/organisms/play-all/modals/play';
import WonModal from 'components/organisms/play-all/modals/won';
import LostModal from 'components/organisms/play-all/modals/lost';
import { useBalanceAndCredits } from 'hooks/use-balance-and-credits/use-balance-and-credits';
import { useGetCredits } from '../../../hooks/use-credits/use-credits';
import { useAuth } from 'contexts/authentication';

export const PlayAllButton = () => {
  const { auth } = useAuth();
  const { balanceAndCredits, isLoading } = useBalanceAndCredits();
  const { refetch } = useGetCredits();
  const [playing, setPlaying] = useState(false);
  const [slots, setSlots] = useState(false);
  const [amount, setAmount] = useState(null);

  const confirmPlayAll = () => {
    setSlots(true);
    playAll(auth.session)
      .then((winnings) => {
        refetch().then(() => {
          setSlots(false);
          setAmount(winnings);
        });
      })
      .catch((err) => {
        console.log(err);
        setSlots(false);
      });
  };

  return (
    <>
      {balanceAndCredits?.credits !== 0 && (
        <OutlinedButton
          text={'Play All'}
          onClick={() => setPlaying(true)}
          disabled={isLoading}
        />
      )}

      {playing && (
        <PlayModal
          show={playing}
          onClose={() => setPlaying(false)}
          onPlayAll={confirmPlayAll}
        />
      )}

      {slots && <SlotsModal show={slots} />}

      {amount > 0 && (
        <WonModal onClose={() => setAmount(null)} amount={amount} show />
      )}

      {amount === 0 && (
        <LostModal onClose={() => setAmount(null)} amount={amount} show />
      )}
    </>
  );
};

export default PlayAllButton;
