import React, { useState } from 'react';
import SkeletonTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/skeleton';
import './styles.scss';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

const Scroll = (props) => {
  return (
    <div id='scroll' style={{ overflowY: 'scroll', height: '350px' }}>
      {props.children}
    </div>
  );
};

type WagerDetailsTableProps = {
  data: any;
  onViewRaceId: any;
};
export const WagerDetailsTable = ({
  data,
  onViewRaceId,
}: WagerDetailsTableProps) => {
  const viewRaceId = (raceId) => {
    onViewRaceId(raceId);
  };
  return (
    <Scroll className={'py-2'} style={{ height: '350px', overflowY: 'hidden' }}>
      {data ? (
        <table>
          <thead className='table-header'>
            <tr>
              <th className={'text-start'} style={{ width: '290px' }}>
                DETAIL
              </th>
              <th>WAGER</th>
              <th>WON/CR</th>
            </tr>
          </thead>
          <tbody className={'table-wrap'}>
            {data.data?.map((wager, i) => (
              <tr className='table-row' key={i}>
                <td className='detail' style={{ width: '290px' }}>
                  <span
                    data-testid={`race-name-${wager.raceId}`}
                    className='race-name'
                    onClick={() => {
                      if (FeatureFlagsService.featureFlags?.enableNpp) {
                        viewRaceId(wager.wagerId);
                      } else {
                        viewRaceId(wager.raceId);
                      }
                    }}
                  >
                    {wager.raceName || 'Race name'}
                  </span>
                  <br />
                  <span>
                    Runner(s): <strong>{wager.runners}</strong>
                  </span>
                  <br />
                  <span>
                    Serial <strong>#{wager.tsn}</strong>{' '}
                  </span>
                </td>
                <td>{wager.wagerAmount}</td>
                <td className='winnings'>{wager.winnings}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <SkeletonTable />
      )}
    </Scroll>
  );
};

export default WagerDetailsTable;
