import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'contexts/authentication';
import {
  addCreditsToCartAnalyticsEvent,
  creditPurchaseAnalyticsEvent,
} from 'common/utils';
import {
  buyCredits,
  BuyCreditsPayload,
} from 'services/buy-credits/buy-credits';
import './styles.scss';
import '../../../layout/game-header/buttons-sdk/styles.scss';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';
import { Lightning } from 'icons/svg/lightning';
import NoLocationAvailableModal from '../no-location-available.modal';
import LocationNotAllowedModal from '../location-not-allowed.modal';
import WagerDetails from '../wager-details/wager-details';
import BonusModal from '../bonus-modal';
import WarningModal from '../warning-modal';
import { Orientation } from 'hooks/use-media-query';

type KeepPlayingModalProps = {
  hide: () => void;
  stage: 'initial' | 'slow' | 'fast' | 'immediate';
  showQuickCreditsModal(): void;
  miniModalPackage: any;
  instantPlayEligible: any;
};

export const KeepPlayingPrompt = ({
  hide,
  stage,
  showQuickCreditsModal,
  miniModalPackage,
  instantPlayEligible,
}: KeepPlayingModalProps) => {
  const timeOutRef = useRef(null);
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [modalState, setModalState] = useState<
    | 'WarningModal'
    | 'WagerDetails'
    | 'BonusModal'
    | 'LocationNotAllowed'
    | 'LocationNotAvaliable'
  >();

  useEffect(() => {
    const chargeUpTimeout =
      FeatureFlagsService.featureFlags.chargeUpCreditsTimeout;
    timeOutRef.current = setTimeout(() => {
      setClose(true);
    }, chargeUpTimeout);
    return () => {
      clearTimeout(timeOutRef.current);
    };
  }, [setClose]);

  const handleAnimationEnd = () => {
    if (close) {
      hide();
    }
  };

  const handleShowQuickCredits = () => {
    clearTimeout(timeOutRef.current);
    showQuickCreditsModal();
  };

  const handleClose = () => {
    clearTimeout(timeOutRef.current);
    setClose(true);
  };

  const onBuy = async () => {
    clearTimeout(timeOutRef.current);
    setClose(true);
    setIsLoading(true);
    addCreditsToCartAnalyticsEvent({
      product: miniModalPackage,
      playerId: auth.session.playerId,
    });
    try {
      let payload: BuyCreditsPayload = {
        game_product_id: miniModalPackage.id,
      };
      const res = await buyCredits(payload, auth.session);
      if (res?.status === 403) {
        setModalState('LocationNotAllowed');
        setIsLoading(false);
        return;
      }
      // await refetch();
      const d = new Date().toISOString();
      creditPurchaseAnalyticsEvent({
        selectedPackage: miniModalPackage,
        playerId: auth.session.playerId,
        transactionId: res?.id || auth?.session?.playerId.toString() + '-' + d,
        isQuickCredits: 'true',
        quickCreditsType: 'keepPlaying',
      });
      setIsLoading(false);
      setClose(true);
      return res;
    } catch (error) {
      if (error?.ok === false && error?.data?.latitude === 0) {
        setModalState('LocationNotAvaliable');
        setIsLoading(false);
      }
      setClose(true);
      return error.message;
    }
  };

  return (
    <>
      <div
        onAnimationEnd={handleAnimationEnd}
        className={[
          'quick-Modal d-flex flex-row align-content-end justify-content-end',
          stage,
          close ? 'on-dismiss' : '',
          Orientation.Landscape ? 'mobile-landscape' : '',
        ].join(' ')}
      >
        <div className={'d-flex flex-column gap-1 pl-5 quick-modal-inner'}>
          <div className='d-flex flex-row align-items-center justify-content-between gap-1 gap-lg-3'>
            <span className={'lc-icon-cta d-flex flex-row align-items-center'}>
              {stage === 'fast' && (
                <img
                  className={[
                    'quick-credits-button',
                    Orientation.Landscape ? 'mobile-landscape' : '',
                  ].join(' ')}
                  src='/js_assets/images/low-credit-2.webp'
                  alt='low-credit-2'
                  onClick={handleShowQuickCredits}
                />
              )}
              {stage === 'immediate' && (
                <img
                  className={[
                    'quick-credits-button',
                    Orientation.Landscape ? 'mobile-landscape' : '',
                  ].join(' ')}
                  src='/js_assets/images/low-credit-3.webp'
                  alt='low-credit-3'
                  onClick={handleShowQuickCredits}
                />
              )}
            </span>
            <div
              className={[
                'cta-mini-modal ',
                stage,
                Orientation.Landscape ? 'mobile-landscape' : '',
              ].join(' ')}
              style={{
                pointerEvents: isLoading ? 'none' : 'all',
              }}
            >
              <div className={`lc-icon-holder `}>
                <Lightning
                  fill={stage === 'immediate' ? '#ffffff' : '#000000'}
                />
              </div>
              <div
                className={`amount ${stage} d-flex justify-content-center gap-2`}
                onClick={onBuy}
              >
                <div className={`content__container__list ${stage}`}>
                  <span className={'content__container__list__item'}>
                    {stage === 'fast' && 'Charge Up Soon'}
                    {stage === 'immediate' && 'Charge Up Now'}
                  </span>
                  <span className={'content__container__list__item'}>
                    {'Buy $' + miniModalPackage?.cost + ' Credits!'}
                  </span>
                </div>
              </div>
            </div>
            <div className='d-flex flex-row gap-2 align-items-center'>
              <div
                className={`wager-details-link`}
                onClick={() => {
                  clearTimeout(timeOutRef.current);
                  setModalState('WarningModal');
                }}
              >
                See wager details.
              </div>
            </div>

            <div
              className='d-flex flex-row align-items-center justify-content-center lc-icon-cta-close'
              onClick={handleClose}
            >
              <img
                src='/js_assets/icons/close-stage.svg'
                alt='close'
                className={[
                  'close-button',
                  Orientation.Landscape ? 'mobile-landscape' : '',
                ].join(' ')}
              />
            </div>
          </div>
          <div
            className='d-flex flex-row align-items-center justify-content-center'
            style={{ fontSize: '10px', fontWeight: '600' }}
          >
            <span className={`bottom-copy ${stage}`}>
              {' '}
              Deduct{' '}
              <span style={{ fontSize: '10px', fontWeight: '900' }}>
                ${miniModalPackage?.cost}
              </span>{' '}
              from my Cash Account. All horse race wagers are final.&nbsp;
            </span>
          </div>
        </div>
      </div>
      {modalState === 'WarningModal' && (
        <WarningModal
          show
          product={miniModalPackage}
          onClickPlayLater={() => setModalState('WagerDetails')}
          onClose={() => {
            setModalState(null);
          }}
        />
      )}
      {modalState === 'WagerDetails' && (
        <WagerDetails
          show
          instantPlayEligible={instantPlayEligible}
          product={miniModalPackage}
          onClickBadge={() => setModalState('BonusModal')}
          onClose={() => setModalState(null)}
          onConfirm={onBuy}
        />
      )}
      {modalState === 'BonusModal' && (
        <BonusModal
          show={true}
          product={miniModalPackage}
          onClose={() => setModalState('WagerDetails')}
        />
      )}
      {modalState === 'LocationNotAvaliable' && (
        <NoLocationAvailableModal show onClose={() => setModalState(null)} />
      )}
      {modalState === 'LocationNotAllowed' && (
        <LocationNotAllowedModal show onClose={() => setModalState(null)} />
      )}
    </>
  );
};
