import './styles.scss';
import { Button } from 'components/atoms/button';
import { GameTileMk2 } from 'components/organisms/game-list/game-tile/game-tile';
export const GameByCategorySkeleton = () => {
  return (
    <>
      <div className={'pb-1 bspot-games-category-grid'}>
        <div className={'grid-container '}>
          <div className={'first-column'}>
            <div className='header'>
              <h3
                className={
                  'fw-bold d-flex flex-row justify-content-between pb-0 my-0'
                }
              >
                <div className={'align-self-start'}>
                  <img
                    src={'/js_assets/images/banner-placeholder.webp'}
                    style={{
                      width: '140px',
                      height: '16px',
                      borderRadius: '4px',
                      backgroundColor: '#121212',
                    }}
                  />
                </div>
                <div className={'align-self-end'}>
                  <Button text={'See All'} isSmall={true} disabled />
                </div>
              </h3>
            </div>
            <div className={'sub-row'}>
              {[1, 2, 3, 4].map((game) => (
                <div key={game} className={'sub-column'}>
                  <GameTileMk2
                    gameInfo={{
                      available: true,
                      icon_url: '',
                      id: 0,
                      game_id: '',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
