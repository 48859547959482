import './index.scss';
import { useState } from 'react';
import { CrossCircle, Info } from 'icons/svg';
import { Collapse } from '@mui/material';
import { Button, ButtonColor } from '../../atoms/button';
import { TournamentType } from 'services/tournaments/tournaments';
import { useNewLeaderBoard } from 'hooks/use-leaderboard';
import { useAuth } from 'contexts/authentication';
import {
  Cta,
  Description,
  Timer,
  FlipBox,
  GameSelect,
  Leaderboard,
} from './tournament-components';
import { UseOptInTournament } from 'hooks/use-opt-in-tournament/use-opt-in-tournament';

type TournamentProps = {
  tournament: TournamentType;
  refetchTournaments?: () => void;
};

export const TournamentCard = ({
  tournament,
  refetchTournaments,
}: TournamentProps) => {
  const { auth } = useAuth();
  const { data: items, refetch: refetchLeaderboards } = useNewLeaderBoard(
    tournament?.id,
    tournament?.rewards,
    auth?.session?.playerId?.toString(),
    tournament?.isOptedIn,
    auth?.member?.name,
  );

  const [showInfo, setShowInfo] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(true);
  const { clickOptIn, loading } = UseOptInTournament({
    tournament,
    refetchTournaments,
    refetchLeaderboards,
  });

  const onClickOptIn = () => {
    clickOptIn(tournament);
  };

  return (
    <div className='tournament-container'>
      <img
        className='logo-container'
        alt='tournament game'
        width={100}
        height={100}
        src={
          tournament.products.length > 1
            ? tournament.logoImage
            : tournament.products[0]?.meta?.image || tournament.logoImage
        }
      />
      <div
        className='card-top'
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%), url(${
            tournament.products.length > 1
              ? tournament.backgroundImage
              : tournament.products[0]?.meta?.background ||
                tournament.backgroundImage
          })`,
        }}
      >
        <div className='text-container'>
          <span
            className='subtitle-text'
            data-testid={`${tournament?.id}-subtitle`}
          >
            Top {tournament?.subtitle}
          </span>
          <span className='title-text' data-testid={`${tournament?.id}-title`}>
            {tournament?.title}
          </span>
        </div>
        <Timer
          isActive={tournament?.status === 'Active'}
          startTime={new Date(tournament?.startTime)}
          endTime={new Date(tournament?.endTime)}
          contestId={tournament?.id}
          id={tournament?.id}
          fetchTournament={refetchTournaments}
          removeTournament={refetchTournaments}
        />

        {tournament?.isOptedIn &&
        tournament?.status === 'Active' &&
        tournament?.products.length > 1 ? (
          <Button
            color={showLeaderboard ? ButtonColor.Primary : ButtonColor.White}
            text={showLeaderboard ? 'Show Games' : 'Show Leaderboard'}
            onClick={() => setShowLeaderboard(!showLeaderboard)}
          />
        ) : (
          <Cta
            isOptedIn={tournament?.isOptedIn}
            meta={
              tournament?.products.length > 1
                ? { ...tournament?.meta, ...tournament?.products?.[0].meta }
                : tournament?.meta
            }
            ctaLoading={loading}
            playerId={auth.session.playerId.toString()}
            isActive={tournament?.status === 'Active'}
            onClickOptIn={onClickOptIn}
          />
        )}
        <button
          className='info-button'
          aria-label='more information'
          data-testid={`${tournament?.id}-info-toggle`}
          data-toggle='collapse'
          onClick={() => setShowInfo(!showInfo)}
        >
          {showInfo ? (
            <CrossCircle size='24px' color='white' />
          ) : (
            <Info size='24px' color='white' />
          )}
        </button>
      </div>

      <Collapse in={showInfo}>
        <Description
          description={tournament?.description}
          id={tournament?.id}
          tnc={tournament?.tnc}
          handleClose={() => setShowInfo(!showInfo)}
        />
      </Collapse>
      <FlipBox
        flip={!showLeaderboard}
        front={
          <Leaderboard contestId={tournament?.id} leaderboardItems={items} />
        }
        back={<GameSelect products={tournament?.products} />}
      />
    </div>
  );
};
