export enum RouteNames {
  Lobby = 'Lobby',
  Tournaments = 'Tournaments',
  CashHistory = 'CashHistory',
  Balances = 'Balances',
  WagerHistory = 'WagerHistory',
  CreateAccount = 'CreateAccount',
  CreateAccountSuccess = 'CreateAccountSuccess',
  CreateAccountFail = 'CreateAccountFail',
  AccountOnBoarding = 'AccountOnBoarding',
  About = 'About',
  Login = 'Login',
  HowItWorks = 'HowItWorks',
  Error = 'Error',
  PageNotFound = 'PageNotFound',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  ResponsibleGamingSettings = 'ResponsibleGamingSettings',
  ResponsibleGaming = 'ResponsibleGaming',
  SessionTimeout = 'SessionTimeout',
  ChangePassword = 'ChangePassword',
  ResetPassword = 'ResetPassword',
  ForgotPassword = 'ForgotPassword',
  DocumentCenter = 'DocumentCenter',
  PlayerInfo = 'PlayerInfo',
  CommunicationPreferences = 'CommunicationPreferences',
  WagersPage = 'WagersPage',
  Game = 'Game',
  GamesByCategory = 'GamesByCategory',
  DynamicPage = 'DynamicPage',
  Affiliates = 'Affiliates',
  MakeDepositPage = 'MakeDepositPage',
  MakeDepositSuccessPage = 'MakeDepositSuccessPage',
  MakeDepositErrorPage = 'MakeDepositErrorPage',

  MakeDepositSuccessIframePage = 'MakeDepositSuccessIframePage',
  MakeDepositErrorIframePage = 'MakeDepositErrorIframePage',
  MakeDepositPendingIframePage = 'MakeDepositPendingIframePage',

  WithdrawPage = 'WithdrawPage',
  Promotions = 'Promotions',
  Home = 'Home',
  Horseplay = 'Horseplay',
}
export type Route = {
  path: string;
  paths?: string[];
  component: any;
  props?: Record<string, unknown>;
  name: string;
  private?: boolean;
  children?: Record<string, Route>;
};
export const Routes: Record<RouteNames, Route> = {
  Lobby: {
    path: '/',
    component: 'lobby-page',
    name: 'Lobby',
    props: { gamesList: window['gamesList'] },
  },
  Home: {
    path: '/en/home',
    component: 'home-page',
    name: 'Home',
    props: { gamesList: window['gamesList'] },
  },
  Horseplay: {
    path: '/horseplay',
    component: 'horseplay-page',
    name: 'Horseplay',
  },
  Login: {
    path: '/en/login',
    component: 'login',
    name: 'Login',
  },
  CreateAccount: {
    path: '/en/registration/register',
    component: `create-account`,
    name: 'Registration',
  },
  CreateAccountSuccess: {
    path: '/en/registration/success',
    component: `create-account-success`,
    name: 'Registration Success',
  },
  CreateAccountFail: {
    path: '/en/registration/unsuccessful',
    component: `create-account-fail`,
    name: 'Registration Fail',
  },
  AccountOnBoarding: {
    path: '/en/registration/onboarding',
    component: `onboarding`,
    name: 'Registration on boarding',
  },
  WagerHistory: {
    path: '/en/account/wallet/transactions',
    component: `account-history.WagerHistory`,
    name: 'Wager History',
  },
  DocumentCenter: {
    path: '/en/account/profile/document-center',
    component: 'document-center',
    name: 'Document uploads',
  },
  ChangePassword: {
    path: '/en/account/profile/change-password',
    component: 'password-change',
    name: 'Change Password',
  },
  ForgotPassword: {
    path: '/en/forget-password',
    component: 'password-forgot',
    name: 'Forgot Password',
  },
  ResetPassword: {
    path: '/en/reset-password/:token',
    component: 'password-reset',
    name: 'Reset Password',
  },
  PlayerInfo: {
    path: '/en/account/profile/personal-info',
    component: 'player-info',
    name: 'Player Info',
  },
  ResponsibleGamingSettings: {
    path: '/en/account/play/play-limits',
    component: 'responsible-gaming-settings',
    name: 'Responsible Gaming Settings',
  },
  CommunicationPreferences: {
    path: '/en/account/profile/communication-preferences',
    component: 'communication-preferences',
    name: 'Communication Preferences Settings',
  },
  WagersPage: {
    path: '/en/wagers',
    component: 'wagers',
    name: 'Wagers',
  },
  Game: {
    path: '/en/game',
    component: 'game',
    name: 'Game',
  },
  GamesByCategory: {
    path: '/en/games/:category',
    component: 'game-by-category',
    name: 'Games by Category',
  },
  MakeDepositPage: {
    path: '/en/account/wallet/deposit',
    component: 'make-deposit',
    name: 'Make Deposit',
  },
  MakeDepositSuccessPage: {
    path: '/en/account/wallet/deposit/success',
    component: 'make-deposit',
    name: 'Make Deposit Success',
    props: {
      depositResult: 'success',
    },
  },
  MakeDepositErrorPage: {
    path: '/en/account/wallet/deposit/error',
    component: 'make-deposit',
    name: 'Make Deposit Error',
    props: {
      depositResult: 'error',
    },
  },
  MakeDepositSuccessIframePage: {
    path: '/en/account/wallet/deposit/success-i',
    component: 'make-deposit',
    name: 'Make Deposit Success Iframe',
    props: {
      depositResult: 'success',
    },
  },
  MakeDepositErrorIframePage: {
    path: '/en/account/wallet/deposit/error-i',
    component: 'make-deposit',
    name: 'Make Deposit Error Iframe',
    props: {
      depositResult: 'error',
    },
  },
  MakeDepositPendingIframePage: {
    path: '/en/account/wallet/deposit/pending-i',
    component: 'make-deposit-pending ',
    name: 'Make Deposit Pending Iframe',
    props: {
      depositResult: 'pending',
    },
  },
  WithdrawPage: {
    path: '/en/account/wallet/withdraw',
    component: 'withdraw',
    name: 'Withdraw',
  },
  Promotions: {
    path: '/en/promos',
    component: 'promotions',
    name: 'Promotions',
  },
  Tournaments: {
    path: '/en/tournaments',
    component: 'tournaments',
    name: 'Tournaments',
  },
  About: { path: '/en/about-us', component: 'about', name: 'About' },
  PrivacyPolicy: {
    path: '/en/privacy-policy',
    component: 'privacy-policy',
    name: 'Privacy Policy',
  },
  TermsOfUse: {
    path: '/en/terms-and-conditions',
    component: 'terms-of-use',
    name: 'Terms of Use',
  },
  ResponsibleGaming: {
    path: '/en/responsible-gaming',
    component: 'responsible-gaming',
    name: 'Responsible Gaming',
  },
  DynamicPage: {
    path: '/content/:slug',
    component: 'content',
    name: 'DynamicContent',
  },
  Affiliates: {
    path: '/affiliates',
    component: 'affiliates',
    name: 'Affiliates',
  },
  Error: { path: '/en/error', component: 'error', name: 'Error' },
  PageNotFound: {
    path: '/en/page-not-found',
    component: 'not-found',
    name: '404',
  },
  /** NOT IN USE **/
  CashHistory: {
    path: '/en/adw_account/cash_history',
    component: `account-history.CashHistory`,
    name: 'Cash History',
  },
  SessionTimeout: {
    path: '/en/pages/session_timeout',
    component: 'session-timeout',
    name: 'Session Timeout',
  },
};
