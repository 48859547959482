import { Close } from 'icons/svg';
import WagersPage from 'pages/wagers/wagers';
import { BspotModal } from 'components/atoms/modal/modal';
import { GameProduct } from 'services/get-game-products/get-game-products';

export type BuyCreditsModalProps = {
  onClose(): void;
  onOpenQuickCredits?(): void;
  depositResult?: string;
  show: boolean;
  instantPackage?: GameProduct;
};
const BuyCreditsModal = ({
  onClose,
  show,
  instantPackage,
  onOpenQuickCredits,
}: BuyCreditsModalProps) => (
  <BspotModal show={show} bg={'#000000'}>
    <div className={'d-flex flex-row justify-content-between'}>
      <h2 className={'my-0'}>
        <span>{'Buy Credits'}</span>
      </h2>
      <Close size={'16px'} color={'#ffffff'} onClick={onClose} />
    </div>
    <div className='d-flex flex-column justify-content-center'>
      <WagersPage
        closeAll={onClose}
        isModal={true}
        instantPackage={instantPackage}
        onOpenQuickCredits={onOpenQuickCredits}
      />
    </div>
  </BspotModal>
);
export default BuyCreditsModal;
