import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RegistrationFail } from 'components/organisms/registration/registration-failed';
import { PlayerDisabled } from 'components/organisms/registration/player-disabled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/authentication';
import { usePlayerStatus } from 'hooks/use-player-status/user-player-status';
import { Routes } from 'common/routes';
import { getCookie } from 'common/utils';
import { ModalWelcome } from 'components';
import { FeatureFlagsService } from '../../services/feature-flags/feature-flags';

type KycType = {
  setShowDisabled?: Dispatch<SetStateAction<boolean>>;
};

const KYCContext = React.createContext<KycType>({});

export const useKyc = () => {
  return useContext(KYCContext);
};

export const KYCContextProvider = (props) => {
  const location = useLocation();
  const { auth } = useAuth();
  const { data: status } = usePlayerStatus();
  const nav = useNavigate();

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (showDisabled) return;

    if (!status?.validator_status || !auth.session) return;
    if (
      location.pathname !== Routes.CreateAccount.path &&
      location.pathname !== Routes.AccountOnBoarding.path
    ) {
      const hasRegistered = getCookie(`${auth.session.playerId}isRegistered`);
      if (status?.validator_status === 1 && hasRegistered) {
        if (
          ENABLE_NEXT_AUTH === 'true' &&
          FeatureFlagsService.featureFlags.enableOnboardingPage
        ) {
          nav(Routes.AccountOnBoarding.path);
          return;
        } else {
          setShowError(false);
          setShowSuccess(true);
          return;
        }
      }
      if (
        status?.validator_status === 5 &&
        location.pathname !== Routes.PlayerInfo.path
      ) {
        setShowError(true);
        return;
      }
      if (
        status?.validator_status === 6 &&
        location.pathname !== Routes.DocumentCenter.path
      ) {
        setShowError(true);
        return;
      }
    }
  }, [auth.session, location.pathname, showDisabled, status?.validator_status]);

  return (
    <KYCContext.Provider
      value={{
        setShowDisabled,
      }}
    >
      {props.children}

      <ModalWelcome
        playerId={auth.session?.playerId.toString()}
        firstName={auth.session?.firstName}
        show={showSuccess}
        onclose={() => setShowSuccess(false)}
        status={status?.validator_status}
      />

      <RegistrationFail
        show={showError}
        onclose={() => setShowError(false)}
        status={status?.validator_status}
      />

      <PlayerDisabled
        show={showDisabled}
        onclose={() => setShowDisabled(false)}
        status={status?.validator_status}
      />
    </KYCContext.Provider>
  );
};
