import { sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { sendAppsFlyerEvent } from 'services/_af-events/appsflyer';
import {
  optinTournament,
  TournamentType,
} from 'services/tournaments/tournaments';
import { useState } from 'react';
import { useAuth } from 'contexts/authentication';
import { CheckCircle, CrossCircle } from 'icons/svg';
import { useToaster } from 'contexts/toaster-provider/toaster-provider';

type OptInTournamentProps = {
  tournament: TournamentType;
  refetchTournaments: () => void;
  refetchLeaderboards: () => void;
};

export const UseOptInTournament = ({
  tournament,
  refetchTournaments,
  refetchLeaderboards,
}: OptInTournamentProps) => {
  const { auth } = useAuth();
  const { pushToast, removeToast } = useToaster();

  const [loading, setLoading] = useState(false);

  const pushFailToast = () => {
    const errorToast = pushToast({
      title: 'We weren’t able to enter you in the tournament.',
      message:
        'Please try again in a few minutes or chat with our support team and we’ll lend a hand.',
      type: 'warning',
      icon: <CrossCircle />,
      closeHandler: () => removeToast(errorToast),
    });
  };

  const pushSuccessToast = () => {
    const successToast = pushToast({
      title: 'You are in!',
      message: 'The leaderboard awaits. Happy Spinning!',
      type: 'success',
      icon: <CheckCircle />,
      closeHandler: () => removeToast(successToast),
    });
  };

  const trackOptIn = () => {
    sendGTMEvent({
      event: 'join_group',
      tournamentName: tournament?.title,
      tournamentStartTimestamp: tournament?.startTime,
      tournamentPrize: tournament?.subtitle,
      user_id: auth?.session?.playerId.toString(),
    });

    sendAppsFlyerEvent('opt_in_tournament', {
      tournamentName: tournament?.title,
      tournamentStartTimestamp: tournament?.startTime,
      tournamentPrize: tournament?.subtitle,
      user_id: auth?.session?.playerId?.toString(),
    });
  };

  const clickOptIn = async (tournament: TournamentType) => {
    setLoading(true);

    const tournamentPayload = {
      contestId: tournament?.parentId,
      title: tournament?.title,
      startTime: tournament?.startTime,
      endTime: tournament?.endTime,
    };

    optinTournament(auth.session, tournamentPayload)
      .then((res) => {
        // @ts-ignore
        if (!res || res.data?.errors?.length) {
          pushFailToast();
          console.error('failed to opt in member');
          return;
        }

        trackOptIn();
        pushSuccessToast();
      })
      .catch((e) => {
        pushFailToast();
        return console.error(e);
      })
      .finally(() => {
        setLoading(false);
        refetchTournaments();
        refetchLeaderboards();
      });
  };

  return { clickOptIn, loading };
};
