import { useState } from 'react';
import { Spinner } from 'icons/svg';
import { InCenter } from 'pages/account-history/styled';
import { useQuery } from '@tanstack/react-query';
import {
  cancelWithdrawal,
  confirmWithdrawal,
  ConfirmWithdrawalRequestPayload,
  getWithdrawMethods,
  requestWithdrawal,
} from 'services/withdraw/withdraw';
import { WithdrawForm } from 'components/molecules/form-withdraw/withdraw-form';
import { WithdrawRequests } from 'components/organisms/withdraw-requests/withdraw-requests';
import { FadeInContainer } from 'pages/make-deposit/styled';
import { SectionTitle } from 'components/atoms/section-title/section-title';
import { useGetBalance } from 'hooks/use-balance';
import { BspotAlert } from 'components/molecules/alerts/alert';
import { Helmet } from 'react-helmet-async';
import { WithdrawConfirmation } from 'components/organisms/withdraw-confirmation/withdraw-confirmation';
import { useAuth } from 'contexts/authentication';
import { sendGTMEvent } from 'services/_gtm-analytics/gtm-analytics';
import { sendAppsFlyerEvent } from 'services/_af-events/appsflyer';
import { CONFIG } from 'common/utils';

/**
 * The withdraw process has 3 steps
 * 1- get  'withdraw-methods'
 * 2- post 'request-withdraw'
 * 3- post 'confirm-withdraw'
 * Cancel
 * 1- post 'cancel-requested-withdraw'
 */
export default function WithdrawPage() {
  const { token, profile, auth } = useAuth();
  const [confirm, setConfirm] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const { data: withdrawMethods, refetch: refetchWithdraw } = useQuery({
    queryKey: ['use-withdraw-methods', profile?.playerId],
    queryFn: getWithdrawMethods,
    keepPreviousData: false,
    enabled: !!token && !!profile,
  });
  const { refetch: refetchBalances } = useGetBalance();

  const refetch = () => {
    refetchWithdraw();
    refetchBalances();
  };

  const makeWithdrawal = ({ account: account, amount: amount }) => {
    if (withdrawMethods.isAllowed) {
      requestWithdrawal({ id: account, amount: amount })
        .then((d) => {
          setConfirm(d);
          setShowModal(true);
        })
        .catch((err) => {
          console.log(err);
          setFeedback(
            err?.message || 'There was an unexpected error, try again later.',
          );
          setIsError(true);
        });
    }
  };

  const confirmWithdraw = (amount) => {
    setFeedback(null);
    setIsError(false);
    const am = amount.split('$ ')[1];
    const confirmPayload: ConfirmWithdrawalRequestPayload = {
      amount: parseFloat(am.replace(/,/g, '')).toFixed(2),
      edited_account: confirm.params.edited_account,
      id: confirm.params.id,
      withdrawal_type: confirm.params.withdrawal_type,
    };

    confirmWithdrawal(confirmPayload)
      .then((d) => {
        if (d.Success) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          setShowModal(false);
          setFeedback(d.Message);
          setIsError(false);
          refetch();
          sendGTMEvent({
            event: 'refund',
            ecommerce: {
              status: 'success',
              currency: 'USD',
              product: confirmPayload,
              transaction_id:
                auth?.session?.playerId + '-' + new Date().getTime,
              items: [
                {
                  item_id:
                    confirm.params.id + '-' + confirm.params.edited_account,
                  item_name:
                    'withdrawal-' + parseFloat(am.replace(/,/g, '')).toFixed(2),
                },
              ],
              value: parseFloat(am.replace(/,/g, '')).toFixed(2),
              user_id: auth?.session?.playerId,
            },
          });
          const date = new Date().toISOString();
          sendAppsFlyerEvent('refund', {
            status: 'success',
            currency: 'USD',
            transaction_id: auth?.session?.playerId.toString() + '-' + date,
            item_id: confirm.params.id + '-' + confirm.params.edited_account,
            value: parseFloat(am.replace(/,/g, '')).toFixed(2).toString(),
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setShowModal(false);
        setFeedback(
          e?.message || 'There was an unexpected error, try again later.',
        );
        setIsError(true);
      });
  };

  const cancelWithdrawRequest = (id) => {
    cancelWithdrawal({ request_id: id }).then((d) => {
      if (d.Success) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setFeedback(d.Message);
        setIsError(false);
        refetch();
      }
    });
  };

  const pageTitle = 'Withdraw - ' + CONFIG.BRAND;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='og:title' content={pageTitle} />
        <meta name='twitter:title' content={pageTitle} />
      </Helmet>
      <>
        <FadeInContainer>
          <SectionTitle title={withdrawMethods?.title} />
        </FadeInContainer>

        {feedback && (
          <div className={'mb-3'}>
            <BspotAlert
              type={isError ? 'error' : 'success'}
              message={
                isError ? feedback : 'Withdraw request is being processed.'
              }
            />
          </div>
        )}

        {withdrawMethods?.isAllowed ? (
          <FadeInContainer>
            <WithdrawForm
              withdrawMethods={withdrawMethods}
              makeWithdrawal={makeWithdrawal}
            />
            <WithdrawRequests
              withdrawMethods={withdrawMethods}
              cancelWithdrawRequest={cancelWithdrawRequest}
            />
          </FadeInContainer>
        ) : withdrawMethods?.info ? (
          <BspotAlert type={'error'} message={withdrawMethods?.info} />
        ) : (
          <InCenter isLoading={true} className='mx-auto'>
            <Spinner size='4em' color='white' />
          </InCenter>
        )}
      </>

      {showModal && (
        <WithdrawConfirmation
          show={showModal}
          title={withdrawMethods?.title}
          confirm_info={confirm?.confirm_info}
          onCancelWithdraw={() => setShowModal(false)}
          onConfirmWithdraw={confirmWithdraw}
        ></WithdrawConfirmation>
      )}
    </>
  );
}
