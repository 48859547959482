import { ModalContentTableFlex } from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/styled';
import SkeletonTable from 'components/organisms/buy-credits/modals/wager-details/details-table/tables/skeleton';
import { WagerDetails } from 'services/get-game-products/get-game-products';
import React from 'react';
import './styles.scss';

const Scroll = (props) => {
  return (
    <div id='scroll' style={{ overflowY: 'scroll', height: '300px' }}>
      {props.children}
    </div>
  );
};
type WagerTableProps = { wagerDetails: WagerDetails };
export const WagerTable = ({ wagerDetails }: WagerTableProps) => {
  return (
    <ModalContentTableFlex style={{ overflowY: 'auto' }}>
      {wagerDetails ? (
        <table>
          <thead className='table-header'>
            <tr>
              <th>
                {wagerDetails?.trackName + ' - RACE ' + wagerDetails?.race}
              </th>
              <th></th>
              <th>{wagerDetails?.eventDate}</th>
            </tr>
          </thead>
          <tbody className={'table-wrap'}>
            {wagerDetails?.wagers?.map((row, i) => (
              <tr className='table-row' key={i}>
                <td width={'40px'}>{row.selections}</td>
                <td>{row.pool_type_id}</td>
                <td className='fw-bold'>{row.total_wager_amount_formatted}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <SkeletonTable />
      )}
    </ModalContentTableFlex>
  );
};

export default WagerTable;
