import { useOutlet } from 'react-router-dom';
import { HelpSection } from 'components/molecules/help-section';
import styled from '@emotion/styled';
import { bp } from 'common/theme';
import { SideMenu } from 'components/organisms/layout/side-menu/side-menu';
import { SuspenseFallback } from 'components/organisms/layout/layout';

const Layout = styled.div``;

const MenuWrapper = styled.div`
  @media ${bp.lg} {
    padding-top: 2rem;
  }
  @media screen and (max-width: 975px) {
    display: none;
  }
`;

const OutletWrapper = styled.div`
  @media ${bp.lg} {
    padding-top: 2rem;
  }
`;

const HelpWrapper = styled.div`
  @media ${bp.lg} {
    padding-top: 2rem;
  }
  @media screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

export const AccountLayout = () => {
  const outlet = useOutlet();
  return (
    <div className={'container pb-3 pb-lg-5'}>
      <Layout className={'row'}>
        <MenuWrapper className={'d-none d-lg-block col-lg-3 col-md-3'}>
          <SideMenu />
        </MenuWrapper>
        <OutletWrapper className={'col-12 col-lg-6 col-md-7'}>
          <div className={'container px-0 mx-0'}>
            <SuspenseFallback>{outlet}</SuspenseFallback>
          </div>
        </OutletWrapper>
        <HelpWrapper className={'col-lg-3 col-md-2 col-12'}>
          <HelpSection />
        </HelpWrapper>
      </Layout>
    </div>
  );
};
