import React, { useState } from 'react';
import { TermsAndConditionsModal } from 'components/organisms/tournament-card/tournament-components';

export const DescriptionWidget = ({ description, id, tnc, handleClose }) => {
  const [showTncModal, setShowTncModal] = useState(false);

  const closeDescription = () => {
    handleClose();
  };

  const onClickTsAndCs = () => {
    setShowTncModal(true);
  };

  return (
    <div
      className='tournament-widget-description'
      data-testid={`${id}-description`}
    >
      <div dangerouslySetInnerHTML={{ __html: description }} />
      {typeof tnc === 'string' && (
        <div className='tnc-stack'>
          <a className='tnc' data-testid={`${id}-tnc`} onClick={onClickTsAndCs}>
            Terms and Conditions
          </a>
          <span> apply.</span>
        </div>
      )}
      {/*<div className='cta'>*/}
      {/*  <Button*/}
      {/*    color={ButtonColor.White}*/}
      {/*    onClick={closeDescription}*/}
      {/*    text='CLOSE'*/}
      {/*  />*/}
      {/*</div>*/}
      <TermsAndConditionsModal
        tnc={tnc}
        show={showTncModal}
        setShow={() => {
          setShowTncModal(!showTncModal);
        }}
      />
    </div>
  );
};
