import React from 'react';
import ReactDOM from 'react-dom';

// @ts-ignore
export const usePortal = (): (({ children: ReactNode }) => JSX.Element) => {
  const el = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    const element = el.current;
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [el]);

  return React.useCallback(
    (props) => ReactDOM.createPortal(props.children, el.current),
    [],
  );
};
