import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { FeatureFlagsService } from 'services/feature-flags/feature-flags';

export const useRouteTracker = () => {
  const location = useLocation();
  useEffect(() => {
    if (!window['xtremepush']) return;
    window['xtremepush']('event', 'LIBERTY_PAGE_VIEW', location?.pathname);
  }, [location.pathname, FeatureFlagsService?.featureFlags]);

  return null;
};
