import React from 'react';
import { SvgProps } from 'icons/svg/index';

export function Lightning({
  width = '19',
  height = '17',
  fill = '#00000',
}: SvgProps) {
  return (
    <svg
      width='19'
      height='17'
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        id='Vector'
        d='M13.4023 0.75L11.5697 3.69508L10.8565 4.84132H12.2065H16.0039L4.19082 14.9663L5.29854 9.98187L5.50137 9.06916H4.5664H1.23374L5.54757 0.75H13.4023Z'
        stroke={fill}
        strokeWidth='1.5'
      />
    </svg>
  );
}
